import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TruncatePipe } from '@seahorse/common';

import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { UtcToLocalDatePipe } from './pipes/utc-to-local-date.pipe';
import { UtcToLocalDateTimePipe } from './pipes/utc-to-local-datetime.pipe';
import { AuthGuard } from './services/auth.guard';
import { FormattingService } from './services/formatting.service';
import { LoginGuardService } from './services/login-guard.service';
import { OnboardingGuardService } from './services/onboarding-guard.service';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    UtcToLocalDateTimePipe,
    UtcToLocalDatePipe,
    HighlightSearchPipe,

    NumbersOnlyDirective,
  ],
  imports: [CommonModule, FormsModule, TruncatePipe],
  providers: [
    AuthGuard,
    LoginGuardService,
    OnboardingGuardService,
    FormattingService,
    // IdentityService, -> Moved it to the AppModule provider list, otherwise it got instantiated over and over in some modules
  ],
  exports: [
    SafeHtmlPipe,
    UtcToLocalDateTimePipe,
    UtcToLocalDatePipe,
    HighlightSearchPipe,

    NumbersOnlyDirective,
  ],
})
export class CoreModule {}
