import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import {
  NotificationService,
  SIGNALR_ACTION,
  SignalRMessage,
  SignalRService,
} from '@seahorse/common';
import { Guid } from 'guid-typescript';
import { HubConnection } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateEnvironmentModel } from '../../../models/create-environment.model';
import { UserAccountService } from '../../services/user-account.service';
import { OrganisationModel } from '../../../../content-distribution/models/organisation.model';
import { LayoutDisplayService } from '../../../../layout/services/layout-display.service';

@Component({
  selector: 'ca-create-environment',
  templateUrl: './create-environment.component.html',
  styleUrls: ['./create-environment.component.scss'],
})
export class CreateEnvironmentComponent implements OnInit, OnDestroy {
  demoOrganisations: OrganisationModel[] = [];
  messages: SignalRMessage[] = [];
  isLoading = false;

  private _signalRService = inject(SignalRService);
  private _userAccountService = inject(UserAccountService);
  private _notificationService = inject(NotificationService);
  private _layoutDisplayService = inject(LayoutDisplayService);
  private _translateService = inject(TranslateService);
  private _destroy = new Subject<void>();
  private _subscriptions = new Subscription();

  ngOnInit() {
    this._layoutDisplayService.currentModuleName =
      this._translateService.instant('account.onboarding.createEnvironment');

    this._subscriptions.add(
      this._userAccountService.getDemoOrganisations().subscribe({
        next: (response) => {
          if (response.result && response.result.length > 0) {
            this.demoOrganisations = response.result;
          } else {
            this._notificationService.displayErrorNotification(
              response.messages
            );
          }
        },
        error: (e) => {
          this._notificationService.displayErrorNotification(e);
        },
      })
    );
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._subscriptions.unsubscribe();
  }

  createEnvironment(organisation: OrganisationModel) {
    if (this.isLoading) return;

    this.isLoading = true;
    this._subscriptions.add(
      this._signalRService.createConnection().subscribe({
        next: (connection) => {
          this._signalRService.receiveMessage
            .pipe(takeUntil(this._destroy))
            .subscribe((message) => {
              if (message) {
                this.messages.unshift(message);

                if (message.action === SIGNALR_ACTION.DISCONNECT) {
                  this.isLoading = false;
                }
              }
            });

          this.startCreateEnvironment(organisation.id, connection);
        },
      })
    );
  }

  private startCreateEnvironment(
    sourceOrganisationId: Guid,
    connection: HubConnection
  ) {
    const model = {
      sourceOrganisationId: sourceOrganisationId,
      signalR_Id: connection.connectionId,
    } as CreateEnvironmentModel;

    this._subscriptions.add(
      this._userAccountService.createEnvironment(model).subscribe({
        next: (response) => {
          if (response.hasResult) {
            this._signalRService.sendMessage(connection, 'Started');
          } else {
            this._notificationService.displayErrorNotification(
              response.messages
            );
          }
        },
        error: (e) => {
          this._notificationService.displayErrorNotification(e);
        },
      })
    );
  }
}
