export interface LoginModel {
  username: string;
  password: string;
  rememberMe: boolean;
}

export class PasswordLoginModel implements LoginModel {
  readonly grantType = 'password';
  username: string;
  password: string;
  rememberMe: boolean;

  constructor() {
    this.rememberMe = false;
  }
}

export class ResetPasswordModel {
  emailAddress: string;

  constructor(emailAddress: string) {
    this.emailAddress = emailAddress ? emailAddress : null;
  }
}

export class UpdatePasswordModel {
  encryptedString: string;
  newPassword: string;

  constructor(encryptedString: string, newPassword: string) {
    this.encryptedString = encryptedString ? encryptedString : null;
    this.newPassword = newPassword ? newPassword : null;
  }
}

export class LoginResponse {
  isSuccess: boolean;
  isMfaEnabled: boolean;
  isMfaRequired: boolean;
  isUnauthorized: boolean;
}
