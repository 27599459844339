<div class="modal-header">
  <h4 class="modal-title">
    {{
      (isNew ? 'companies.company.addCompany' : 'companies.company.editCompany')
        | translate
    }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<sh-loading-indicator
  *ngIf="isCountryLoading"
  class="d-flex justify-content-center loader-sm mt-2 mb-2"
>
</sh-loading-indicator>

<form
  *ngIf="!isCountryLoading"
  #companyForm="ngForm"
  (ngSubmit)="companyForm.submitted && companyForm.valid && save(companyForm)"
>
  <div class="modal-body">
    <div class="form-group">
      <label
        ><b>{{ 'companies.company.model.name' | translate }}</b>
        <span class="text-danger">*</span></label
      >
      <input
        required
        class="form-control"
        type="text"
        #name="ngModel"
        [(ngModel)]="model.name"
        id="name"
        name="name"
        (focusout)="nameExistsCheck()"
      />
      <p *ngIf="nameExists" class="text-danger">
        {{ 'companies.form.companyWithThisNameExists' | translate }}
      </p>
    </div>
    <div class="row" *ngIf="isNew">
      <div class="col-6">
        <div class="form-group">
          <label>
            <b>{{ 'companies.address.model.street' | translate }}</b>
          </label>
          <input
            class="form-control"
            type="text"
            #addressLine1="ngModel"
            [(ngModel)]="address.addressLine1"
            id="addressLine1"
            name="addressLine1"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="control-label"
            ><b>{{ 'shared.addressForm.model.country' | translate }}</b>
          </label>
          <ng-container>
            <ng-select
              id="country"
              name="country"
              [items]="countries"
              [placeholder]="'companies.form.selectCountry' | translate"
              bindLabel="name"
              bindValue="isO2"
              [searchable]="true"
              [(ngModel)]="address.country"
              #country="ngModel"
            ></ng-select>
          </ng-container>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>
            <b>{{ 'companies.address.model.postalCode' | translate }}</b>
          </label>
          <input
            [(ngModel)]="address.zip"
            class="form-control"
            type="text"
            id="zip"
            name="zip"
            #zip="ngModel"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>
            <b>{{ 'companies.address.model.city' | translate }}</b>
          </label>
          <input
            [(ngModel)]="address.city"
            class="form-control"
            type="text"
            id="city"
            name="city"
            #city="ngModel"
          />
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="isNew">
      <label>
        <b>{{ 'companies.address.addressType.email' | translate }}</b>
      </label>
      <input
        [(ngModel)]="email.value"
        [ngModelOptions]="{ standalone: true }"
        class="form-control"
        type="text"
        id="value"
        name="value"
      />
    </div>
    <div class="form-group">
      <label
        ><b>{{ 'companies.company.model.number' | translate }}</b></label
      >
      <input
        class="form-control"
        type="text"
        #number
        [(ngModel)]="model.number"
        id="number"
        name="number"
      />
    </div>
    <form
      *ngIf="chooseCompanyTypesVisible; else add_company_type"
      [formGroup]="form"
    >
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label>
            <b> {{ 'companies.company.model.types' | translate }}: </b>
          </label>
          <button
            type="button"
            class="btn btn-link btn-sm"
            (click)="chooseCompanyTypesVisible = false"
          >
            <i class="fa-regular fa-plus fa-fw"></i>
            {{ 'shared.terms.new' | translate }}
          </button>
        </div>
        <sh-multi-select
          formControlName="companyTypes"
          [options]="companyTypeList"
        ></sh-multi-select>
      </div>
    </form>
    <ng-template #add_company_type>
      <label>{{ 'companies.company.addType' | translate }}</label>
      <div class="input-group">
        <div class="input-group-prepend flex-grow-1">
          <input
            type="text"
            class="form-control"
            #newCompanyTypeName
            id="newCompanyTypeName"
            name="newCompanyTypeName"
          />
        </div>
        <button
          class="btn btn-success ml-2"
          type="button"
          (click)="saveCompanyType(newCompanyTypeName.value)"
        >
          {{ 'shared.terms.save' | translate }}
        </button>
        <button
          class="btn btn-link ml-2"
          type="button"
          (click)="chooseCompanyTypesVisible = true"
        >
          {{ 'shared.terms.cancel' | translate }}
        </button>
      </div>
    </ng-template>
    <div class="form-group">
      <label
        ><b>{{ 'companies.company.model.remarks' | translate }}</b></label
      >
      <div>
        <textarea
          rows="3"
          class="form-control"
          type="text"
          [(ngModel)]="model.remarks"
          id="remarks"
          name="remarks"
        ></textarea>
      </div>
    </div>

    <div *ngIf="isNew; else update_button">
      <div class="d-flex">
        <div class="flex-grow-1">
          <button type="button" class="btn btn-block btn-link" closeModal>
            {{ 'shared.terms.cancel' | translate }}
          </button>
        </div>
        <div class="flex-grow-1">
          <div class="btn-group btn-block" role="group">
            <button
              type="submit"
              class="btn btn-block btn-success"
              [disabled]="saveStarted || !chooseCompanyTypesVisible"
            >
              {{ 'shared.terms.save' | translate }}
            </button>
            <div class="btn-group">
              <button
                class="btn btn-success active dropdown-toggle"
                id="ddl_save_company_more_options"
                data-toggle="dropdown"
                [disabled]="saveStarted || !chooseCompanyTypesVisible"
                aria-haspopup="true"
                aria-expanded="false"
                data-offset="0,1"
              ></button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="ddl_save_company_more_options"
              >
                <button
                  class="dropdown-item"
                  type="submit"
                  (click)="reload = true"
                >
                  {{ 'shared.terms.saveAndAdd' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #update_button>
      <div class="d-flex">
        <div class="flex-grow-1">
          <button type="button" class="btn btn-block btn-link" closeModal>
            {{ 'shared.terms.cancel' | translate }}
          </button>
        </div>
        <div class="flex-grow-1">
          <button
            type="submit"
            class="btn btn-block btn-success"
            (click)="save(companyForm)"
          >
            {{ 'shared.terms.update' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</form>
