import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompanyComponentsModule } from '../../company/components/company-components.module';
import { CoreModule } from '../../core/core.module';
import { CustomContentPipesModule } from '../../custom-content/pipes/custom-content-pipes.module';
import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { LayoutPipesModule } from '../../layout/pipes/layout-pipes.module';
import { NauticalShipComponentsModule } from '../../nautical-ship/components/nautical-ship-components.module';
import { NauticalVisitComponentsModule } from '../../nautical-visit/components/nautical-visit-components.module';
import { OrderRegistrationsComponentsModule } from '../../order-registrations/components/order-registrations-components.module';
import { SharedModule } from '../../shared/shared.module';
import { UserComponentsModule } from '../../user/components/user.components.module';
import { UserPipesModule } from '../../user/pipes/user-pipes.module';

import { CustomContentDetailsComponent } from './custom-content-details-card/custom-content-details-card.component';
import { CustomContentEditReportModalComponent } from './custom-content-edit-report-modal/custom-content-edit-report-modal.component';
import { CustomContentEditValueComponent } from './custom-content-edit-value/custom-content-edit-value.component';
import { CustomContentListCardComponent } from './custom-content-list-card/custom-content-list-card.component';
import { CustomContentListComponent } from './custom-content-list/custom-content-list.component';
import { CustomContentOverviewHeaderComponent } from './custom-content-overview-header/custom-content-overview-header.component';
import { CustomContentPickerModalComponent } from './custom-content-picker-modal/custom-content-picker-modal.component';
import { DateTimePickerComponent } from '@seahorse/temp';

@NgModule({
  declarations: [
    CustomContentDetailsComponent,
    CustomContentEditReportModalComponent,
    CustomContentEditValueComponent,
    CustomContentListCardComponent,
    CustomContentListComponent,
    CustomContentOverviewHeaderComponent,
    CustomContentPickerModalComponent,
  ],
  exports: [
    CustomContentDetailsComponent,
    CustomContentEditReportModalComponent,
    CustomContentEditValueComponent,
    CustomContentListCardComponent,
    CustomContentListComponent,
    CustomContentOverviewHeaderComponent,
    CustomContentPickerModalComponent,
  ],
  imports: [
    CompanyComponentsModule,
    CustomContentPipesModule,
    CommonModule,
    CoreModule,
    FormsModule,
    LayoutComponentsModule,
    LayoutPipesModule,
    NauticalShipComponentsModule,
    NauticalVisitComponentsModule,
    NgbModule,
    NgSelectModule,
    OrderRegistrationsComponentsModule,
    RouterModule,
    SharedModule,
    UserPipesModule,
    UserComponentsModule,
    DateTimePickerComponent,
  ],
})
export class CustomContentComponentsModule {}
