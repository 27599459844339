import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExportTemplateModel } from '../models/export-template.model';
import { DataService } from '@seahorse/domain';

@Injectable()
export class ExportTemplateDataService
  implements DataService<ExportTemplateModel>
{
  constructor(private dataContext: DataContextService) {}

  search(
    searchQuery?: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<ExportTemplateModel[]>> {
    const queryParams = [];

    if (searchQuery !== undefined && searchQuery !== null) {
      queryParams.push(`searchTerm=${encodeURIComponent(searchQuery)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `contentdistribution/exporttemplate/search${
      queryString ? '?' + queryString : ''
    }`;

    return this.dataContext.get<ExportTemplateModel[]>(url);
  }

  searchByOrganization(
    searchQuery?: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<ExportTemplateModel[]>> {
    const queryParams = [];

    if (searchQuery !== undefined && searchQuery !== null) {
      queryParams.push(`searchTerm=${encodeURIComponent(searchQuery)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `contentdistribution/exporttemplate/searchbyorganisation${
      queryString ? '?' + queryString : ''
    }`;

    return this.dataContext.get<ExportTemplateModel[]>(url);
  }

  getById(id: number): Observable<ResultWrapper<ExportTemplateModel>> {
    return this.dataContext
      .get<ExportTemplateModel>(`contentdistribution/exporttemplate/${id}`)
      .pipe(
        map((item) => {
          if (item.result && !item.result.templateConfiguration) {
            item.result.templateConfiguration = {};
          }
          return item;
        })
      );
  }

  addAsSuperUser(
    model: ExportTemplateModel
  ): Observable<ResultWrapper<ExportTemplateModel>> {
    return this.dataContext.post<ExportTemplateModel>(
      `su/contentdistribution/exporttemplate`,
      model
    );
  }

  add(
    model: ExportTemplateModel
  ): Observable<ResultWrapper<ExportTemplateModel>> {
    return this.dataContext.post<ExportTemplateModel>(
      `contentdistribution/exporttemplate`,
      model
    );
  }

  update(
    model: ExportTemplateModel
  ): Observable<ResultWrapper<ExportTemplateModel>> {
    return this.dataContext.put<ExportTemplateModel>(
      `contentdistribution/exporttemplate`,
      model
    );
  }

  delete(id: number): Observable<ResultWrapper<ExportTemplateModel>> {
    return this.dataContext.delete<ExportTemplateModel>(
      `contentdistribution/exporttemplate/${id}`
    );
  }

  getAll(): Observable<ResultWrapper<ExportTemplateModel[]>> {
    return this.dataContext.get<ExportTemplateModel[]>(
      `contentdistribution/exporttemplate`
    );
  }
}
