import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SeahorseTemplateDirective } from '@seahorse/common';
import { BaseControl } from '../base.control';
import { MultiSelectOption } from '../multi-select/multi-select.model';

@Component({
  selector: 'sh-multi-select',
  templateUrl: './multi-select.control.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => MultiSelectControl),
    },
  ],
  styles: [
    `
      .e-list-icon {
        margin-right: 0.5rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectControl extends BaseControl<
  string[] | number[] | null
> {
  @ContentChild(SeahorseTemplateDirective) template?: SeahorseTemplateDirective;
  @Input() mustMatchOptions = true;
  @Input() options: MultiSelectOption[] = [];
  @Input() maxItems?: number;

  fieldMapping = { text: 'label', value: 'value', iconCss: 'icon' };

  get templateRef() {
    return this.template?.templateRef;
  }
}
