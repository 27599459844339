import { KeyValuePair, NotificationService } from '@seahorse/common';
import {
  Input,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CompanyModel,
  CompanyDataService,
  FieldVisibility,
  ObjectDefinitionModel,
  FieldDefinitionModel,
  OrderRegistrationModel,
  TariffAmountTypes,
  ExpenseModel,
} from '@seahorse/domain';
import {
  CustomDataFormService,
  FormComponent,
  FormModel,
} from '@seahorse/temp';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ProxyServices } from '../../../core/services/proxy.service';
import {
  TariffModel,
} from '../../../invoicing-and-tariffs/modules/tariffs/models/tariff.model';
import { TariffDataService } from '../../../invoicing-and-tariffs/modules/tariffs/services/tariff-data.service';
import { IdentityService } from '../../../core/services/identity.service';

@Component({
  selector: 'ca-expense-form',
  templateUrl: './expense-form.component.html',
})
export class ExpenseFormComponent implements OnDestroy, OnInit {
  private _expense: ExpenseModel;
  @Input()
  set expense(value: ExpenseModel) {
    this._expense = { ...value };
    // We can't know which comes first, orderRegistrations or expense
    if (value.orderRegistration && this.orderRegistrations?.length > 0) {
      this.selectedOrderRegistration = this.orderRegistrations.find(
        (x) => x.__Id === value.orderRegistration
      );
    }
  }
  get expense() {
    return this._expense;
  }

  private _orderRegistrations: OrderRegistrationModel[];
  @Input()
  set orderRegistrations(value: OrderRegistrationModel[]) {
    this._orderRegistrations = value;
    // We can't know which comes first, orderRegistrations or expense
    if (this.expense?.orderRegistration) {
      this.selectedOrderRegistration = value.find(
        (x) => x.__Id === this.expense?.orderRegistration
      );
    }
  }
  get orderRegistrations() {
    return this._orderRegistrations;
  }

  @Input() loadingOrderRegistrations = false;
  @Input() hideOrderRegistration = false;
  @Input() hideTariff = false;
  @Input() hideCommissionFeeGroup = false;

  currencies: KeyValuePair<string, string>[] = [];
  currencyValues: string[] = [];
  commissionFeeTypes = Object.values(TariffAmountTypes).filter(
    (x) => typeof x === 'number'
  );
  selectedOrderRegistration: OrderRegistrationModel = null;
  selectedTariff: TariffModel = null;
  selectedSupplier: CompanyModel = null;
  selectedCustomer: CompanyModel = null;
  isLoadingOrderRegistration = false;
  isLoadingTariff = false;
  isLoadingSupplier = false;
  isLoadingCustomer = false;
  prependText: string;
  submitted = false;

  expenseFieldOptions: {
    [key: string]: {
      isVisible: boolean;
      isRequired: boolean;
      isReadOnly: boolean;
      label: string;
    };
  } = {};

  appendedForm: FormModel;

  @ViewChild(FormComponent)
  appendedFormComponent: FormComponent;

  private _customDataFormService = inject(CustomDataFormService);
  private _companyDataService = inject(CompanyDataService);
  private _identityService = inject(IdentityService);
  private _proxyServices = inject(ProxyServices);
  private _tariffDataService = inject(TariffDataService);
  private _translateService = inject(TranslateService);
  private _notificationService = inject(NotificationService);

  private _subscriptions = new Subscription();
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  ngOnInit() {
    const objectDefinition = this._proxyServices.objectDefinitions.find(
      (x) => x.systemCode === 'expense'
    );

    const expenseDefinition = {
      ...objectDefinition,
      ...{
        objectFieldDefinitions: objectDefinition.objectFieldDefinitions.filter(
          (x) =>
            objectDefinition.baseObjectDefinition.objectFieldDefinitions
              .map((y) => y.systemCode)
              .includes(x.systemCode)
        ),
      },
    };

    const expenseFieldDefinitions =
      ObjectDefinitionModel.getAllFieldDefinitions(expenseDefinition);
    this.currencies =
      expenseFieldDefinitions.find((x) => x.systemCode === 'currency')
        ?.fieldRule.items || [];
    this.currencyValues = this.currencies.map((x) => x.value);

    if (!this.expense) {
      this.expense = {
        ...new ExpenseModel(),
        ...expenseFieldDefinitions.map((x) => {
          return { [x.systemCode]: x.defaultValue };
        }),
      };
    }

    this.prependText =
      this.expense.commissionFeeType === TariffAmountTypes.Number
        ? this.expense.currency[0]
        : null;

    this.expenseFieldOptions = expenseFieldDefinitions.reduce((o, x) => {
      const isVisible = this.isVisible(x);
      o[x.systemCode] = {
        isVisible: isVisible,
        isRequired: isVisible ? x.isRequired : false,
        isReadOnly: x.isReadOnly,
        label:
          expenseDefinition.baseObjectDefinition.objectFieldDefinitions.find(
            (y) => y.systemCode === x.systemCode
          )?.name === x.name
            ? this._translateService.instant(
                `customModules.expenses.model.${x.systemCode}`
              )
            : x.name,
      };
      return o;
    }, {});

    // Now we know which expense fields will be visible, load data for the pickers if necessary
    this.loadData();

    // Create appended form
    const appendedDefinition = {
      ...objectDefinition,
      ...{
        objectFieldDefinitions: objectDefinition.objectFieldDefinitions.filter(
          (x) =>
            !objectDefinition.baseObjectDefinition.objectFieldDefinitions
              .map((y) => y.systemCode)
              .includes(x.systemCode)
        ),
        baseObjectDefinition: undefined,
        baseObjectDefinitionId: undefined,
      },
    };

    const appendedFields = ObjectDefinitionModel.getAllFieldDefinitions(
      appendedDefinition
    ).map((x) => x.systemCode);
    const appendedValues = appendedFields.reduce(
      (o, x) => ({ ...o, [x]: this.expense[x] }),
      {}
    );

    this.appendedForm = this._customDataFormService.getFormModel(
      appendedDefinition,
      appendedFields,
      appendedValues,
      true
    );
  }

  private loadData() {
    if (this.expense.tariff && this.expenseFieldOptions['tariff'].isVisible) {
      this.isLoadingTariff = true;
      this._subscriptions.add(
        this._tariffDataService.getById(this.expense.tariff).subscribe({
          next: (response) => {
            if (response.hasResult) {
              this.selectedTariff = response.result;
            } else {
              this._notificationService.displayErrorNotification(
                response.messages
              );
            }
          },
          error: (e) => this._notificationService.displayErrorNotification(e),
          complete: () => (this.isLoadingTariff = false),
        })
      );
    }

    if (
      this.expense.supplier &&
      this.expenseFieldOptions['supplier'].isVisible
    ) {
      this.isLoadingSupplier = true;
      this._subscriptions.add(
        this._companyDataService.getById(this.expense.supplier).subscribe({
          next: (response) => {
            if (response.hasResult) {
              this.selectedSupplier = response.result;
            } else {
              this._notificationService.displayErrorNotification(
                response.messages
              );
            }
          },
          error: (e) => this._notificationService.displayErrorNotification(e),
          complete: () => (this.isLoadingSupplier = false),
        })
      );
    }

    if (
      this.expense.customer &&
      this.expenseFieldOptions['customer'].isVisible
    ) {
      this.isLoadingCustomer = true;
      this._subscriptions.add(
        this._companyDataService.getById(this.expense.customer).subscribe({
          next: (response) => {
            if (response.hasResult) {
              this.selectedCustomer = response.result;
            } else {
              this._notificationService.displayErrorNotification(
                response.messages
              );
            }
          },
          error: (e) => this._notificationService.displayErrorNotification(e),
          complete: () => (this.isLoadingCustomer = false),
        })
      );
    }
  }

  private isVisible(fieldDefinition: FieldDefinitionModel): boolean {
    switch (fieldDefinition.systemCode) {
      case 'orderRegistration':
        if (this.hideOrderRegistration) {
          return false;
        }
        break;
      case 'tariff':
        if (this.hideTariff) {
          return false;
        }

        if (
          !this._identityService.hasPermission(
            'invoices.tariff_admin,invoices.invoices,invoices.invoices_configure'
          )
        ) {
          return false;
        }
        break;
      case 'commissionFee':
      case 'commissionFeeType':
      case 'charged':
      case 'remarks':
        if (this.hideCommissionFeeGroup) {
          return false;
        }
        break;
      /* Invisible items are never validated
       * These sytem field definitions are not included in this form
       * Force isVisible = false to not break validation later on
       */
      case 'isBudget':
      case 'currency':
      case 'tariffType':
      case 'portCallFile':
      case 'po':
        return false;
    }

    // Default
    return fieldDefinition.visibility !== FieldVisibility.Hidden;
  }

  onSelectedOrderRegistrationChanged() {
    this.expense.orderRegistration =
      this.selectedOrderRegistration?.__Id || null;
    this.expense.remarks =
      this.selectedOrderRegistration?.customerReference || null;
  }

  onSelectedTariffChanged() {
    this.expense.tariff = this.selectedTariff?.id || null;

    if (this.selectedTariff) {
      if (!this.expense.__Id) {
        this.expense.tariffType = this.selectedTariff.tariffType;
      }

      if (!this.expense.__Id || !this.expense.amount) {
        this.expense.amount = this.selectedTariff.amount;
      }

      if (!this.expense.__Id || !this.expense.description) {
        this.expense.description = this.selectedTariff.uITemplate;
      }
    }
  }

  onSelectedCustomerChanged() {
    this.expense.customer = this.selectedCustomer?.id ?? null;
  }

  onSelectedSupplierChanged() {
    this.expense.supplier = this.selectedSupplier?.id ?? null;
  }

  onCommissionFeeTypeChanged() {
    this.prependText =
      this.expense.commissionFeeType === TariffAmountTypes.Number
        ? this.expense.currency[0]
        : null;
  }

  onSelectedCurrencyChanged(value: string) {
    this.expense.currency = [
      this.currencies.find((x) => x.value === value).key,
    ];
  }

  validate(): boolean {
    this.submitted = true;

    // Workaround: Should be handled in the place where bug occurs (empty string in array)
    if (this.expense?.currency?.length > 0) {
      this.expense.currency = this.expense.currency.filter(
        (x) => x !== null && x !== undefined && x !== ''
      );
    }

    const expenseIsInvalid = Object.keys(this.expense).some((x) => {
      return this.expenseFieldOptions[x]?.isRequired
        ? this.expense[x] === null ||
            this.expense[x] === undefined ||
            this.expense[x] === ''
        : false;
    });

    if (expenseIsInvalid || this.appendedFormComponent.form.invalid) {
      return false;
    }

    this.expense = { ...this.expense, ...this.appendedFormComponent.submit() };
    return true;
  }
}
