import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { IdentityService } from './identity.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  constructor(public identity: IdentityService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const permissions = route.data.allowedPermissions;
    return of(this.hasPermission(permissions));
  }

  hasPermission(permissions): boolean {
    if (permissions && !this.identity.hasPermission(permissions)) {
      this.router.navigate(['not-authorized']);
      return false;
    } else {
      return true;
    }
  }
}
