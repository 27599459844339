import { inject, Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileModel } from '@seahorse/domain';
import { BaseFileListCardService, FileDropModel, FileListCardComponent } from '@seahorse/temp';
import * as _ from 'underscore';
import { FileImportWizardComponent } from '../file-import-wizard/file-import-wizard.component';

@Injectable({
  providedIn: 'root',
})
export class FileListCardService extends BaseFileListCardService {
  private _modal = inject(NgbModal);

  override filesAdd(component: FileListCardComponent, fileDropped?: FileDropModel) {
    const size = this.env.useFileUploadWizard ? 'xl' : 'md';

    const m: NgbModalRef = this._modal.open(FileImportWizardComponent, {
      backdrop: 'static',
      size: size,
    });

    if (fileDropped) {
      m.componentInstance.objectMappingKey = fileDropped.objectType;
      m.componentInstance.objectId = fileDropped.objectId;
      m.componentInstance.object = fileDropped.object;
      m.componentInstance.filesDropped = fileDropped.files;
      m.componentInstance.objectFieldDefinitions = component.objectFieldDefinitions;
    } else {
      m.componentInstance.objectMappingKey = component.objectType;
      m.componentInstance.objectId = component.objectId;
      m.componentInstance.object = component.object;
      m.componentInstance.objectFieldDefinitions = component.objectFieldDefinitions;
    }

    m.componentInstance.confirm.subscribe((o: { files: FileModel[] }) => {
      if (component.files && component.files.length) {
        component.files = _.sortBy(component.files.concat(o.files), 'name');
      } else {
        component.files = _.sortBy(o.files, 'name');
      }
    });
  }

  override openImportLinkedObjectsModal(file: FileModel, component: FileListCardComponent) {
    const m: NgbModalRef = this._modal.open(FileImportWizardComponent, {
      backdrop: 'static',
      size: 'xl',
    });

    m.componentInstance.objectMappingKey = component.objectType;
    m.componentInstance.objectId = component.objectId;
    m.componentInstance.object = component.object;
    m.componentInstance.objectFieldDefinitions = component.objectFieldDefinitions;
    m.componentInstance.editFile = true;
    m.componentInstance.files = component.files;
    m.componentInstance.file = file;
    m.componentInstance.linkedObjectsIds = component.linkedObjectsIds;
  }
}
