<table
  class="table table-truncated"
  [ngClass]="{ 'table-clickable': allowSelection }"
>
  <thead>
    <tr>
      <th
        class="text-truncate"
        style="max-width: 700px"
        *ngFor="let column of columns"
        title="{{ column.displayName }}"
        [style.width]="column.width ? column.width : hasFixWidth ? '' : '1%'"
      >
        {{ column.displayName }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let entry of entries"
      (click)="selectEntry(entry)"
      [ngClass]="{
        selected: selectedEntry && selectedEntry.__Id === entry.__Id,
        approved: approvedField && entry[approvedField]
      }"
    >
      <td
        *ngFor="let column of columns"
        [ngClass]="{ 'text-truncate': column.allowOverflow === true }"
        title="{{
          entry.__AdditionalData && entry.__AdditionalData[column.fieldName]
            ? (entry.__AdditionalData[column.fieldName]
              | customValueDisplay: fields[column.fieldName]:column
              | async)
            : (entry[column.fieldName]
              | customValueDisplay: fields[column.fieldName]:column
              | async)
        }}"
        [style.width]="column.width"
      >
        {{
          entry.__AdditionalData && entry.__AdditionalData[column.fieldName]
            ? (entry.__AdditionalData[column.fieldName]
              | customValueDisplay: fields[column.fieldName]:column
              | async)
            : (entry[column.fieldName]
              | customValueDisplay: fields[column.fieldName]:column
              | async)
        }}
      </td>
    </tr>
  </tbody>
  <tfoot *ngIf="footers && footers.length">
    <tr>
      <td *ngFor="let column of columns">
        <ng-container *ngFor="let footer of footers">
          <ng-container *ngIf="footer.key == column.fieldName">
            {{ footer.value }}
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </tfoot>
</table>
