import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ResultStatus } from '@seahorse/common';
import {
  AddressPostalModel,
  AddressTypes,
  CompanyAddressModel,
  CompanyDataService,
  CompanyModel,
  CompanyTypeModel,
  CountryModel,
} from '@seahorse/domain';
import * as _ from 'underscore';
import { AddressDataService } from '../../../company/services/address-data.service';
import { CountryDataService } from '../../../superuser-nautical/services/country-data.service';
import { MultiSelectOption } from '@seahorse/forms';
import { CreateCompanyForm } from './company.form';

@Component({
  templateUrl: 'company.component.html',
})
export class CompanyFormComponent implements OnInit, AfterViewInit {
  @ViewChild('name') nameElement: ElementRef;
  @ViewChild('companyForm') clearCompanyForm: NgForm;

  isNew = false;
  form = CreateCompanyForm();
  types: CompanyTypeModel[] = [];
  companyTypeList: MultiSelectOption[] = [];
  reload = false;
  saveStarted = false;
  chooseCompanyTypesVisible = true;

  model: CompanyModel;
  companyId: number;

  email: CompanyAddressModel;
  address: AddressPostalModel;

  isCountryLoading = false;
  countries: CountryModel[] = [] as CountryModel[];

  nameExists = false;
  navigateOnSave = true;

  private activeModal = inject(NgbActiveModal, { optional: true });

  constructor(
    private companyDataService: CompanyDataService,
    private notificationService: NotificationService,
    private router: Router,
    private translate: TranslateService,
    private addressData: AddressDataService,
    private countryDataService: CountryDataService
  ) {
    this.model = new CompanyModel();
    this.loadTypes();

    this.email = new CompanyAddressModel();
    this.address = new AddressPostalModel();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.nameElement && this.nameElement.nativeElement) {
        this.nameElement.nativeElement.focus();
      }
    }, 100);
  }

  ngOnInit() {
    this.isNew = this.companyId == null || this.companyId === undefined;
    if (!this.isNew) {
      this.companyDataService.getById(this.companyId).subscribe((result) => {
        this.model = result.result;
        this.form.setValue({
          companyTypes: result.result?.companyTypes?.map((x) => x.id) ?? [],
        });
      });
    }

    this.email.type = AddressTypes.Email;
    this.email.name = this.translate.instant(
      'companies.address.addressType.email'
    );

    this.isCountryLoading = true;
    this.countryDataService.getAll().subscribe(
      (response) => {
        this.countries = response;
        this.isCountryLoading = false;
      },
      () => {
        this.isCountryLoading = false;
      }
    );
  }

  clear() {
    this.clearCompanyForm.resetForm();

    this.companyId = null;
    this.model = new CompanyModel();
    this.email = new CompanyAddressModel();

    this.address = new AddressPostalModel();
    setTimeout(() => {
      if (this.nameElement && this.nameElement.nativeElement) {
        this.nameElement.nativeElement.focus();
      }
    }, 100);
  }

  loadTypes() {
    this.companyDataService.getTypes().subscribe((response) => {
      if (response.status === ResultStatus.OK) {
        this.types = response.result;
        this.companyTypeList = response.result.map((x) => {
          return { label: x.name, value: x.id };
        });
      }
    });
  }

  close(result?: unknown) {
    this.activeModal.close(result);
  }

  saveCompanyType(typeName: string) {
    const type = new CompanyTypeModel();
    type.name = typeName.trim();
    this.companyDataService.addCompanyType(type).subscribe((response) => {
      if (response.status === ResultStatus.OK && response.result.id > 0) {
        this.notificationService.showSuccess(
          'New company type added!',
          'Success'
        );
        this.chooseCompanyTypesVisible = true;
        this.types.push(response.result);
        this.companyTypeList.push({
          label: response.result.name,
          value: response.result.id,
        });
        this.form.setValue({
          companyTypes: this.form
            .get('companyTypes')
            .value.concat([response.result.id]),
        });
      } else {
        this.notificationService.showError('Something went wrong!', 'Failed');
      }
    });
  }

  save(companyForm: NgForm) {
    this.saveStarted = true;
    this.model.companyTypes =
      this.types?.filter((x) =>
        this.form.get('companyTypes').value.includes(x.id)
      ) ?? [];

    if (this.isNew) {
      this.companyDataService.add(this.model).subscribe({
        next: (response) => {
          if (response.status === ResultStatus.OK && response.result.id > 0) {
            this.model = response.result;
            this.notificationService.showSuccess(
              'shared.terms.addSuccess',
              'shared.terms.success'
            );
          } else {
            this.notificationService.showError(
              'shared.terms.wrong',
              'shared.terms.failed'
            );
          }
          this.saveStarted = false;
        },
        complete: () => this.finalAdditions(),
      });
    } else {
      if (!companyForm.form.valid) {
        return;
      }
      this.companyDataService.update(this.model).subscribe((response) => {
        if (response.status === ResultStatus.OK) {
          this.model = response.result;

          if (this.navigateOnSave) {
            this.router
              .navigateByUrl('/companies/overview', {
                skipLocationChange: true,
              })
              .then(() =>
                this.router.navigate([`/companies/${this.model.id}`])
              );
          } else {
            this.close({ ...this.model });
          }

          this.notificationService.showSuccess(
            'shared.terms.updateSuccess',
            'shared.terms.success'
          );
        } else {
          this.notificationService.showError(
            'shared.terms.wrong',
            'shared.terms.failed'
          );
        }
        this.saveStarted = false;
      });
    }
  }

  finalAdditions() {
    let alreadyExistingAddress: CompanyAddressModel;
    if (!this.isNew) {
      alreadyExistingAddress = this.findPrimaryAddress();
    }

    this.model.companyAddresses = [];
    const addressModels: CompanyAddressModel[] = [];

    let i = 0;

    if (
      this.address.addressLine1 ||
      this.address.city ||
      this.address.zip ||
      this.address.country
    ) {
      const address = new CompanyAddressModel();
      {
        address.companyId = this.model.id;
        address.type = AddressTypes.Postal;
        address.name = this.translate.instant(
          'companies.address.addressType.postal'
        );
        address.value = this.prepareAddressPostalCode(this.address);
        address.postalModel = this.address;
      }

      address.isPrimary = true;

      addressModels.push(address);
    }

    if (this.email.value) {
      this.email.companyId = this.model.id;

      addressModels.push(this.email);
    }

    if (addressModels.length) {
      _.forEach(addressModels, (addressModel) => {
        if (this.isNew || alreadyExistingAddress === undefined) {
          this.addressData.add(addressModel).subscribe({
            next: (r) => {
              if (r.hasResult) {
                this.model.companyAddresses.push(r.result);
              }
            },
            complete: () => {
              i++;
              if (addressModels.length === i) {
                if (this.reload) {
                  this.clear();
                  this.reload = false;
                } else {
                  this.close({ ...this.model });
                }
              }
            },
          });
        } else {
          addressModel.id = alreadyExistingAddress.id;
          this.addressData.update(addressModel).subscribe({
            next: (r) => {
              if (r.hasResult) {
                this.model.companyAddresses.push(r.result);
              }
            },
            complete: () => {
              i++;
              if (addressModels.length === i) {
                if (this.reload) {
                  this.clear();
                } else {
                  this.close({ ...this.model });
                }
              }
            },
          });
        }
      });
    } else {
      if (this.reload) {
        this.clear();
      } else {
        this.close({ ...this.model });
      }
    }
  }

  prepareAddressPostalCode(postalModel: AddressPostalModel): string {
    return (
      postalModel.addressLine1 +
      ';' +
      postalModel.addressLine2 +
      ';' +
      postalModel.zip +
      ';' +
      postalModel.city +
      ';' +
      postalModel.state +
      ';' +
      postalModel.country
    );
  }

  findPrimaryAddress() {
    return this.model.companyAddresses.find(
      (x) => x.isPrimary && x.type === AddressTypes.Postal
    );
  }

  nameExistsCheck() {
    if (this.isNew && this.model && this.model.name && this.model.name !== '') {
      this.companyDataService.nameExists(this.model.name).subscribe({
        next: (response) => {
          if (response.hasResult) this.nameExists = response.result;
          else
            this.notificationService.displayErrorNotification(
              response.messages
            );
        },
        error: (e) => this.notificationService.displayErrorNotification(e),
      });
    } else this.nameExists = false;
  }
}
