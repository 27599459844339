import { Injectable, Injector } from '@angular/core';

import {
  BaseFieldRule,
  FieldDefinitionModel,
  FieldType,
  FieldVisibility,
  ObjectDefinitionModel,
  ProxyService,
} from '@seahorse/domain';
import {
  FormModel,
  FormField,
  FormFieldValidation,
  IncrementValuesListItem,
} from './form.model';
import { FormService } from './form.service';

@Injectable({
  providedIn: 'root',
})
export class CustomDataFormService {
  private _fieldTypeRules: { [key: number]: BaseFieldRule };

  constructor(
    private _globalCache: ProxyService,
    private _formService: FormService
  ) {
    this.setFieldTypeRules();
  }

  private setFieldTypeRules() {
    const fieldTypeDefinitions = this._globalCache.fieldTypeDefinitions;

    if (!fieldTypeDefinitions) {
      this._fieldTypeRules = {};
      return;
    }

    this._fieldTypeRules = this._globalCache.fieldTypeDefinitions.reduce(
      (acc, cur) => {
        acc[cur.id] = cur.rule;
        return acc;
      },
      {}
    );
  }

  openFormModal(
    definition: ObjectDefinitionModel,
    fields: string[],
    value?: any,
    injector?: Injector
  ) {
    const form = this.getFormModel(definition, fields, value);
    return this._formService.openFormModal(form, definition.name, injector);
  }

  openFieldModal(
    definition: ObjectDefinitionModel,
    field: string,
    value: any,
    injector?: Injector
  ) {
    const form = this.getFieldModel(definition, field, value);
    return this._formService.openFieldModal(form, injector);
  }

  getFormModel(
    definition: ObjectDefinitionModel,
    fields: string[],
    value = null,
    publicOnly = true
  ): FormModel {
    const fieldDefinitions = ObjectDefinitionModel.getAllFieldDefinitions(
      definition
    ).filter(
      (def) =>
        def.fieldType !== FieldType.AutoNumbering &&
        !def.isReadOnly &&
        def.visibility !== FieldVisibility.Hidden &&
        (publicOnly ? def.visibility !== FieldVisibility.Private : true) &&
        (fields?.length > 0 ? fields?.includes(def.systemCode) : true)
    );

    const formFields = this.getFormFields(fieldDefinitions, value);

    const form: FormModel = {
      fields: formFields,
      value,
    };

    return form;
  }

  getFieldModel(
    definition: ObjectDefinitionModel,
    field: string,
    value = null
  ): FormModel {
    const fieldDefinition = ObjectDefinitionModel.getAllFieldDefinitions(
      definition
    ).find((item) => item.systemCode === field);
    const formFields = this.getFormField(fieldDefinition, value);

    const form: FormModel = {
      fields: [formFields],
      value: { [field]: value },
    };

    return form;
  }

  private getFormFields(
    fieldDefinitions: FieldDefinitionModel[],
    value = null
  ): FormField[] {
    return fieldDefinitions.map((field) => this.getFormField(field, value));
  }

  private getFormField(field: FieldDefinitionModel, value: any): FormField {
    return {
      key: field.systemCode,
      name: field.name,
      groupName: field.groupName,
      type: field.fieldType,
      isRequired: field.isRequired,
      isDisabled: field.isReadOnly,
      validation: this.getFieldValidation(field),
      options: {
        fieldDefinition: field,
        fieldCode: field.fieldCode,
        formatting:
          value && value.__DataFieldFormatting
            ? value.__DataFieldFormatting[field.systemCode]
            : undefined,
        mode:
          field.additionalDataModel && field.additionalDataModel.mode
            ? field.additionalDataModel.mode.edit
            : undefined,
        listItems: field.fieldRule?.['items']?.length
          ? field.fieldRule['items']
          : undefined,
        appendText: field?.fieldRule?.['valueDescriptor'],
        incrementValuesList: this.getIncrementValuesList(field),
      },
    };
  }

  private getFieldValidation(
    field: FieldDefinitionModel
  ): Partial<FormFieldValidation> {
    switch (field.fieldType) {
      case FieldType.SingleLineText:
      case FieldType.MultiLineText:
        return {
          maxLength: this.maxLength(field),
          minLength: this.minLength(field),
          pattern: this.pattern(field),
        };

      case FieldType.Date:
      case FieldType.DateTime:
        if (!field.fieldRule) {
          return undefined;
        }

        return {
          format: BaseFieldRule.date(field.fieldRule).format,
          minDate: BaseFieldRule.date(field.fieldRule).minDate,
          maxDate: BaseFieldRule.date(field.fieldRule).maxDate,
        };
      case FieldType.Time:
        if (!field.fieldRule) {
          return {
            format: 'HH:mm',
          };
        }

        return {
          format: BaseFieldRule.time(field.fieldRule).format,
          minTime: BaseFieldRule.time(field.fieldRule).minTime,
          maxTime: BaseFieldRule.time(field.fieldRule).maxTime,
        };
      case FieldType.Decimal:
        if (!field.fieldRule) {
          return {
            numDecimals: 2,
          };
        }

        return {
          numDecimals: BaseFieldRule.decimal(field.fieldRule).digits,
        };
      case FieldType.Currency:
        if (!field.fieldRule) {
          return {
            numDecimals: 2,
          };
        }

        return {
          numDecimals: BaseFieldRule.decimal(field.fieldRule).digits,
          currencyCodes: field.fieldRule['currencyCodes'],
        };
      case FieldType.List:
        if (!field.fieldRule) {
          return undefined;
        }

        return {
          max: BaseFieldRule.list(field.fieldRule).max,
          mustMatch: BaseFieldRule.list(field.fieldRule).mustMatch,
        };

      default:
        return undefined;
    }
  }

  private maxLength(field: FieldDefinitionModel) {
    if (!field.fieldRule) {
      return null;
    }

    let rule = BaseFieldRule.singleLineText(field.fieldRule).maxLength || null;

    if (this._fieldTypeRules[field.fieldTypeDefinitionId]) {
      rule =
        rule ||
        BaseFieldRule.singleLineText(
          this._fieldTypeRules[field.fieldTypeDefinitionId]
        ).maxLength;
    }

    return rule;
  }

  private minLength(field: FieldDefinitionModel) {
    if (!field.fieldRule) {
      return null;
    }

    let rule = BaseFieldRule.singleLineText(field.fieldRule).minLength || null;

    if (this._fieldTypeRules[field.fieldTypeDefinitionId]) {
      rule =
        rule ||
        BaseFieldRule.singleLineText(
          this._fieldTypeRules[field.fieldTypeDefinitionId]
        ).minLength;
    }

    return rule;
  }

  private pattern(field: FieldDefinitionModel) {
    if (!field.fieldRule) {
      return null;
    }

    let rule =
      BaseFieldRule.singleLineText(field.fieldRule).regexPattern || null;

    if (this._fieldTypeRules[field.fieldTypeDefinitionId]) {
      rule =
        rule ||
        BaseFieldRule.singleLineText(
          this._fieldTypeRules[field.fieldTypeDefinitionId]
        ).regexPattern;
    }

    return rule;
  }

  private getIncrementValuesList(
    field: FieldDefinitionModel
  ): IncrementValuesListItem[] | undefined {
    return field?.additionalDataModel?.incrementValues
      ? Object.entries(field.additionalDataModel.incrementValues)
          .map(([key, value]) => {
            return { display: key, value: +value };
          })
          .sort((a, b) => a.value - b.value)
      : undefined;
  }
}
