import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ObjectDefinitionModel,
  ProxyService,
  UiModule,
} from '@seahorse/domain';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as _ from 'underscore';
import { FieldTypeDefinitionsDataService } from '../../custom-data/services/field-type-definitions-data.service';
import { ObjectDefinitionsDataService } from '../../custom-data/services/object-definitions-data.service';
import { UiModulesDataService } from '../../custom-modules/services/ui-modules-data.service';
import { FeatureReleaseDataService } from '../../shared/services/feature-release-data.service';

@Injectable({
  providedIn: 'root',
})
export class ProxyServices extends ProxyService {
  private _featureReleaseDataService = inject(FeatureReleaseDataService);

  constructor(
    private _fieldTypeDefinitionsDataService: FieldTypeDefinitionsDataService,
    private _objectDefinitionsDataService: ObjectDefinitionsDataService,
    private _uiModulesDataService: UiModulesDataService,
    private _translateService: TranslateService
  ) {
    super();
  }

  getData(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.resetObjectKeyMappings();

      const filedTypes = this._fieldTypeDefinitionsDataService.get();
      const customObjects = this._objectDefinitionsDataService.getAll(
        -1,
        -1,
        true
      );
      const uiModules = this._uiModulesDataService.getAll();
      const featureReleaseData = this._featureReleaseDataService.getAll();

      forkJoin([
        filedTypes,
        customObjects,
        uiModules,
        featureReleaseData,
      ]).subscribe(
        (results) => {
          const filedTypesResult = results[0];
          const customObjectsResult = results[1];
          const uiModulesResult = results[2];
          const featureReleaseData = results[3];

          if (filedTypesResult.hasResult) {
            this.fieldTypeDefinitions = filedTypesResult.result;
          }

          if (customObjectsResult.hasResult) {
            this.objectDefinitions = _.map(
              customObjectsResult.result,
              (customObjectMap) => {
                if (customObjectMap.objectFieldDefinitions) {
                  _.each(customObjectMap.objectFieldDefinitions, (fieldMap) => {
                    if (fieldMap.additionalData) {
                      try {
                        fieldMap.additionalDataModel = JSON.parse(
                          fieldMap.additionalData
                        );
                      } catch {
                        this._notificationService.displayErrorNotification();
                      }
                    }
                  });
                }
                if (
                  customObjectMap.baseObjectDefinition &&
                  customObjectMap.baseObjectDefinition.objectFieldDefinitions
                ) {
                  _.each(
                    customObjectMap.baseObjectDefinition.objectFieldDefinitions,
                    (fieldMap) => {
                      if (fieldMap.additionalData) {
                        try {
                          fieldMap.additionalDataModel = JSON.parse(
                            fieldMap.additionalData
                          );
                        } catch {
                          this._notificationService.displayErrorNotification();
                        }
                      }
                    }
                  );
                }
                return customObjectMap;
              }
            );
            this.resetObjectKeyMappings();
          }

          this.uiModules = uiModulesResult;

          if (featureReleaseData.count)
            this.enabledFeatures = featureReleaseData.result;

          subscriber.next(true);
          subscriber.complete();
        },
        () => {
          subscriber.next(false);
          subscriber.complete();
        }
      );
    });
  }

  deleteObjectDefinitions(definitionId: number) {
    if (this.objectDefinitions) {
      const index = _.findIndex(
        this.objectDefinitions,
        (def) => def.id === definitionId
      );
      if (index > -1) {
        this.objectDefinitions.splice(index, 1);
      }
    }

    this.resetObjectKeyMappings();
  }

  getBaseObjectDefinitionById(id: number): ObjectDefinitionModel {
    return _.find(
      this.objectDefinitions,
      (filter) =>
        filter.baseObjectDefinition && filter.baseObjectDefinition.id === id
    )?.baseObjectDefinition;
  }

  getObjectDefinitionById(id: number): ObjectDefinitionModel {
    return _.find(this.objectDefinitions, (obj) => obj.id === id);
  }

  getObjectDefinitionByMappingKey(mappingKey: string): ObjectDefinitionModel {
    return _.find(
      this.objectDefinitions,
      (obj) => obj.mappingKey?.toLowerCase() === mappingKey.toLowerCase()
    );
  }

  getObjectDefinitionsByMappingKey(
    mappingKey: string
  ): ObjectDefinitionModel[] {
    return _.filter(
      this.objectDefinitions,
      (obj) => obj.mappingKey?.toLowerCase() === mappingKey.toLowerCase()
    );
  }

  getObjectDefinitionsForLinkedObject(
    linkedObjectKey: string
  ): ObjectDefinitionModel[] {
    return _.filter(this.objectDefinitions, (filterItem) =>
      ObjectDefinitionModel.hasLinkedObject(filterItem, linkedObjectKey)
    );
  }

  updateObjectDefinitions(definition: ObjectDefinitionModel) {
    if (!this.objectDefinitions) {
      this.objectDefinitions = [];
    }

    if (!definition) {
      this._objectDefinitionsDataService
        .getAll(-1, -1, true)
        .subscribe((response) => {
          if (response) {
            this.objectDefinitions = response.result;
          }
        });
    } else {
      const clone = JSON.parse(JSON.stringify(definition)); // use json to clone the nested objects
      const index = _.findIndex(
        this.objectDefinitions,
        (def) => def.id === definition.id
      );
      if (index === -1) {
        this.objectDefinitions.push(clone);
      } else {
        this.objectDefinitions[index] = clone;
      }
    }

    this.resetObjectKeyMappings();
  }

  updateObjectDefinitionsById(objectDefinitionId: number) {
    this._objectDefinitionsDataService
      .getById(objectDefinitionId)
      .subscribe((response) => {
        if (response) {
          this.updateObjectDefinitions(response.result);
        }
      });
  }

  updateUiModules(
    uiModule: UiModule,
    changeDB?: boolean
  ): Observable<UiModule> | void {
    const index = this.uiModules.findIndex((x) => x.id === uiModule.id);
    const addOrUpdate = (response) => {
      if (response.hasResult) {
        let successMessage = this._translateService.instant(
          'shared.terms.updateSuccess'
        );
        if (index >= 0) this.uiModules[index] = response.result;
        else {
          this.uiModules.push(response.result);
          successMessage = this._translateService.instant(
            'shared.terms.addSuccess'
          );
        }
        this._notificationService.showSuccess(
          successMessage,
          this._translateService.instant('shared.terms.success')
        );
      } else
        this._notificationService.displayErrorNotification(response.messages);
    };
    if (changeDB === true) {
      if (index >= 0)
        return this._uiModulesDataService.update(uiModule).pipe(
          tap((response) => addOrUpdate(response)),
          map((response) => response.result),
          catchError((e) => {
            this._notificationService.displayErrorNotification(e);
            return throwError(e);
          })
        );
      else
        return this._uiModulesDataService.add(uiModule).pipe(
          tap((response) => addOrUpdate(response)),
          map((response) => response.result),
          catchError((e) => {
            this._notificationService.displayErrorNotification(e);
            return throwError(e);
          })
        );
    } else {
      if (index >= 0) this.uiModules[index] = uiModule;
      else this.uiModules.push(uiModule);
    }
  }

  deleteUiModule(
    uiModuleId: number,
    changeDB?: boolean
  ): Observable<UiModule> | void {
    const index = this.uiModules.findIndex((x) => x.id === uiModuleId);
    if (index >= 0) {
      if (changeDB === true) {
        this._uiModulesDataService.delete(uiModuleId).subscribe({
          next: (response) => {
            if (response.hasResult) {
              this.uiModules.splice(index, 1);
              return response.result;
            } else
              this._notificationService.displayErrorNotification(
                response.messages
              );
          },
          error: (e) => {
            this._notificationService.displayErrorNotification(e);
            return throwError(e);
          },
        });
      } else this.uiModules.splice(index, 1);
    }
  }
}
