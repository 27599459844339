import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Attribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule, NgForm, NgModel } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicsService, SeahorseTemplateDirective } from '@seahorse/common';
import {
  BaseFieldRule,
  CustomDataBaseModel,
  FieldType,
} from '@seahorse/domain';
import { YESNOOPTIONS } from '../controls/constants';
import { DateTimePickerComponent } from '../controls/date-time-picker/date-time-picker.component';
import { LinkedObjectControl } from '../controls/linked-object/linked-object.control';
import { LinkedObjectsControl } from '../controls/linked-objects/linked-objects.control';
import { ListControl } from '../controls/list/list.control';
import { NumericInputComponent } from '../controls/numeric-input/numeric-input.component';
import { UserListControl } from '../controls/user-list/user-list.control';

import { FormInteractionService } from './form-interaction.service';
import {
  CustomFieldControl,
  CUSTOM_FORM_CONTROLS,
  FormField,
  FormModel,
} from './form.model';
import { FormService } from './form.service';

@Component({
  selector: 'temp-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NumericInputComponent,
    DateTimePickerComponent,
    ListControl,
    UserListControl,
    TranslateModule,
    LinkedObjectControl,
    LinkedObjectsControl,
    SeahorseTemplateDirective,
  ],
})
export class FormComponent implements AfterViewInit {
  @ViewChildren(SeahorseTemplateDirective)
  templates: QueryList<SeahorseTemplateDirective>;

  get isSubmitted() {
    return !!this.form && this.form.submitted;
  }

  get isEdit() {
    return this.model.value != null || this.value != null;
  }

  get fields() {
    return this.model ? this.model.fields : [];
  }

  get noValidate() {
    if (!this.model || !this.model.options) {
      return false;
    }

    return this.model.options.noValidate;
  }

  private _formService = inject(FormService);
  private _customFormControlFactory = inject(CUSTOM_FORM_CONTROLS, {
    optional: true,
  });
  customFormControls: { [key: string]: CustomFieldControl<any> } = {};

  constructor(
    private _cdr: ChangeDetectorRef,
    private _dynamicsService: DynamicsService,
    @Optional()
    _interactionService?: FormInteractionService,
    @Attribute('columns') public columns?: string
  ) {
    if (_interactionService) {
      _interactionService.formRef = this;
    }
  }
  @ViewChild(NgForm) form: NgForm;
  @ViewChild(NgForm, { read: ElementRef })
  formElRef: ElementRef<HTMLFormElement>;

  @ViewChildren('childForm') childForms: QueryList<FormComponent>;

  @Input() value: any;

  private _model: FormModel;
  @Input() set model(val: FormModel) {
    if (!val) {
      return;
    }

    this._model = val;
    this.setUpForm(val);
  }

  get model() {
    return this._model;
  }

  nameSuffix = Math.floor(Math.random() * 1000);

  // eslint-disable-next-line
  @Output('submit') onSubmit = new EventEmitter<any>();

  FieldType = FieldType;
  FieldRule = BaseFieldRule;
  yesNoOptions = YESNOOPTIONS;
  fieldGroups?: { [group: string]: FormField[] };

  @HostListener('keydown.escape')
  onEscape() {
    if (this._formService.isModalEditActive) {
      this._formService.closeModals();
    }
  }

  private setUpForm(model: FormModel) {
    if (this.isEdit) {
      this.value = this.value ?? Object.assign({}, model.value);
    } else {
      this.value = {};
    }

    if (this._customFormControlFactory) {
      this.fields.forEach((field) => {
        const customControl = this._customFormControlFactory(field);

        if (customControl) {
          this.customFormControls[field.key] = customControl;
        }
      });
    }

    this.fieldGroups = this.fields.reduce((acc, cur) => {
      const groupName = cur.groupName || '-1';
      acc[groupName] = acc[groupName] || [];
      acc[groupName].push(cur);

      return acc;
    }, {});
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const elements =
        this.formElRef.nativeElement.querySelectorAll<HTMLElement>(
          'input:not(.invisible), textarea, select'
        );
      elements[0]?.focus();
    });

    this.templates.forEach((template) => {
      template.vcRef.clear();
      const customControl = this.customFormControls?.[template.id];

      if (customControl) {
        this.createCustomControl(template, customControl);
      }
    });
  }

  private createCustomControl(
    template: SeahorseTemplateDirective,
    control: CustomFieldControl<any>
  ) {
    const value = this.value[template.id];
    const data = control.data(value);

    this._dynamicsService.createComponent(
      {
        component: control.component,
        props: { ...data },
      },
      template.vcRef
    );

    data.valueChange$.subscribe((value) => {
      this.value[template.id] = value;
    });
  }

  submit() {
    this.form.onSubmit(new Event('submit'));

    const valid = this.form.valid || this.noValidate;

    if (!valid) {
      this._cdr.detectChanges();
      return;
    }

    const value = Object.assign({}, this.value);
    this.adjustValules(value);

    if (this.childForms.length > 0) {
      this.childForms.forEach((form) => form.submit());
    }

    this.onSubmit.emit(value);
    return value;
  }

  assignJsonValue(value: object, key: string, isJson: boolean) {
    this.value[key] = isJson ? JSON.stringify(value) : value;
  }

  reset() {
    this.form.resetForm();
    this.value = {} as CustomDataBaseModel;
  }

  isValid({ control }: NgModel) {
    return control.valid || this.noValidate;
  }

  getError({ control }: NgModel) {
    return Object.keys(control.errors)[0];
  }

  // TODO: remove when (boolean) custom form controls are implemented
  private adjustValules(value: any) {
    // send false for null boolean values
    const booleanFields = this.fields
      .filter((field) => field.type === FieldType.Boolean)
      .map((field) => field.key);

    Object.keys(value).forEach((key) => {
      if (!booleanFields.includes(key) || value[key] !== null) {
        return;
      }

      value[key] = false;
    });
  }

  getValidation(field: FormField, validationKey: string) {
    if (this.model.options && this.model.options.noValidate) {
      return null;
    }

    return this.getFieldOption(field, validationKey);
  }

  getFieldOption(field: FormField, validationKey: string) {
    if (!field.validation) {
      return null;
    }

    return field.validation[validationKey];
  }

  updateFormatting(field: FormField, key: string, formatting: object) {
    field.options['formatting'] = formatting;

    if (this.value.__DataFieldFormatting) {
      if (this.value.__DataFieldFormatting[key]) {
        Object.assign(this.value.__DataFieldFormatting[key], formatting);
      } else {
        this.value.__DataFieldFormatting[key] = formatting;
      }
    } else {
      this.value['__DataFieldFormatting'] = { [key]: formatting };
    }
  }
}
