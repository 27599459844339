<div
  tempFileDragDrop
  [isDropAllowed]="notificationBodyLoaded && !loadingPreview"
  (fileUploaded)="fileUploaded($event)"
>
  <div class="modal-header">
    <h4 class="modal-title-bold" *ngIf="showHeader">
      {{ 'messages.send' | translate }}
    </h4>
    <button type="button" class="close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <form [formGroup]="form">
          <div class="form-group">
            <label
              ><b
                >{{ 'notifications.recipientAddresses' | translate }}:</b
              ></label
            >
            <sh-multi-select
              formControlName="selectedRecipients"
              [options]="recipientOptions"
              [mustMatchOptions]="false"
            ></sh-multi-select>
          </div>
          <sh-error-message-panel
            [httpError]="addressError"
          ></sh-error-message-panel>
          <sh-error-message-panel
            [httpError]="previewError"
          ></sh-error-message-panel>
          <sh-loading-indicator *ngIf="loadingPreview"></sh-loading-indicator>
          <div
            class="form-group"
            *ngIf="notificationBodyLoaded && !loadingPreview"
          >
            <label class="control-label" for="subject"
              ><b>{{ 'notifications.subject' | translate }}</b></label
            >
            <input
              type="text"
              class="form-control"
              id="subject"
              formControlName="subject"
            />
          </div>
          <ng-container *ngIf="allowPreview">
            <div
              class="text-right"
              *ngIf="!notificationBodyLoaded && !loadingPreview"
            >
              <button type="button" class="btn btn-link" (click)="preview()">
                {{ 'shared.terms.preview' | translate }}
              </button>
            </div>
          </ng-container>
          <div *ngIf="notificationBodyLoaded">
            <editor
              formControlName="notificationBody"
              [init]="{
                base_url: '/tinymce',
                suffix: '.min',
                plugins: 'lists link image table code help wordcount',
                menubar: false,
                min_height: 350
              }"
            ></editor>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ca-template-files
    *ngIf="
      notificationBodyLoaded &&
      !loadingPreview &&
      (includedFiles.length > 0 || includedTemplates.length > 0)
    "
    [templates]="includedTemplates"
    [files]="includedFiles"
    [objectIds]="objectIds"
    [objectType]="objectType"
    [searchFields]="searchFields"
    [exportOption]="exportOption"
    (delete)="fileDelete($event)"
  ></ca-template-files>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="dismiss()">
      {{ 'shared.terms.cancel' | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="ok()">
      {{ 'shared.terms.ok' | translate }}
    </button>
  </div>
</div>
