import { BusinessLogicTimePeriods } from './business-logic-calculation.model';
import { BusinessLogicObject } from './business-logic-object.model';
import {
  ArrayOperators,
  BooleanOperators,
  ConditionScheduleTypes,
  DateOperators,
  NumericOperators,
  ScheduleOperators,
  StringOperators,
  TimespanOperators,
} from './operators.enum';

export class BusinessLogicCondition extends BusinessLogicObject {}

export class ConditionArrayModel extends BusinessLogicCondition {
  sourceField: string;
  operator: ArrayOperators;
  values: string[];
}

export class ConditionBooleanModel extends BusinessLogicCondition {
  sourceField: string;
  operator: BooleanOperators;
}

export class ConditionDateModel extends BusinessLogicCondition {
  sourceField: string;
  operator: DateOperators;
  value: Date;
  secondValue: Date;
}

export class ConditionNumericModel extends BusinessLogicCondition {
  sourceField: string;
  operator: NumericOperators;
  value: number;
  secondValue: number;
}

export class ConditionScheduleModel extends BusinessLogicCondition {
  sourceFieldMin: string;
  sourceFieldMax: string;
  operator: ScheduleOperators;
  value: string;
  items: ConditionScheduleItem[];
}

export class ConditionStringModel extends BusinessLogicCondition {
  sourceField: string;
  operator: StringOperators;
  value: string;
}

export class ConditionTimespanModel extends BusinessLogicCondition {
  sourceFieldMin: string;
  sourceFieldMax: string;
  operator: TimespanOperators;
  operatorPeriod: BusinessLogicTimePeriods;
  value: number;
}

export class ConditionScheduleItem {
  from: ConditionScheduleObject;
  to: ConditionScheduleObject;
}

export class ConditionScheduleObject {
  scheduleType?: ConditionScheduleTypes;
  value?: string;
}
