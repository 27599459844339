import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import {
  CustomDataSearchFieldModel,
  CustomDefaultDataModel,
} from '@seahorse/domain';
import { LanguageCode } from 'iso-639-1';
import { Observable } from 'rxjs';

import {
  ExportOptionItemModel,
  ExportOptionModel,
} from '../models/content-distribtion-export-option.model';
import { ContentDistributionLogModel } from '../models/content-distribution-log.model';
import { ContentDistributionRecipientModel } from '../models/content-distribution-recipient.model';
import { GenericDataExportModel } from '../models/generic-data-export.model';
import { ImportDefinitionModel } from '../models/import-definition.model';
import { HttpParams } from '@angular/common/http';
import { DefaultDataImportRequestModel } from '../models/content-distribution-import-request.model';

@Injectable({
  providedIn: 'root',
})
export class ContentDistributionDataService {
  constructor(private dataContext: DataContextService) {}

  distributeTemplate(
    templateId: number,
    documentType: number,
    objectIds: any[],
    recipients: ContentDistributionRecipientModel[],
    objectType: string,
    searchFields: CustomDataSearchFieldModel[] = [],
    body?: string,
    subject?: string,
    data?: string
  ): Observable<any> {
    return this.dataContext.post('contentdistribution/distribute', {
      templateId,
      documentType,
      objectIds,
      recipients,
      objectType,
      searchFields,
      body,
      subject,
      data,
    });
  }

  distributeSimpleMessage(
    templateId: number,
    documentType: number,
    objectIds: any[],
    recipients: ContentDistributionRecipientModel[],
    objectType: string,
    searchFields: CustomDataSearchFieldModel[] = [],
    body?: string,
    subject?: string
  ): Observable<any> {
    return this.dataContext.post(
      'contentdistribution/distribute-simpleMessage',
      {
        templateId,
        documentType,
        objectIds,
        recipients,
        objectType,
        searchFields,
        body,
        subject,
      }
    );
  }

  getDistributionAddresses(
    templateId: number,
    objectIds: any[],
    objectType: string
  ): Observable<ResultWrapper<ContentDistributionRecipientModel[]>> {
    return this.dataContext.post<ContentDistributionRecipientModel[]>(
      'contentdistribution/distribute/addresses',
      {
        templateId,
        objectIds,
        objectType,
      }
    );
  }

  generateExport(
    exportOption: ExportOptionItemModel,
    objectIds: any[],
    objectType: string,
    searchFields: CustomDataSearchFieldModel[] = [],
    data?: string
  ): Observable<boolean> {
    let parameters = `objectIds=`; //${objectIds.join(',')}`;

    if (exportOption.language) {
      parameters = `${parameters}&iso6391=${exportOption.language}`;
    }

    return this.dataContext.download(
      `contentdistribution/export?${parameters}`,
      null,
      {
        searchFields: searchFields,
        templateId: exportOption.templateId,
        documentTypeId: exportOption.documentType.id,
        objectIds: objectIds,
        objectType: objectType,
        data: data,
      }
    );
  }

  generateExportDownload(
    templateId: number,
    documentTypeId: number,
    objectIds: any[],
    objectType: string,
    searchFields: CustomDataSearchFieldModel[] = []
  ): Observable<boolean> {
    const parameters = `objectIds=`; //${objectIds.join(',')}`;

    return this.dataContext.download(
      `contentdistribution/export?${parameters}`,
      null,
      {
        searchFields: searchFields,
        templateId: templateId,
        documentTypeId: documentTypeId,
        objectIds: objectIds,
        objectType: objectType,
      }
    );
  }

  generateExportBodyPreview(
    templateId: number,
    documentTypeId: number,
    objectIds: any[],
    objectType: string,
    searchFields: CustomDataSearchFieldModel[] = [],
    type: string
  ): Observable<Blob> {
    const parameters = `objectIds=${objectIds.join(',')}`;

    return this.dataContext.getBlob(
      `contentdistribution/export?${parameters}`,
      {
        searchFields: searchFields,
        templateId: templateId,
        documentTypeId: documentTypeId,
        objectType: objectType,
      },
      type
    );
  }

  generateExportBody(
    exportOption: ExportOptionItemModel,
    objectIds: any[],
    objectType: string,
    searchFields: CustomDataSearchFieldModel[] = [],
    data?: string
  ): Observable<string> {
    let parameters = objectIds ? `objectIds=${objectIds.join(',')}` : '';

    if (exportOption.language) {
      parameters = `${parameters}&iso6391=${exportOption.language}`;
    }

    return this.dataContext.postRaw(
      `contentdistribution/export?${parameters}`,
      {
        searchFields: searchFields,
        templateId: exportOption.templateId,
        documentTypeId: exportOption.documentType.id,
        objectType: objectType,
        data: data,
      }
    );
  }

  generateExportPreview(
    exportOption: ExportOptionItemModel,
    objectIds: any[],
    objectType: string,
    searchFields: CustomDataSearchFieldModel[] = [],
    type: string
  ): Observable<Blob> {
    let parameters = `objectIds=`; //${objectIds.join(',')}`;

    if (exportOption.language) {
      parameters = `${parameters}&iso6391=${exportOption.language}`;
    }

    return this.dataContext.getBlob(
      `contentdistribution/export?${parameters}`,
      {
        searchFields: searchFields,
        templateId: exportOption.templateId,
        documentTypeId: exportOption.documentType.id,
        objectIds: objectIds,
        objectType: objectType,
      },
      type
    );
  }

  applyTemplateContent(
    template: string,
    templateId: number,
    mappingKey: string,
    objectIds: string[],
    data?: string
  ): Observable<any> {
    const url = 'contentdistribution/apply-content';
    return this.dataContext.post(url, {
      template: template,
      templateId: templateId,
      mappingKey: mappingKey,
      objectIds: objectIds,
      data: data,
    });
  }

  generateExportForData(
    exportData: GenericDataExportModel,
    exportOption: ExportOptionItemModel,
    exportType: ExportType = ExportType.Download
  ): Observable<any> {
    let parameters = `templateId=${exportOption.templateId}&documentType=${exportOption.documentType.id}`;

    if (exportOption.language) {
      parameters = `${parameters}&iso6391=${exportOption.language}`;
    }

    if (exportType === ExportType.Distribtion) {
      return this.dataContext.post(
        `contentdistribution/export/generic?${parameters}`,
        exportData
      );
    }

    if (exportType === ExportType.Download) {
      return this.dataContext.download(
        `contentdistribution/export/generic?${parameters}`,
        null,
        exportData
      );
    }
  }

  store(
    exportOption: ExportOptionItemModel,
    objectIds: any[],
    objectType: string,
    searchFields: CustomDataSearchFieldModel[] = []
  ): Observable<any> {
    let params = new HttpParams();

    if (exportOption.language) {
      params = params.set('iso6391', exportOption.language);
    }

    return this.dataContext.post(`contentdistribution/store?${params}`, {
      searchFields: searchFields,
      templateId: exportOption.templateId,
      documentTypeId: exportOption.documentType.id,
      objectIds: objectIds,
      objectType: objectType,
    });
  }

  storeForData(
    exportData: GenericDataExportModel,
    exportOption: ExportOptionItemModel,
    objectIds: string[],
    objectType: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('documentType', exportOption.documentType.id)
      .set('templateId', exportOption.templateId)
      .set('objectType', objectType);

    objectIds.forEach((id) => {
      params = params.append('objectIds', id.toString());
    });

    if (exportOption.language) {
      params = params.set('iso6391', exportOption.language);
    }

    return this.dataContext.post(
      `contentdistribution/store/generic?${params}`,
      exportData
    );
  }

  getDetails(
    logId: number
  ): Observable<ResultWrapper<ContentDistributionLogModel>> {
    return this.dataContext.get(`contentdistribution/${logId}`);
  }

  getImportDefinitions(): Observable<ResultWrapper<ImportDefinitionModel[]>> {
    return this.dataContext.get(`contentdistribution/importdefinition`);
  }

  getLoggingForObject(
    objectType: string
  ): Observable<ResultWrapper<ContentDistributionLogModel[]>> {
    return this.dataContext.get(`contentdistribution/logging/${objectType}`);
  }

  getLoggingForObjectById(
    objectType: string,
    contentId: string
  ): Observable<ResultWrapper<ContentDistributionLogModel[]>> {
    return this.dataContext.get(
      `contentdistribution/logging/${objectType}/${contentId}`
    );
  }

  getExportOptions(
    iso6391?: LanguageCode
  ): Observable<ResultWrapper<ExportOptionModel[]>> {
    const url = iso6391
      ? `contentdistribution/export/options?iso6391=${iso6391}`
      : 'contentdistribution/export/options';

    return this.dataContext.get<ExportOptionModel[]>(url);
  }

  exportExact(objectId: any): Observable<ResultWrapper<any>> {
    return this.dataContext.get<any>(
      `contentdistribution/exactonline/export/${objectId}`
    );
  }

  import(
    file: File,
    contentType: string,
    configuration: any
  ): Observable<ResultWrapper<any>> {
    const formData = new FormData();

    formData.append('configuration', JSON.stringify(configuration));
    formData.append('file', file, file.name);

    return this.dataContext.post<ResultWrapper<any>>(
      `contentdistribution/import/${contentType}`,
      formData
    );
  }

  interpretImport(
    file: File,
    contentType: string,
    configuration: any,
    objectKey: string
  ): Observable<ResultWrapper<any>> {
    const formData = new FormData();

    formData.append('configuration', JSON.stringify(configuration));
    formData.append('file', file, file.name);
    formData.append('objectKey', objectKey);

    return this.dataContext.post<ResultWrapper<any>>(
      `contentdistribution/import/${contentType}/interpret`,
      formData
    );
  }

  importDefaultData(
    customDefaultDataId: CustomDefaultDataModel['id'],
    defaultDataImportRequest: DefaultDataImportRequestModel
  ): Observable<ResultWrapper<boolean>> {
    return this.dataContext.post<boolean>(
      `contentdistribution/import-default-data/${customDefaultDataId}`,
      defaultDataImportRequest
    );
  }
}

export enum ExportType {
  Download,
  Distribtion,
}
