import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../shared/models/event.model';

@Component({
  selector: 'ca-company-details-header',
  templateUrl: './company-details-header.component.html',
})
export class CompanyDetailsHeaderComponent {
  @Input() saveStarted: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.saveStarted = false;
    this.onEvent = new EventEmitter<EventModel>();
  }

  addItem(actionType: string) {
    this.onEvent.emit({
      action: actionType,
      data: null,
    });
  }
}
