import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentDistributionRecipientModel } from '../../../content-distribution/models/content-distribution-recipient.model';
import * as _ from 'underscore';
import { createExportSelectRecipientModalForm } from './export-select-recipient-modal.form';
import { ContentDistributionDataService } from '../../services/content-distribution-data.service';
import { ExportOptionItemModel } from '../../models/content-distribtion-export-option.model';
import { ExportTemplateDataService } from '../../services/export-template.service';
import {
  ExportTemplateModel,
  ExportTemplatePreviewStates,
} from '../../models/export-template.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MultiSelectOption } from '@seahorse/forms';
import { CustomDataSearchFieldModel, FileService } from '@seahorse/domain';
import { map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'ca-export-select-recipient-modal',
  templateUrl: 'export-select-recipient-modal.component.html',
})
export class ExportSelectRecipientModalComponent implements OnInit {
  private _exportOption!: ExportOptionItemModel;
  private _recipients: ContentDistributionRecipientModel[] = [];
  private _messageBody: string;
  private _messageSubject: string;

  @Input() get exportOption(): ExportOptionItemModel {
    return this._exportOption;
  }
  set exportOption(input: ExportOptionItemModel) {
    this._exportOption = input;
    if (this._exportOption && this._exportOption.previewState) {
      (this.allowPreview =
        this._exportOption.previewState ===
        ExportTemplatePreviewStates.AllowPreview),
        (this.showPreview =
          this._exportOption.previewState ===
          ExportTemplatePreviewStates.AlwaysShow);
    }
  }
  @Input() objectIds?: any[];
  @Input() data?: any;
  @Input() objectType!: string;
  @Input() showHeader = true;
  @Input() get recipients(): ContentDistributionRecipientModel[] {
    return this._recipients;
  }
  set recipients(input: ContentDistributionRecipientModel[]) {
    if (input) {
      this._recipients = input;
    } else {
      this._recipients = [];
    }
    this.recipientOptions = this._recipients.map((recipient) => {
      return {
        label: recipient.address,
        value: recipient.address,
      };
    });
  }
  @Input() searchFields: CustomDataSearchFieldModel[] = null;
  get messageBody(): string {
    return this._messageBody;
  }
  @Input() set messageBody(body: string) {
    this._messageBody = body;
    this.form.controls.notificationBody.patchValue(body);
    this.notificationBodyLoaded = true;
  }
  get messageSubject(): string {
    return this._messageSubject;
  }
  @Input() set messageSubject(subject: string) {
    this._messageSubject = subject;
    this.form.controls.subject.patchValue(subject);
  }

  additionalRecipient: string = null;
  allowPreview = false;
  form = createExportSelectRecipientModalForm();
  addressError?: HttpErrorResponse;
  loadingPreview = false;
  notificationBodyLoaded = false;
  previewError?: HttpErrorResponse;
  recipientOptions: MultiSelectOption[] = [];
  recipientSelectFields = { text: 'address', value: 'address' };
  showPreview = false;
  includedFiles = [];
  includedTemplates = [];
  exportTemplate: ExportTemplateModel;

  constructor(
    public contentDistributionData: ContentDistributionDataService,
    public modal: NgbActiveModal,
    public templateData: ExportTemplateDataService,
    public fileData: FileService
  ) {}

  ngOnInit(): void {
    this.loadAddresses();
    if (this.showPreview) {
      this.getExportTemplate();
    }
    this.setRecipients();
  }

  getExportTemplate() {
    if (!this._exportOption.templateId) {
      return;
    }
    this.templateData
      .getById(this._exportOption.templateId)
      .subscribe((temp) => {
        this.exportTemplate = temp.result;
        this.preview();
        this.previewAttachments();
      });
  }

  dismiss() {
    this.modal.dismiss();
  }

  loadAddresses() {
    if (!this.exportOption.templateId) {
      return;
    }
    this.contentDistributionData
      .getDistributionAddresses(
        this.exportOption.templateId,
        this.objectIds,
        this.objectType
      )
      .subscribe({
        next: (addressResult) => {
          if (
            addressResult &&
            addressResult.hasResult &&
            addressResult.result &&
            addressResult.result.length > 0
          ) {
            this.setRecipients(addressResult.result);
          }
        },
        error: (errorEvt) => {
          this.addressError = errorEvt;
        },
      });
  }

  ok() {
    this.form.submit();
    if (!this.form.valid) {
      return;
    }

    const formValue = this.form.getRawValue();
    const recipients = formValue.selectedRecipients.map((recipientAdress) => {
      return (
        this.recipients.find((find) => find.address === recipientAdress) ?? {
          address: recipientAdress,
        }
      );
    });

    this.modal.close({
      body: this.notificationBodyLoaded
        ? formValue.notificationBody
        : undefined,
      recipients: recipients,
      subject: formValue.subject,
    });
  }

  preview() {
    this.loadingPreview = true;

    this.contentDistributionData
      .applyTemplateContent(
        this.exportTemplate.templateConfiguration.defaultExportFilename,
        this.exportTemplate.id,
        this.exportTemplate.mappingKey,
        this.objectIds,
        JSON.stringify(this.data)
      )
      .subscribe((res) => {
        this.form.controls.subject.patchValue(res.result);
      });

    this.contentDistributionData
      .generateExportBody(
        this._exportOption,
        this.objectIds,
        this.objectType,
        null,
        JSON.stringify(this.data)
      )
      .subscribe({
        next: (exportResult) => {
          if (exportResult && exportResult.length > 0) {
            this.form.controls.notificationBody.patchValue(exportResult);
            this.notificationBodyLoaded = true;
          }
          this.loadingPreview = false;
        },
        error: (errorEvt) => {
          this.previewError = errorEvt;
          this.loadingPreview = false;
        },
      });

    this.showPreview = !this.showPreview;
  }

  previewAttachments() {
    const includedTemplates$ =
      this.exportTemplate.templateConfiguration.includedExportTemplates &&
      this.exportTemplate.templateConfiguration.includedExportTemplates.length >
        0
        ? forkJoin(
            this.exportTemplate.templateConfiguration.includedExportTemplates.map(
              (inclTemp) => this.templateData.getById(inclTemp)
            )
          )
        : of([]);

    const includedFiles$ =
      this.exportTemplate.templateConfiguration.includeFileIds &&
      this.exportTemplate.templateConfiguration.includeFileIds.length > 0
        ? forkJoin(
            this.exportTemplate.templateConfiguration.includeFileIds.map(
              (inclFile) => this.fileData.getFileById(inclFile)
            )
          )
        : of([]);

    forkJoin([includedTemplates$, includedFiles$])
      .pipe(
        map(([includedTemplates, includedFiles]) => ({
          includedTemplates,
          includedFiles,
        }))
      )
      .subscribe(({ includedTemplates, includedFiles }) => {
        if (includedTemplates && includedFiles) {
          this.objectIds.forEach((id) => {
            this.includedTemplates.push(
              ...includedTemplates.map((inclTemp) => ({
                ...inclTemp.result,
                fileType: '.' + inclTemp.result.documentType.defaultExtension,
                objectId: id,
              }))
            );
          });

          this.includedFiles.push(
            ...includedFiles.map((inclFile) => inclFile.result)
          );
        }
      });
  }

  setRecipients(recipients?: ContentDistributionRecipientModel[]) {
    if (recipients && recipients.length > 0) {
      for (let iRecipient = 0; iRecipient < recipients.length; iRecipient++) {
        const recipient = recipients[iRecipient];
        if (
          !this.recipients.find(
            (findRecipient) => findRecipient.address === recipient.address
          )
        ) {
          this.recipients.push(recipient);
        }
      }
    }
    this.form.patchValue({
      selectedRecipients: this.recipients.map((recipient) => recipient.address),
    });
  }

  fileUploaded(file: any) {
    file.allowDelete = true;
    this.includedFiles.push(file);

    this.exportTemplate.templateConfiguration.includeFileIds =
      this.includedFiles.map((file) => file.id);

    this.templateData.update(this.exportTemplate).subscribe();
  }

  fileDelete(id: any) {
    this.includedFiles = this.includedFiles.filter((file) => file.id !== id);

    this.exportTemplate.templateConfiguration.includeFileIds =
      this.includedFiles.map((file) => file.id);

    this.templateData.update(this.exportTemplate).subscribe();
  }
}
