import { Injectable } from '@angular/core';
import { EnvService, ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
import { map } from 'rxjs/operators';

import {
  CustomDataBaseModel,
  DataObjectKeyModel,
} from '../models/custom-data-base.model';
import { DeleteRequestModel } from '../models/delete-request.model';
import { ObjectState } from '../models/object-state.model';
import { CloneObjectRequest } from '../../core/models/clone-object-request.model';
import { CustomDataSearchFieldModel } from '../models/custom-data-search-field.model';
import { CustomDataAppendDataModel } from '../models/custom-data-append-data.model';

@Injectable({
  providedIn: 'root',
})
export class CustomDataContentService {
  private prefix = 'customdata/customcontent/';

  constructor(
    private dataContext: DataContextService,
    private _env: EnvService
  ) {}

  addCustomerData(
    objectType: string,
    objectContent: any,
    defaultDataGroups?: number[],
    defaultDataObjects?: string[]
  ): Observable<ResultWrapper<any>> {
    let uri = this.prefix + 'custom/' + objectType + '/data';

    const uriArgs: string[] = [];
    if (
      defaultDataGroups !== null &&
      defaultDataGroups !== undefined &&
      defaultDataGroups.length > 0
    ) {
      uriArgs.push('defaultDataGroups=' + defaultDataGroups.join(','));
    }
    if (
      defaultDataObjects !== null &&
      defaultDataObjects !== undefined &&
      defaultDataObjects.length > 0
    ) {
      uriArgs.push('defaultDataObjects=' + defaultDataObjects.join(','));
    }
    if (uriArgs.length > 0) {
      uri += '?' + uriArgs.join('&');
    }

    return this.dataContext.post<any>(uri, objectContent);
  }

  addCustomerArrayOfData(
    objectType: string,
    objectContent: any,
    defaultDataGroups?: number[],
    defaultDataObjects?: string[]
  ): Observable<ResultWrapper<any>> {
    let uri = this.prefix + 'custom/' + objectType + '/arrayofdata';

    const uriArgs: string[] = [];
    if (
      defaultDataGroups !== null &&
      defaultDataGroups !== undefined &&
      defaultDataGroups.length > 0
    ) {
      uriArgs.push('defaultDataGroups=' + defaultDataGroups.join(','));
    }
    if (
      defaultDataObjects !== null &&
      defaultDataObjects !== undefined &&
      defaultDataObjects.length > 0
    ) {
      uriArgs.push('defaultDataObjects=' + defaultDataObjects.join(','));
    }
    if (uriArgs.length > 0) {
      uri += '?' + uriArgs.join('&');
    }

    return this.dataContext.post<any>(uri, objectContent);
  }

  deleteCustomerData(objectType: string, __Id: string) {
    return this.dataContext.delete<any>(
      this.prefix + 'custom/' + objectType + '/data/' + __Id
    );
  }

  deleteRequest(
    objectType: string,
    __Id: string,
    deleteRequest: DeleteRequestModel
  ) {
    return this.dataContext.put<any>(
      this.prefix +
        'custom/' +
        objectType +
        '/data/' +
        __Id +
        '/delete-request',
      deleteRequest
    );
  }

  getAllCustomerData(
    objectType: string,
    orderby?: string,
    sortorder?: string,
    appendData?: CustomDataAppendDataModel,
    includeDeleted: boolean = false
  ): Observable<ResultWrapper<any[]>> {
    const states =
      includeDeleted === true
        ? null
        : _.filter(
            _.keys(ObjectState),
            (k) => isNaN(Number(k)) && k !== 'Deleted'
          );
    return this._searchData(
      'custom',
      objectType,
      null,
      null,
      states,
      orderby,
      sortorder,
      0,
      500,
      appendData,
      null,
      null,
      null,
      null
    );
  }

  getCustomerDataById(
    objectType: string,
    id: string,
    appendData?: CustomDataAppendDataModel,
    includeStatus?: boolean
  ) {
    return this.getCustomerDataByIds(
      objectType,
      [id],
      appendData,
      null,
      includeStatus
    ).pipe(
      map((response) => {
        if (response.hasResult) {
          response.result = response.result[0];
        }

        return response as unknown as ResultWrapper<CustomDataBaseModel>;
      })
    );
  }

  getCustomerDataByIds(
    objectType: string,
    ids: string[],
    appendData?: CustomDataAppendDataModel,
    forceUseV2?: boolean,
    includeStatus?: boolean
  ): Observable<ResultWrapper<any[]>> {
    let uri = this.prefix + 'custom/' + objectType + '/data/ids';

    const queryParams = [];

    if (this._env.useContentV2 || forceUseV2 === true) {
      uri += '/v2';
    }

    if (appendData) {
      uri = appendData.setUrlArgs(uri);
    }

    if (includeStatus === true) {
      queryParams.push(`includeStatus=1`);
    }

    if (queryParams.length > 0) {
      uri += '&' + queryParams.join('&');
    }

    return this.dataContext.post<any[]>(uri, ids);
  }

  getCustomDataChangesHistory(
    objectType: string,
    objectId: string,
    pageIndex: number = 0,
    pageSize: number = 50
  ): Observable<ResultWrapper<any[]>> {
    return this.dataContext.get<any[]>(
      `${this.prefix}custom/${objectType}/${objectId}/changes-history?pindex=${pageIndex}&psize=${pageSize}`
    );
  }

  getDataByIds(
    keyModel: DataObjectKeyModel,
    ids: string[],
    appendData?: CustomDataAppendDataModel,
    forceUseV2?: boolean
  ): Observable<ResultWrapper<any[]>> {
    let uri = `${this.prefix}${keyModel.Scope}/${keyModel.SystemCode}/data/ids`;

    if (this._env.useContentV2 || forceUseV2 === true) {
      uri += '/v2';
    }

    if (appendData) {
      uri = appendData.setUrlArgs(uri);
    }

    return this.dataContext.post<any[]>(uri, ids);
  }

  searchActiveCustomerDataByFieldsByScope(
    scope: string,
    objectType: string,
    fields: CustomDataSearchFieldModel[],
    orderby?: string,
    sortorder?: string,
    pageIndex: number = 0,
    pageSize: number = 50,
    appendData?: CustomDataAppendDataModel,
    dateFrom: any = null,
    dateUntil: any = null,
    includeTime: boolean = false,
    dateRangeField: string = null,
    skipJoinTables: boolean = null
  ): Observable<ResultWrapper<any[]>> {
    const states = _.filter(
      _.keys(ObjectState),
      (k) => isNaN(Number(k)) && k !== 'Deleted'
    );
    return this._searchDataWithFields(
      scope,
      objectType,
      fields,
      states,
      orderby,
      sortorder,
      pageIndex,
      pageSize,
      appendData,
      dateFrom,
      dateUntil,
      includeTime,
      dateRangeField,
      skipJoinTables
    );
  }

  searchActiveCustomerDataByFields(
    objectType: string,
    fields: CustomDataSearchFieldModel[],
    orderby?: string,
    sortorder?: string,
    pageIndex: number = 0,
    pageSize: number = 50,
    appendData?: CustomDataAppendDataModel,
    dateFrom: any = null,
    dateUntil: any = null,
    includeTime: boolean = false,
    dateRangeField: string = null,
    skipJoinTables: boolean = null
  ): Observable<ResultWrapper<any[]>> {
    const states = _.filter(
      _.keys(ObjectState),
      (k) => isNaN(Number(k)) && k !== 'Deleted'
    );
    return this._searchDataWithFields(
      'custom',
      objectType,
      fields,
      states,
      orderby,
      sortorder,
      pageIndex,
      pageSize,
      appendData,
      dateFrom,
      dateUntil,
      includeTime,
      dateRangeField,
      skipJoinTables
    );
  }

  searchCustomerDataByFields(
    objectType: string,
    fields: CustomDataSearchFieldModel[],
    states?: any[],
    orderby?: string,
    sortorder?: string,
    pageIndex: number = 0,
    pageSize: number = 50,
    appendData?: CustomDataAppendDataModel,
    dateFrom: any = null,
    dateUntil: any = null,
    includeTime: boolean = false,
    dateRangeField: string = null,
    skipJoinTables: boolean = null,
    includeStatus: boolean = null
  ): Observable<ResultWrapper<any[]>> {
    return this._searchDataWithFields(
      'custom',
      objectType,
      fields,
      states,
      orderby,
      sortorder,
      pageIndex,
      pageSize,
      appendData,
      dateFrom,
      dateUntil,
      includeTime,
      dateRangeField,
      skipJoinTables,
      includeStatus
    );
  }

  searchActiveCustomerData(
    objectType: string,
    search?: string,
    fieldName?: string,
    orderby?: string,
    sortorder?: string,
    pageIndex: number = 0,
    pageSize: number = 50,
    appendData?: CustomDataAppendDataModel,
    dateFrom: any = null,
    dateUntil: any = null,
    includeTime: boolean = false,
    dateRangeField: string = null,
    skipJoinTables: boolean = null
  ): Observable<ResultWrapper<any[]>> {
    const states = _.filter(
      _.keys(ObjectState),
      (k) => isNaN(Number(k)) && k !== 'Deleted'
    );
    return this._searchData(
      'custom',
      objectType,
      search,
      fieldName,
      states,
      orderby,
      sortorder,
      pageIndex,
      pageSize,
      appendData,
      dateFrom,
      dateUntil,
      includeTime,
      dateRangeField,
      skipJoinTables
    );
  }

  searchCustomerData(
    objectType: string,
    search?: string,
    fieldName?: string,
    states?: any[],
    orderby?: string,
    sortorder?: string,
    pageIndex: number = 0,
    pageSize: number = 50,
    appendData?: CustomDataAppendDataModel,
    dateFrom: any = null,
    dateUntil: any = null,
    includeTime: boolean = false,
    dateRangeField: string = null,
    skipJoinTables: boolean = null,
    includeStatus: boolean = null
  ): Observable<ResultWrapper<any[]>> {
    return this._searchData(
      'custom',
      objectType,
      search,
      fieldName,
      states,
      orderby,
      sortorder,
      pageIndex,
      pageSize,
      appendData,
      dateFrom,
      dateUntil,
      includeTime,
      dateRangeField,
      skipJoinTables,
      includeStatus
    );
  }

  searchDataByObjectKey(
    keyModel: DataObjectKeyModel,
    search?: string,
    fieldName?: string,
    states?: any[],
    orderby?: string,
    sortorder?: string,
    pageIndex: number = 0,
    pageSize: number = 50,
    appendData?: CustomDataAppendDataModel,
    dateFrom: any = null,
    dateUntil: any = null,
    includeTime: boolean = false,
    dateRangeField: string = null,
    skipJoinTables: boolean = null
  ): Observable<ResultWrapper<any[]>> {
    return this._searchData(
      keyModel.Scope,
      keyModel.SystemCode,
      search,
      fieldName,
      states,
      orderby,
      sortorder,
      pageIndex,
      pageSize,
      appendData,
      dateFrom,
      dateUntil,
      includeTime,
      dateRangeField,
      skipJoinTables
    );
  }

  searchDataByObjectKeyAndFields(
    keyModel: DataObjectKeyModel,
    fields: CustomDataSearchFieldModel[],
    states?: any[],
    orderby?: string,
    sortorder?: string,
    pageIndex: number = 0,
    pageSize: number = 50,
    appendData?: CustomDataAppendDataModel,
    dateFrom: any = null,
    dateUntil: any = null,
    includeTime: boolean = false,
    dateRangeField: string = null,
    skipJoinTables: boolean = null,
    includeStatus: boolean = null
  ): Observable<ResultWrapper<any[]>> {
    return this._searchDataWithFields(
      keyModel.Scope,
      keyModel.SystemCode,
      fields,
      states,
      orderby,
      sortorder,
      pageIndex,
      pageSize,
      appendData,
      dateFrom,
      dateUntil,
      includeTime,
      dateRangeField,
      skipJoinTables,
      includeStatus
    );
  }

  updateCustomerData(
    objectType: string,
    objectId: any,
    data: any,
    includeStatus = false
  ): Observable<ResultWrapper<any>> {
    let uri = this.prefix + 'custom/' + objectType + '/data/' + objectId;

    if (includeStatus) {
      uri += `?includeStatus=${includeStatus}`;
    }

    return this.dataContext.put<any>(uri, data);
  }

  updateCustomerArrayOfData(
    objectType: string,
    data: any,
    includeStatus
  ): Observable<ResultWrapper<any>> {
    let uri = this.prefix + 'custom/' + objectType + '/arrayofdata';

    if (includeStatus) {
      uri += `?includeStatus=${includeStatus}`;
    }

    return this.dataContext.put<any>(uri, data);
  }

  markAsDelete(objectType: string, __Id: string, remarks: string) {
    return this.dataContext.put<any>(
      this.prefix + 'custom/' + objectType + '/data/' + __Id + '/delete',
      { remarks }
    );
  }

  private _searchData(
    scope: string,
    objectType: string,
    search?: string,
    fieldName?: string,
    states?: any[],
    orderby?: string,
    sortorder?: string,
    pageIndex: number = 0,
    pageSize: number = 50,
    appendData?: CustomDataAppendDataModel,
    dateFrom: any = null,
    dateUntil: any = null,
    includeTime: boolean = false,
    dateRangeField: string = null,
    skipJoinTables: boolean = null,
    includeStatus: boolean = null
  ): Observable<ResultWrapper<any[]>> {
    let uri = `${this.prefix}${scope}/${objectType}/search`;

    const args = [];
    if (search !== undefined && search !== null) {
      args.push(`searchQuery=${search}`);
    }

    if (fieldName) {
      args.push(`searchField=${fieldName}`);
    }

    if (states) {
      args.push(`states=${states.join(',')}`);
    }

    if (orderby) {
      args.push(`orderby=${orderby}`);
    }

    if (sortorder) {
      args.push(`sortorder=${sortorder}`);
    }

    if (pageIndex) {
      args.push(`pindex=${pageIndex}`);
    }

    if (pageSize) {
      args.push(`psize=${pageSize}`);
    }

    if (dateFrom) {
      if (includeTime === false) {
        args.push(`dateFrom=${dateFrom.format('YYYY-MM-DD')}`);
      } else {
        args.push(`dateFrom=${dateFrom.format('YYYY-MM-DDTHH:mm:ss')}`);
      }
    }

    if (dateUntil) {
      if (includeTime === false) {
        args.push(`dateUntil=${dateUntil.format('YYYY-MM-DD')}`);
      } else {
        args.push(`dateUntil=${dateUntil.format('YYYY-MM-DDTHH:mm:ss')}`);
      }
    }

    if (dateRangeField) {
      args.push(`dateRangeField=${dateRangeField}`);
    }

    if (skipJoinTables === true) {
      args.push(`skipJoinTables=${skipJoinTables}`);
    }

    if (includeStatus === true) {
      args.push(`includeStatus=${includeStatus}`);
    }

    if (args.length > 0) {
      uri = `${uri}?${args.join('&')}`;
    }

    if (appendData) {
      uri = appendData.setUrlArgs(uri);
    }

    return this.dataContext.get<any[]>(uri);
  }

  private _searchDataWithFields(
    scope: string,
    objectType: string,
    fields: CustomDataSearchFieldModel[],
    states?: any[],
    orderby?: string,
    sortorder?: string,
    pageIndex: number = 0,
    pageSize: number = 50,
    appendData?: CustomDataAppendDataModel,
    dateFrom: any = null,
    dateUntil: any = null,
    includeTime: boolean = false,
    dateRangeField: string = null,
    skipJoinTables: boolean = null,
    includeStatus: boolean = null
  ): Observable<ResultWrapper<any[]>> {
    let uri = `${this.prefix}${scope}/${objectType}/search`;

    const args = [];

    if (states) {
      args.push(`states=${states.join(',')}`);
    }

    if (orderby) {
      args.push(`orderby=${orderby}`);
    }

    if (sortorder) {
      args.push(`sortorder=${sortorder}`);
    }

    if (pageIndex) {
      args.push(`pindex=${pageIndex}`);
    }

    if (pageSize) {
      args.push(`psize=${pageSize}`);
    }

    if (dateFrom) {
      if (includeTime === false) {
        args.push(`dateFrom=${dateFrom.format('YYYY-MM-DD')}`);
      } else {
        args.push(`dateFrom=${dateFrom.format('YYYY-MM-DDTHH:mm:ss')}`);
      }
    }

    if (dateUntil) {
      if (includeTime === false) {
        args.push(`dateUntil=${dateUntil.format('YYYY-MM-DD')}`);
      } else {
        args.push(`dateUntil=${dateUntil.format('YYYY-MM-DDTHH:mm:ss')}`);
      }
    }

    if (dateRangeField) {
      args.push(`dateRangeField=${dateRangeField}`);
    }

    if (skipJoinTables === true) {
      args.push(`skipJoinTables=${skipJoinTables}`);
    }

    if (includeStatus === true) {
      args.push(`includeStatus=${includeStatus}`);
    }

    if (args.length > 0) {
      uri = `${uri}?${args.join('&')}`;
    }

    if (appendData) {
      uri = appendData.setUrlArgs(uri);
    }

    return this.dataContext.post<any[]>(uri, {
      Scope: scope,
      ObjectDefinitionSystemCode: objectType,
      Fields: fields,
    });
  }

  clone(
    objectType: string,
    clonedObjectParams: CloneObjectRequest
  ): Observable<ResultWrapper<any>> {
    return this.dataContext.post<any>(
      this.prefix + 'custom/' + objectType + '/data/clone',
      clonedObjectParams
    );
  }
}
