import { MultiSelectOption } from '@seahorse/forms';
import { FieldType } from '../../custom-data/models/field-type-definition.model';

export class UiModuleDetailsConfiguration {
  static default(headerConfigNull = false): UiModuleDetailsConfiguration {
    return {
      id: null,
      showTasks: true,
      showFiles: true,
      showMessages: true,
      showNotes: true,
      showChat: true,
      headerConfig: headerConfigNull
        ? null
        : {
            showExportButton: true,
            showAddButton: true,
            showDeleteButton: true,
            showDuplicateButton: true,
            showViewChangesButton: true,
            invoiceFlow: null,
          },
      objectsConfig: [],
      tabsConfig: null,
    };
  }
}

export interface UiModuleDetailsConfiguration {
  id: number;
  showTasks: boolean;
  showFiles: boolean;
  showMessages: boolean;
  showNotes: boolean;
  showChat: boolean;
  headerConfig: UiModuleDetailsHeaderConfigModel;
  objectsConfig: UiModuleDetailsLinkedObjectsConfigModel[];
  tabsConfig: TabConfigModel[];
}

export interface UiModuleDetailsHeaderConfigModel {
  showExportButton: boolean;
  showAddButton: boolean;
  showDeleteButton: boolean;
  showDuplicateButton: boolean;
  showViewChangesButton: boolean;
  invoiceFlow: string;
}

export interface UiModuleDetailsLinkedObjectsConfigModel {
  field: string;
  order: number;
  showExportButton: boolean;
  showAddButton: boolean;
  showLinkButton?: boolean;
  showDeleteButton?: boolean;
  showDuplicateButton?: boolean;
  clickAction?: LinkedObjectClickAction;
  clickActionParameter?: string;
  objectMappingKey?: string;
  sortField?: string;
  sortFieldOrder?: boolean;
  displayFields?: string[];
  // UI only
  name?: string;
  fieldType?: FieldType;
  options?: MultiSelectOption[];
}

export interface TabConfigModel {
  order: number;
  displayName: string;
  systemCode: string;
  detailsConfigurationId: number;
  detailsConfiguration?: UiModuleDetailsConfiguration;
}

export interface TabItem {
  name: string;
  originalName?: string;
  systemCode: string;
}

export enum LinkedObjectClickAction {
  None,
  Open,
  Edit,
}
