import { Injectable } from '@angular/core';
import { DataContextService, ResultWrapper } from '@seahorse/common';
import { Observable } from 'rxjs';

import {
  CompanyAddressModel,
  CompanyContactModel,
  CompanyDepartmentModel,
  CompanyModel,
  CompanyTypeModel,
} from '../models/company.model';
import { CompanySearchModel } from '../models/company-search.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyDataService {
  constructor(private dataContext: DataContextService) {}

  getAll(
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<CompanyModel[]>> {
    return this.dataContext.get<CompanyModel[]>(
      `companies?pindex=${pageIndex}&psize=${pageSize}`
    );
  }

  search(
    search: string,
    pageIndex: number,
    pageSize: number,
    annotate: boolean = false,
    tag?: string
  ): Observable<ResultWrapper<CompanyModel[]>> {
    let queryParams = `companies?search=${encodeURIComponent(
      search
    )}&pindex=${pageIndex}&psize=${pageSize}&annotate=${annotate}`;

    if (tag !== undefined && tag !== null) {
      queryParams += `&tag=${encodeURIComponent(tag)}`;
    }

    return this.dataContext.get<CompanyModel[]>(queryParams);
  }

  searchByModel(
    searchModel: CompanySearchModel,
    pageIndex: number,
    pageSize: number,
    annotate: boolean = false
  ): Observable<ResultWrapper<Array<CompanyModel>>> {
    return this.dataContext.post<Array<CompanyModel>>(
      `companies/advanced-search?annotate=${annotate}&pindex=${pageIndex}&psize=${pageSize}`,
      searchModel
    );
  }

  getPossibleParentCompanies(
    companyId: number
  ): Observable<ResultWrapper<CompanyModel[]>> {
    return this.dataContext.get<CompanyModel[]>(
      `companies/${companyId}/possible-parent-companies`
    );
  }

  getChildCompanies(
    companyId: number
  ): Observable<ResultWrapper<CompanyModel[]>> {
    return this.dataContext.get<CompanyModel[]>(
      `companies/${companyId}/child-companies`
    );
  }

  getById(
    companyId: number,
    annotate: boolean = false
  ): Observable<ResultWrapper<CompanyModel>> {
    return this.dataContext.get<CompanyModel>(
      `companies/${companyId}?annotate=${annotate}`
    );
  }

  getAddresses(
    companyId: number,
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<CompanyAddressModel[]>> {
    return this.dataContext.get<CompanyAddressModel[]>(
      `companies/${companyId}/addresses?&pindex=${pageIndex}&psize=${pageSize}`
    );
  }

  getContacts(
    companyId: number,
    search: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<CompanyContactModel[]>> {
    let queryParams = `search=${encodeURIComponent(search)}`;

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams += `&pindex=${pageIndex}`;
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams += `&psize=${pageSize}`;
    }

    return this.dataContext.get<CompanyContactModel[]>(
      `companies/${companyId}/contacts?${queryParams}`
    );
  }

  getDepartments(
    companyId: number,
    search: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<CompanyDepartmentModel[]>> {
    let queryParams = `search=${encodeURIComponent(search)}`;

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams += `&pindex=${pageIndex}`;
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams += `&psize=${pageSize}`;
    }

    return this.dataContext.get<CompanyDepartmentModel[]>(
      `companies/${companyId}/departments?${queryParams}`
    );
  }

  add(company: CompanyModel): Observable<ResultWrapper<CompanyModel>> {
    return this.dataContext.post<CompanyModel>(`companies`, company);
  }

  update(company: CompanyModel): Observable<ResultWrapper<CompanyModel>> {
    return this.dataContext.put<CompanyModel>(
      `companies/${company.id}`,
      company
    );
  }

  deactivate(
    companyId: CompanyModel['id']
  ): Observable<ResultWrapper<CompanyModel>> {
    return this.dataContext.delete(`companies/${companyId}`);
  }

  addCompanyType(
    type: CompanyTypeModel
  ): Observable<ResultWrapper<CompanyTypeModel>> {
    return this.dataContext.post<CompanyTypeModel>(`types`, type);
  }

  getTypes(): Observable<ResultWrapper<CompanyTypeModel[]>> {
    return this.dataContext.get<CompanyTypeModel[]>(`types`);
  }

  getTypesAssigned(): Observable<ResultWrapper<Array<CompanyTypeModel>>> {
    return this.dataContext.get<Array<CompanyTypeModel>>(
      'types/assigned-to-companies'
    );
  }

  nameExists(name: string): Observable<ResultWrapper<boolean>> {
    return this.dataContext.get<boolean>(`companies/${name}/exists`);
  }

  getWithReferences(
    organisationId: string
  ): Observable<ResultWrapper<CompanyModel[]>> {
    return this.dataContext.get<CompanyModel[]>(
      `companies/${organisationId}/references`
    );
  }
}
