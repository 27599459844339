import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationService } from '@seahorse/common';

@Injectable({
  providedIn: 'root',
})
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            //
          }
        },
        (err: any) => {
          this.handleError(err);
        }
      )
    );
  }

  private handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 100:
        this.notificationService.showError('Unknown error!', 'Failed');
        break;
      case 401:
        //                window.location.href = '/account/login'
        break;
      case 403:
        break;
      case 404:
        // this.router.navigate(['not-found']);
        break;
      case 490:
        // this.notificationService.showError('Not enough permissions!', 'Denied');
        break;
      case 500:
        this.notificationService.showError('Something went wrong!', 'Failed');
        break;
      default:
        break;
    }
  }
}
