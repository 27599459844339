import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import {
  CustomDataAdditionalDataModel,
  FieldDefinitionModel,
  FieldType,
  ObjectDefinitionModel,
  CustomDataBaseModel
} from '@seahorse/domain';
import { ProxyServices } from '../../../core/services/proxy.service';
import { TableColumnModel } from '../../../layout/models/table.model';
import { PipeModel, KeyValuePair } from '@seahorse/common';

@Component({
  selector: 'ca-custom-content-list',
  templateUrl: './custom-content-list.component.html',
})
export class CustomContentListComponent implements OnInit {
  selectedEntry: CustomDataBaseModel = null;
  fieldType = null;
  hasFixWidth = false;

  @Input() allowSelection = true;
  @Input() columns: TableColumnModel[] = [];
  @Input() entryId: string = null;
  @Input() entries: CustomDataBaseModel[] = [];
  @Input() definition: ObjectDefinitionModel = null;
  @Input() footers: KeyValuePair<any, string>[];
  @Input() approvedField: string;

  @Output() onEntrySelected = new EventEmitter<CustomDataBaseModel>();

  fields = {}; // {FieldSystemcode, FieldDefinition}

  constructor(
    private proxyServices: ProxyServices,
    private translate: TranslateService
  ) {
    this.fieldType = FieldType;
    this.footers = [];
    this.approvedField = null;
  }

  ngOnInit() {
    if (this.definition) {
      let systemFields = [];

      let json = null;
      if (this.definition.templateCode) {
        try {
          json = JSON.parse(this.definition.templateCode);
        } catch {}
      }

      if (json && json['sort'] && json['sort']['fields']) {
        systemFields = this.convertToFieldDefinition(json['sort']['fields']);
      } else {
        systemFields = this.convertToFieldDefinition([
          { code: '__CreatedOn', order: -4 },
          { code: '__CreatedBy', order: -3 },
          { code: '__LastEditedOn', order: -2 },
          { code: '__LastEditedBy', order: -1 },
        ]);
      }

      // sort by sortOrder then by name
      const allFields: FieldDefinitionModel[] = _.sortBy(
        _.union(
          systemFields,
          ObjectDefinitionModel.getAllFieldDefinitions(this.definition)
        ),
        (field: FieldDefinitionModel) => {
          return parseInt(field.sortOrder);
        }
      );

      if (!this.columns || this.columns.length === 0) {
        this.columns = _.map(allFields, (field) => {
          this.fields[field.systemCode] = field;

          // dirty fix for old data. e.g. CVV/KP data
          if (this.proxyServices.objectKeyMapping[field.systemCode]) {
            this.fields[field.systemCode].fieldType = FieldType.LinkedObject;
          }

          let pipes = null;
          if (
            this.fields[field.systemCode].fieldType ===
              FieldType.LinkedObject ||
            this.fields[field.systemCode].fieldType === FieldType.LinkedObjects
          ) {
            let displayFields = null; // default show all properties
            if (this.fields[field.systemCode].additionalData) {
              try {
                const additionalData: CustomDataAdditionalDataModel =
                  JSON.parse(this.fields[field.systemCode].additionalData);
                if (additionalData && additionalData.displayFields) {
                  displayFields = additionalData.displayFields;
                }
              } catch {}
            }
            pipes = [
              new PipeModel('keyValue', { displayFields: displayFields }),
            ]; // show all properties
          }

          return new TableColumnModel(
            field.systemCode,
            field.fieldType,
            field.name,
            pipes,
            false,
            false,
            field.sortOrder
          );
        });
      } else {
        this.fields = allFields;
        _.each(this.columns, (col: TableColumnModel) => {
          const def: any = _.find(
            this.fields,
            (field: any) => field.systemCode === col.fieldName
          );
          if (def) {
            if (
              def.fieldType === FieldType.LinkedObject ||
              def.fieldType === FieldType.LinkedObjects
            ) {
              let displayFields = null; // default show all properties
              if (def.additionalData) {
                try {
                  const additionalData: CustomDataAdditionalDataModel =
                    JSON.parse(def.additionalData);
                  if (additionalData && additionalData.displayFields) {
                    displayFields = additionalData.displayFields;
                  }
                } catch {}
              }
              col.pipes = [
                new PipeModel('keyValue', { displayFields: displayFields }),
              ];
            }
          }
        });
      }

      this.hasFixWidth = _.any(
        this.columns,
        (col: TableColumnModel) => col.width !== undefined && col.width !== null
      );
    }

    if (this.entryId) {
      this.selectedEntry = _.find(
        this.entries,
        (entry) => entry.__Id === this.entryId
      );
    }
  }

  selectEntry(entry: CustomDataBaseModel) {
    if (!this.allowSelection) {
      return;
    }
    this.selectedEntry = entry;
    this.onEntrySelected.emit(entry);
  }

  private convertToFieldDefinition(fields) {
    const definitions = [];
    _.each(fields, (f) => {
      const definition = new FieldDefinitionModel();
      definition.systemCode = f['code'];
      definition.name = this.translate.instant(`customData.model.${f['code']}`);
      definition.sortOrder = !f['order'] ? 999 : f['order'];

      switch (f['code']) {
        case '__CreatedOn':
        case '__LastEditedOn':
          definition.fieldTypeDefinitionId = 3; // datetime field
          break;

        case '__CreatedBy':
        case '__LastEditedBy':
          definition.fieldTypeDefinitionId = 12; // user field
          break;

        case '__AdditionalData':
          break;

        default:
          definition.fieldTypeDefinitionId = 1;
      }
      definitions.push(definition);
    });
    return definitions;
  }
}
