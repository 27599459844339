import { ObjectDefinitionModel } from './object-definition.model';
import { ObjectState } from './object-state.model';

export class CustomDataBaseModel {
  __Id: string;
  __CreatedOn: Date;
  __CreatedBy: string;
  __DataObjectKey: string;
  __LastEditedOn: Date;
  __LastEditedBy: string;
  __OrganisationId: string;
  __ObjectState: ObjectState;
  __AdditionalData: any;
  __DataFieldFormatting: object;
  __DisplayName: string;
  __Status: any;

  constructor() {
    this.__Id = null;
    this.__CreatedOn = null;
    this.__CreatedBy = null;
    this.__DataObjectKey = null;
    this.__LastEditedOn = null;
    this.__LastEditedBy = null;
    this.__OrganisationId = null;
    this.__ObjectState = null;
    this.__AdditionalData = {};
    this.__DataFieldFormatting = {};
    this.__Status = {};
  }
}

export class CustomDataWithDefinitionModel extends CustomDataBaseModel {
  __Definition: ObjectDefinitionModel;

  constructor() {
    super();
    this.__Definition = null;
  }
}

export class DataObjectKeyModel {
  Scope: string;
  SystemCode: string;

  constructor(scope?: string, systemCode?: string) {
    this.Scope = scope === undefined || scope === null ? null : scope;
    this.SystemCode =
      systemCode === undefined || systemCode === null ? null : systemCode;
  }

  static fromKey(key: string) {
    const model = new DataObjectKeyModel();
    model.parseKey(key);
    return model;
  }

  parseKey?(key: string) {
    const index = key.indexOf('-');
    if (index === 2) {
      const prefix = key.substring(0, 2).toLowerCase();
      this.SystemCode = key.substring(3);
      switch (prefix) {
        case 'sh':
          this.Scope = 'seahorse';
          break;

        case 'st':
          this.Scope = 'system';
          break;
        default:
          this.Scope = 'custom';
          break;
      }
    } else {
      this.SystemCode = key;
      this.Scope = 'custom';
    }
  }
}
