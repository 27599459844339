import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomCVVModule } from './cvv/cvv.module';
import { CustomGeneralModule } from './general/custom-general.module';
import { CustomKPModule } from './kp/kp.module';
import { CustomPortOfAmsterdamModule } from './port-of-amsterdam/port-of-amsterdam.module';
import { ZeehavenIJmuidenModule } from './zeehaven-ijmuiden/zeehaven-ijmuiden.module';
import { PermissionsGuard } from '@seahorse/auth';

const routes: Routes = [
  {
    path: 'beverwijk',
    loadChildren: () =>
      import('./beverwijk/beverwijk.module').then(
        (m) => m.CustomUIBeverwijkModule
      ),
    canActivate: [PermissionsGuard],
    data: {
      allowedPermissions: 'custom_ui.haven_beverwijk',
      redirect: 'dashboard',
    },
  },
  {
    path: 'cvv',
    loadChildren: () =>
      import('./cvv/cvv.module').then((m) => m.CustomCVVModule),
    canActivate: [PermissionsGuard],
    data: {
      allowedPermissions: 'custom_ui.cvv',
      redirect: 'dashboard',
    },
  },
  {
    path: 'kp',
    loadChildren: () => import('./kp/kp.module').then((m) => m.CustomKPModule),
    canActivate: [PermissionsGuard],
    data: {
      allowedPermissions: 'custom_ui.koperenploeg',
      redirect: 'dashboard',
    },
  },
  {
    path: 'port-of-amsterdam',
    loadChildren: () =>
      import('./port-of-amsterdam/port-of-amsterdam.module').then(
        (m) => m.CustomPortOfAmsterdamModule
      ),
  },
  {
    path: 'zeehaven-ijmuiden',
    loadChildren: () =>
      import('./zeehaven-ijmuiden/zeehaven-ijmuiden.module').then(
        (m) => m.ZeehavenIJmuidenModule
      ),
    canActivate: [PermissionsGuard],
    data: {
      allowedPermissions: 'custom_ui.zeehaven_ijmuiden',
      redirect: 'dashboard',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    // Custom modules
    CustomCVVModule,
    CustomKPModule,
    CustomGeneralModule,
    CustomPortOfAmsterdamModule,
    ZeehavenIJmuidenModule,
  ],
  declarations: [],
})
export class CustomUIModule {}
