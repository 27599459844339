import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FieldType, ObjectDefinitionModel } from '@seahorse/domain';
import { BadgeDirective, EllipsisDirective } from '@seahorse/ui';
import { CustomDataFormService } from '../../form/custom-data-form.service';
import { FieldFormDirective } from '../../form/field-form.directive';
import { FormModel } from '../../form/form.model';
import { FieldValuePipe } from '../../pipes/field-value.pipe';
import { EditAttributeModal } from '../edit-attribute-modal/edit-attribute-modal.component';
import { FieldAttributeModel } from '../field-attribute.model';
import { FieldModeDirective } from '../field-mode.directive';
import { AppendTextPipe, TruncatePipe } from '@seahorse/common';
import { CopyContentButtonComponent } from '../../buttons/copy-content-button/copy-content-button.component';

@Component({
  selector: 'temp-attribute-list',
  templateUrl: 'attribute-list.component.html',
  styleUrls: ['./attribute-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CopyContentButtonComponent,
    TranslateModule,
    BadgeDirective,
    FieldValuePipe,
    EllipsisDirective,
    FieldFormDirective,
    FieldModeDirective,
    TruncatePipe,
    AppendTextPipe,
  ],
})
export class AttributeListComponent implements OnInit {
  @Input() allowEdit = false;

  private _attributes: FieldAttributeModel[] = [];
  @Input() set attributes(val: FieldAttributeModel[]) {
    this.attributeGroups = val.reduce((acc, cur) => {
      const groupName = cur.groupName || '-1';
      acc[groupName] = acc[groupName] || [];
      acc[groupName].push(cur);

      if (cur.fieldType === FieldType.LinkedObject) {
        cur.displayValue = cur.displayValue?.replace(/\n/g, ' | ');
      }

      return acc;
    }, {});

    this._attributes = val;
  }

  @Input() columns = 1;

  @Input() displayField?: string;
  @Input() modalHeader: string;
  @Input() objectDefinition?: ObjectDefinitionModel;
  @Input() showGroupTitles = false;

  @Output() attributeChanged = new EventEmitter<FieldAttributeModel>();

  attributeGroups?: { [group: string]: FieldAttributeModel[] };
  formModels: { [key: string]: FormModel } = {};

  FieldType = FieldType;

  get isNewSetup() {
    return !!this.objectDefinition;
  }

  constructor(
    private modal: NgbModal,
    private translateService: TranslateService,
    private _customDataFormService: CustomDataFormService
  ) {}

  ngOnInit() {
    if (this.isNewSetup) {
      this.setUpFormModels(this._attributes);
    }
  }

  private setUpFormModels(attributes: FieldAttributeModel[]) {
    this.formModels = attributes.reduce((acc, cur) => {
      if (cur.fieldFormatting) {
        Object.assign(cur.fieldValue, {
          __DataFieldFormatting: { [cur.fieldCode]: cur.fieldFormatting },
        });
      }
      const model = this.getFieldModel(cur.fieldCode, cur.fieldValue);
      acc[cur.fieldCode] = model;
      return acc;
    }, <{ [key: string]: FormModel }>{});
  }

  private getFieldModel(key: string, value: string) {
    return this._customDataFormService.getFieldModel(
      this.objectDefinition,
      key,
      value
    );
  }

  submitForm(attr: FieldAttributeModel, value: any) {
    attr.fieldValue = value;
    this.attributeChanged.emit(attr);

    const model = this.getFieldModel(attr.fieldCode, attr.fieldValue);
    this.formModels[attr.fieldCode] = model;
  }

  // old field edit
  editField(attribute: FieldAttributeModel) {
    if (!this.allowEdit) {
      return;
    }

    const attributeCopy: FieldAttributeModel = new FieldAttributeModel(
      attribute.fieldName,
      attribute.fieldType,
      attribute.fieldValue,
      attribute.fieldCode,
      attribute.isRequired,
      null,
      attribute.fieldFormatting
    );
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
    };

    const modalRef = this.modal.open(EditAttributeModal, ngbModalOptions);
    modalRef.componentInstance.attribute = attributeCopy;
    modalRef.componentInstance.modalHeader = this.translateService.instant(
      this.modalHeader
    );

    modalRef.result
      .then((modalResult) => {
        if (modalResult) {
          // DON'T return the copy object but only parse the needed field to the original attribute.
          // Bacause the originel attribute could contain more field than FieldAttributeModel. e.g. the ship attribute contains 'Category'
          attribute.fieldValue = modalResult.fieldValue;
          attribute.fieldFormatting = modalResult.fieldFormatting;
          this.attributeChanged.emit(attribute);
        }
      })
      .catch((res) => {
        //
      });
  }

  getTaskProgressFraction(value) {
    const tasks = value.split('/').map(Number);

    if (
      tasks.length === 2 &&
      !isNaN(tasks[0]) &&
      !isNaN(tasks[1]) &&
      tasks[1] !== 0
    ) {
      const taskStatus = {
        Progress: tasks[0],
        Total: tasks[1],
      };

      if (taskStatus.Progress === 0) {
        return 1;
      }

      if (
        taskStatus.Progress === taskStatus.Total &&
        taskStatus.Progress / taskStatus.Total === 1
      ) {
        return 0;
      }

      return taskStatus.Progress / taskStatus.Total;
    }

    return 1;
  }

  clickLabel(event, id: string) {
    const dd = document.getElementById(id);
    if (dd) {
      dd.click();
    }
    event.stopPropagation();
  }
}
