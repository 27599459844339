<form ngForm *ngIf="fieldGroups">
  <div
    [class.field-group]="!columns"
    [class.attribute-group]="columns"
    [class.attribute-group-border]="columns"
    [class]="'attribute-group-col-' + columns"
    *ngFor="let group of fieldGroups | keyvalue"
  >
    <div
      class="attribute-group-title mb-2"
      *ngIf="columns && group.key && group.key !== '-1'"
    >
      {{ group.key | translate }}
    </div>

    <ng-container *ngFor="let field of group.value">
      <div
        [class.form-group]="!columns"
        [class.attribute-group-item]="columns"
        *ngIf="!customFormControls?.[field.key]"
      >
        <label
          *ngIf="
            [
              FieldType.Boolean,
              FieldType.LinkedObject,
              FieldType.LinkedObjects
            ].includes(field.type) === false &&
            field.options?.fieldCode !== 'user' &&
            !columns
          "
          class="control-label"
          [class.required]="!noValidate && field.isRequired"
        >
          <b>{{ field.name }}</b>
        </label>

        <dt *ngIf="columns">{{ field.name }}</dt>

        <ng-container [ngSwitch]="true">
          <ng-container
            *ngSwitchCase="
              field.type === FieldType.Json || field.type === FieldType.Object
            "
          >
            <temp-form
              class="child-form"
              #childForm
              [model]="field.options.formConfig"
              (submit)="
                assignJsonValue(
                  $event,
                  field.key,
                  field.type === FieldType.Json
                )
              "
            ></temp-form>
          </ng-container>
          <ng-container *ngSwitchCase="field.type === FieldType.Boolean">
            <div
              *ngIf="field.options.mode !== 'list'"
              class="custom-control"
              [class]="'custom-' + (field.options.mode || 'checkbox')"
            >
              <input
                [id]="field.key + '_' + nameSuffix"
                class="custom-control-input"
                type="checkbox"
                [(ngModel)]="value[field.key]"
                [name]="field.key + '_' + nameSuffix"
                [disabled]="field.isDisabled"
                #controlRef="ngModel"
              /><label
                class="custom-control-label cursor-pointer"
                [attr.for]="field.key + '_' + nameSuffix"
              >
                {{ field.name }}
              </label>
            </div>
            <div *ngIf="field.options.mode === 'list'" class="form-group">
              <label for="dispalyMode">
                <b>{{ field.name }}</b>
              </label>
              <ng-select
                [name]="field.key"
                [(ngModel)]="value[field.key]"
                [items]="yesNoOptions"
                [disabled]="field.isDisabled"
                bindValue="id"
                bindLabel="name"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </div>
          </ng-container>

          <ng-container
            *ngSwitchCase="
              [FieldType.Integer, FieldType.Decimal].includes(field.type)
            "
          >
            <temp-numeric-input
              [(number)]="value[field.key]"
              [placeholder]="field.name | translate"
              [numDecimals]="
                field.type === FieldType.Integer
                  ? 0
                  : getValidation(field, 'numDecimals') || 2
              "
              [(ngModel)]="value[field.key]"
              [disabled]="field.isDisabled"
              [required]="field.isRequired && !noValidate"
              [name]="field.key"
              [appendText]="field.options?.appendText"
              [incrementValuesList]="field.options?.incrementValuesList"
              #controlRef="ngModel"
            ></temp-numeric-input>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="field.type === FieldType.Currency">
            <temp-numeric-input
              [(number)]="value[field.key]"
              [placeholder]="field.name | translate"
              [numDecimals]="getValidation(field, 'numDecimals') || 2"
              [(ngModel)]="value[field.key]"
              [disabled]="field.isDisabled"
              [required]="field.isRequired && !noValidate"
              [name]="field.key"
              [prependText]="field.options.formatting?.CurrencyCode"
              [appendList]="getFieldOption(field, 'currencyCodes')"
              [incrementValuesList]="field.options?.incrementValuesList"
              (appendListItemSelected)="
                updateFormatting(field, field.key, { CurrencyCode: $event })
              "
              #controlRef="ngModel"
            ></temp-numeric-input>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="field.type === FieldType.DateTime">
            <temp-date-time-picker
              [(dateTime)]="value[field.key]"
              [hideTime]="false"
              [isContained]="true"
              [dateTimeFormat]="getFieldOption(field, 'format')"
              [invalidBefore]="getValidation(field, 'minDate')"
              [invalidAfter]="getValidation(field, 'maxDate')"
              [(ngModel)]="value[field.key]"
              [required]="field.isRequired && !noValidate"
              [name]="field.key"
              [incrementValuesList]="field.options?.incrementValuesList"
              [allowPartialValue]="field.options?.allowPartialValue"
              #controlRef="ngModel"
            ></temp-date-time-picker>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="field.type === FieldType.Date">
            <temp-date-time-picker
              [(dateTime)]="value[field.key]"
              [hideTime]="true"
              [isContained]="true"
              [dateTimeFormat]="getFieldOption(field, 'format')"
              [invalidBefore]="getValidation(field, 'minDate')"
              [invalidAfter]="getValidation(field, 'maxDate')"
              [(ngModel)]="value[field.key]"
              [required]="field.isRequired && !noValidate"
              [name]="field.key"
              [incrementValuesList]="field.options?.incrementValuesList"
              #controlRef="ngModel"
            ></temp-date-time-picker>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="field.type === FieldType.Time">
            <temp-date-time-picker
              [dateTimeFormat]="getFieldOption(field, 'format') ?? 'HH:mm'"
              [(dateTime)]="value[field.key]"
              [hideDate]="true"
              [isContained]="true"
              [invalidBefore]="getValidation(field, 'minTime')"
              [invalidAfter]="getValidation(field, 'maxTime')"
              [(ngModel)]="value[field.key]"
              [required]="field.isRequired && !noValidate"
              [name]="field.key"
              [incrementValuesList]="field.options?.incrementValuesList"
              #controlRef="ngModel"
            ></temp-date-time-picker>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="field.type === FieldType.LinkedObject">
            <temp-linked-object
              [value]="value[field.key]"
              [(ngModel)]="value[field.key]"
              [placeholder]="field.name"
              [required]="field.isRequired && !noValidate"
              [name]="field.key"
              [fieldDefinition]="field.options.fieldDefinition"
              #controlRef="ngModel"
            ></temp-linked-object>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="field.type === FieldType.LinkedObjects">
            <temp-linked-objects
              [value]="value[field.key]"
              [(ngModel)]="value[field.key]"
              [placeholder]="field.name"
              [required]="field.isRequired && !noValidate"
              [name]="field.key"
              [fieldDefinition]="field.options.fieldDefinition"
              #controlRef="ngModel"
            ></temp-linked-objects>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="field.options?.fieldCode === 'user'">
            <temp-user-list
              [value]="value[field.key]"
              [(ngModel)]="value[field.key]"
              [placeholder]="field.name"
              [required]="field.isRequired && !noValidate"
              [name]="field.key"
              #controlRef="ngModel"
            ></temp-user-list>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="field.type === FieldType.MultiLineText">
            <div class="input-group">
              <textarea
                rows="3"
                class="form-control"
                [disabled]="field.isDisabled"
                [required]="field.isRequired && !noValidate"
                [attr.maxLength]="getValidation(field, 'maxLength')"
                [attr.minLength]="getValidation(field, 'minLength')"
                [pattern]="getValidation(field, 'pattern')"
                [name]="field.key"
                [(ngModel)]="value[field.key]"
                #controlRef="ngModel"
              ></textarea>
              <div class="input-group-append" *ngIf="field.options?.appendText">
                <span class="input-group-text" id="append-text">{{
                  field.options.appendText
                }}</span>
              </div>
            </div>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="field.type === FieldType.List">
            <temp-list
              [(value)]="value[field.key]"
              [(ngModel)]="value[field.key]"
              [disabled]="field.isDisabled"
              [required]="field.isRequired && !noValidate"
              [name]="field.key"
              [sortBy]="'label'"
              [listItems]="field.options?.listItems"
              [mustMatch]="getFieldOption(field, 'mustMatch')"
              [max]="getFieldOption(field, 'max')"
              [appendText]="field.options?.appendText"
              #controlRef="ngModel"
            >
            </temp-list>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <div class="input-group">
              <input
                class="form-control"
                [disabled]="field.isDisabled"
                [required]="field.isRequired && !noValidate"
                [attr.maxLength]="getValidation(field, 'maxLength')"
                [attr.minLength]="getValidation(field, 'minLength')"
                [pattern]="getValidation(field, 'pattern')"
                [name]="field.key"
                [(ngModel)]="value[field.key]"
                #controlRef="ngModel"
                [type]="field.additionalDataModel?.mode?.edit || 'text'"
              />
              <div class="input-group-append" *ngIf="field.options?.appendText">
                <span class="input-group-text" id="append-text">{{
                  field.options.appendText
                }}</span>
              </div>
            </div>
            <ng-container
              *ngTemplateOutlet="
                validation;
                context: { $implicit: controlRef, field: field }
              "
            ></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="form-group" *ngIf="customFormControls?.[field.key]">
        <ng-container *shTemplate="'control'; id: field.key"></ng-container>
      </div>
    </ng-container>
  </div>

  <div class="form-actions">
    <button
      (click)="submit()"
      *ngIf="model?.options?.showActions"
      class="btn btn-success"
      type="button"
    >
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>

  <ng-content select="[actions]"></ng-content>
</form>

<ng-template #validation let-controlRef let-field="field">
  <span *ngIf="isSubmitted && !isValid(controlRef)" class="text-danger">
    {{
      'forms.validation.' + getError(controlRef)
        | translate: { field: field.name }
    }}
  </span>
</ng-template>
