import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';
import { BaseModel } from '@seahorse/common';
import { ValidationResult } from '@seahorse/common';
import { Guid } from 'guid-typescript';
import { inject } from '@angular/core';
import { Validators } from '@angular/forms';

export class ExportTemplateModel extends BaseModel {
  id: number;
  name: string;
  documentTypeId: number;
  templateBody: string;
  organizationId: Guid;
  systemCode: string;
  organizationName: string;
  startDate: Date;
  endDate: Date;
  enabled: boolean;
  enableStore: boolean;
  isPublic: boolean;
  templateConfiguration: ExportTemplateConfigurationModel;
  mappingKey: string;

  static validate(model: ExportTemplateModel) {
    if (
      !model.name ||
      !model.templateBody ||
      !model.documentTypeId ||
      !model.mappingKey
    ) {
      return false;
    }

    model.name = model.name.trim();
    if (model.name === '') {
      model.name = null;
      return false;
    }

    model.templateBody = model.templateBody.trim();
    if (model.templateBody === '') {
      model.templateBody = null;
      return false;
    }

    return true;
  }

  validate(errorPrefix?: string): ValidationResult {
    const result: ValidationResult = new ValidationResult();

    if (!errorPrefix) {
      errorPrefix = '';
    }

    if (this.name == null) {
      result.errors.push(errorPrefix + 'Name is required!');
    }

    this.name = this.name.trim();
    if (this.name === '') {
      this.name = null;
      result.errors.push(errorPrefix + 'Name is required!');
    }

    if (this.templateBody == null) {
      result.errors.push(errorPrefix + 'Template body is required!');
    }

    this.templateBody = this.templateBody.trim();
    if (this.templateBody === '') {
      this.templateBody = null;
      result.errors.push(errorPrefix + 'Template body code is required!');
    }

    if (this.documentTypeId === undefined) {
      result.errors.push(errorPrefix + 'Document type is required!');
    }

    if (this.mappingKey === undefined) {
      result.errors.push(errorPrefix + 'MappingKey is required!');
    }

    result.isValid = result.errors.length === 0;
    return result;
  }
}

export interface ExportTemplateConfigurationModel {
  requiredFields?: string[];
  includedExportTemplates?: number[];
  multipleSupport?: TemplateMultipleSupport;
  defaultExportFilename?: string;
  requiredData?: ExportRequiredDataModel[];
  includeUploadedFiles?: IncludeUploadedFiles;
  includeFileIds?: number[];
  shouldIncludeFiles?: boolean;
  childObjects?: ChildObjects;
  allowEditBeforeSending?: boolean;
  addressMapping?: string[];
  previewState?: ExportTemplatePreviewStates;
  visibilityCondition?: VisibilityOption;
}

export type ExportTemplateForm = FormModel<typeof createExportTemplateForm>;
export type ExportTemplateConfigurationForm = FormModel<
  typeof createExportTemplateConfigurationForm
>;

export function createExportTemplateForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    id: fb.control<number | null>(null),
    name: fb.control<string | null>(null, Validators.required),
    documentTypeId: fb.control<number | null>(null, Validators.required),
    mappingKey: fb.control<string | null>(null, Validators.required),
    templateBody: fb.control<string | null>(null, Validators.required),
    organizationId: fb.control<Guid | null>(null),
    systemCode: fb.control<string | null>({ value: null, disabled: true }),
    organizationName: fb.control<string | null>(null),
    startDate: fb.control<Date | null>(null),
    endDate: fb.control<Date | null>(null),
    enabled: fb.control<boolean>(false),
    enableStore: fb.control<boolean>(false),
    isPublic: fb.control<boolean>(false),
    templateConfiguration: createExportTemplateConfigurationForm(),
  });
}

export function createExportTemplateConfigurationForm() {
  const fb = inject(SeahorseFormBuilder);

  const childObjectsFormGroup = fb.group({
    childObjectTypes: fb.array([]),
    parentObjectType: fb.control<string | null>(null),
    parentObjectId: fb.control<string | null>(null),
  });

  const visibilityConditionFormGroup = fb.group({
    fieldCode: fb.control<string | null>(null),
    fieldValue: fb.control<string[] | null>(null),
  });

  return fb.group({
    requiredFields: fb.control<string[] | null>(null),
    includedExportTemplates: fb.control<number[] | null>(null),
    multipleSupport: fb.control<TemplateMultipleSupport | null>(null),
    defaultExportFilename: fb.control<string | null>(null),
    requiredData: fb.array([]),
    includeUploadedFiles: fb.control<IncludeUploadedFiles | null>(null),
    includeFileIds: fb.control<number[] | null>(null),
    shouldIncludeFiles: fb.control<boolean | null>(null),
    childObjects: childObjectsFormGroup,
    allowEditBeforeSending: fb.control<boolean | null>(null),
    addressMapping: fb.control<string[] | null>(null),
    previewState: fb.control<ExportTemplatePreviewStates | null>(null),
    visibilityCondition: visibilityConditionFormGroup,
  });
}

export enum ExportTemplatePreviewStates {
  Never = 0,
  AllowPreview = 1,
  AlwaysShow = 2,
}

export enum TemplateMultipleSupport {
  None,
  InTemplate,
  CreateZIP,
  Combine,
}

export enum IncludeUploadedFiles {
  Never,
  Manual,
  Individual,
  Zipped,
}

export interface ChildObjects {
  childObjectTypes?: { [key: string]: string[] }[];
  parentObjectType?: string;
  parentObjectId?: string;
}

export interface ExportRequiredDataModel {
  mappingKey?: string;
  multiple?: boolean;
  output?: string;
  field?: string;
  children?: ExportRequiredDataModel[];
}

export interface VisibilityOption {
  fieldCode?: string;
  fieldValue?: string[];
}
