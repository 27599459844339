<table class="table table-clickable">
  <thead>
    <tr>
      <th
        *ngIf="items && items.length && showCheckboxes"
        style="width: 1%"
        class="pl-3 pr-0 checkbox-cell"
      >
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            [id]="'customCheckAll_' + title"
            (change)="checkAll($event)"
          />
          <label
            class="custom-control-label"
            [for]="'customCheckAll_' + title"
          ></label>
        </div>
      </th>
      <th *ngFor="let field of fields" class="content-cell">
        {{ field.name }}
      </th>
    </tr>
  </thead>
  <tbody *ngIf="items && items.length">
    <tr
      *ngFor="let row of items"
      [class.selected]="row === selectedItem"
      [class.cursor-default]="!selectable"
    >
      <td
        *ngIf="showCheckboxes"
        class="pl-3 pr-0 checkbox-cell"
        style="width: 1%"
        (click)="toggleChecked(row)"
      >
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            [(ngModel)]="row['isChecked']"
            [ngModelOptions]="{ standalone: true }"
          />
          <label class="custom-control-label"></label>
        </div>
      </td>
      <ng-container *ngIf="readonly || (!readonly && !row.isChecked)">
        <td
          colspan="1"
          class="content-cell"
          *ngFor="let item of fields"
          (click)="selectItem(row)"
        >
          <span
            *ngIf="
              item.type === FieldType.List && item.badge && row[item.key];
              else otherFieldTypes
            "
            [shBadge]="item.badge[row[item.key]]"
          >
            <ng-container *ngTemplateOutlet="default"></ng-container>
          </span>
          <ng-container *ngIf="item.type === FieldType.ProgressBar">
            <progress
              class="status-bar"
              id="taskProgress_{{ row.__Id }}"
              [title]="row[item.key]?.Progress + '/' + row[item.key]?.Total"
              [value]="getTaskProgressFraction(row[item.key])"
              max="1"
            ></progress>
          </ng-container>

          <ng-template #otherFieldTypes>
            <ng-container *ngIf="item.type === FieldType.Status; else default">
              <ng-container [ngSwitch]="item.mode?.display">
                <div *ngSwitchCase="'bar'">
                  <div
                    title="{{
                      (row[item.key] | tempStatusProgress) +
                        ' (' +
                        (row[item.key] | tempStatusProgress: 'percentage') +
                        ')'
                    }}"
                    class="progress"
                    role="progressbar"
                    aria-label="Default striped example"
                    aria-valuenow="{{
                      row[item.key] | tempStatusProgress: 'fraction'
                    }}"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="progress-bar progress-bar-striped"
                      [ngStyle]="{
                        width:
                          (row[item.key] | tempStatusProgress: 'percentage')
                      }"
                    >
                      {{ row[item.key] | tempStatusProgress: 'percentage' }}
                    </div>
                  </div>
                </div>
                <!-- TODO implement 'battery' mode -->
                <div *ngSwitchDefault>
                  <!-- default is 'percentage' (text) -->
                  {{
                    (row[item.key] | tempStatusProgress) +
                      ' (' +
                      (row[item.key] | tempStatusProgress: 'percentage') +
                      ')'
                  }}
                </div>
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template #default>
            {{
              row[item.key]
                | fieldValue
                  : item.type
                  : item.pipeModels
                  : row
                  : users
                  : null
                  : (item.displayFields ? item.displayFields : null)
            }}
            {{ row[item.key] && (item.appendText ?? '') }}

            <ng-container
              *ngIf="
                item.showCopyButton &&
                row[item.key] !== undefined &&
                row[item.key] !== null &&
                row[item.key] !== ''
              "
            >
              <temp-copy-content-button
                [content]="
                  (row[item.key]
                    | fieldValue
                      : item.type
                      : item.pipeModels
                      : row
                      : users
                      : null
                      : (item.displayFields ? item.displayFields : null)) +
                  (row[item.key] && (item.appendText ?? ''))
                "
              ></temp-copy-content-button>
            </ng-container>
          </ng-template>
        </td>
      </ng-container>
      <temp-form
        *ngIf="!readonly && formModel && row.isChecked"
        table
        [model]="formModel"
        [value]="row"
      ></temp-form>

      <td style="width: 1%" class="pr-2" *ngIf="actions && actions.length > 0">
        <div class="dropdown">
          <button
            type="button"
            class="btn btn-light btn-sm"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis-vertical fa-fw"></i>
          </button>
          <div class="dropdown-menu">
            <button
              type="button"
              class="dropdown-item"
              (click)="itemAction(actionsItem.key, row)"
              *ngFor="let actionsItem of actions"
            >
              {{ actionsItem.name | translate }}
            </button>
            <ng-container
              [ngTemplateOutlet]="rowActionTemplate"
              [ngTemplateOutletContext]="{ $implicit: row.__Id }"
            ></ng-container>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<temp-empty-collection
  *ngIf="emptyPlaceholderConfig && (!items || (items && !items.length))"
  [icon]="emptyPlaceholderConfig.icon"
  [message]="emptyPlaceholderConfig.message"
  [size]="emptyPlaceholderConfig.size"
>
  <ng-container emptyActions>
    <ng-content select="[emptyActions]"></ng-content>
  </ng-container>
</temp-empty-collection>
