import { CustomDataSearchModel } from '../../custom-data/models/custom-data-search-field.model';
import { FieldDefinitionModel } from '../../custom-data/models/field-definition.model';

export enum UiModuleOverviewDisplayStyle {
  List = 1,
}

export class UiModuleOverviewConfiguration {
  static default(): UiModuleOverviewConfiguration {
    return {
      id: null,
      summaryConfig: {
        allowEdit: false,
        fields: null,
        actions: null,
      },
      groupsConfig: null,
      pageSize: 20,
      sortFieldCode: null,
      sortOrder: null,
      displayColumnsCodes: null,
      headerConfig: {
        displayStyle: UiModuleOverviewDisplayStyle.List,
        invoiceFlow: null,
        showSimpleSearch: true,
        showAdvancedSearch: true,
        showExportButton: true,
        showAddButton: true,
      },
      defaultFilter: {
        scope: 0,
        objectDefinitionSystemCode: null,
        fields: [],
      },
    };
  }
}

export interface UiModuleOverviewConfiguration {
  id: number;
  summaryConfig: UiModuleOverviewSummaryConfig;
  groupsConfig: UiModuleOverviewGroupsConfig;
  pageSize: number;
  sortFieldCode: string;
  sortOrder: boolean;
  displayColumnsCodes: string;
  headerConfig: UiModuleOverviewHeaderConfigModel;
  defaultFilter: CustomDataSearchModel;
}

export interface UiModuleOverviewSummaryConfig {
  actions?: UiModuleOverviewSummaryActionModel[];
  allowEdit: boolean;
  fields: string[];
  primaryAction?: string;
}

export interface UiModuleOverviewGroupsConfig {
  field: string;
  sort: string;
  fieldDefinition?: FieldDefinitionModel;
}

export interface UiModuleOverviewHeaderConfigModel {
  displayStyle: UiModuleOverviewDisplayStyle;
  invoiceFlow: string;
  showSimpleSearch: boolean;
  showAdvancedSearch: boolean;
  showExportButton: boolean;
  showAddButton: boolean;
}

export interface UiModuleOverviewSummaryActionModel {
  displayName: string;
  icon: string;
  fieldCode: string;
}
