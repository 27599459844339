<div class="modal-header align-items-center">
  <h1 class="modal-title">
    {{ 'invoicing.invoiceCreator.title' | translate }}
  </h1>
  <div class="flex-grow-1 d-flex justify-content-end align-items-center">
    <ng-container *ngIf="step === 0 && showTable && hasFiltering">
      <div class="custom-control custom-checkbox d-flex mr-2">
        <input
          id="includeFilteringBox"
          (change)="includeFiltering = !includeFiltering"
          type="checkbox"
          class="custom-control-input"
        />
        <label for="includeFilteringBox" class="custom-control-label w-100">
          {{ filterText }}
        </label>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 0 && showTable && hasGroupOptions">
      <div class="ml-2 d-flex align-items-center">
        <label for="groupSelect" class="mr-3 mt-2">{{
          'shared.terms.groupBy' | translate
        }}</label>
        <ng-select
          style="min-width: 200px"
          [(ngModel)]="selectedGroupOption"
          [items]="selectGroupOptions"
          (change)="groupBy($event)"
          bindLabel="key"
          [clearable]="false"
          [searchable]="false"
        >
          <ng-template ng-option-tmp let-item="item">
            <div [title]="item.key">{{ item.key }}</div>
          </ng-template>
        </ng-select>
      </div>
    </ng-container>
    <button closeModal type="button" class="btn btn-link text-dark">
      <i class="fa-solid fa-times fa-fw fa-sm"></i>
    </button>
  </div>
</div>

<div class="modal-body p-0" *ngIf="step === 0">
  <ng-container *ngIf="showTable; then showTable; else showText"></ng-container>

  <ng-template #showTable>
    <table
      class="table table-truncated table-clickable table-hover table-form-condensed"
    >
      <thead>
        <tr>
          <th width="1%" style="white-space: nowrap">
            <input
              id="checkboxAllObjects"
              [checked]="isAllObjectChecked"
              (change)="selectAll(!isAllObjectChecked)"
              type="checkbox"
            />
          </th>
          <th
            class="text-truncate"
            style="max-width: 700px"
            *ngFor="let column of customDataColumns; let ci = index"
            title="{{ column.displayName }}"
            [style.width]="column.width ? column.width : ''"
            [attr.colspan]="ci === 0 ? '2' : '1'"
          >
            {{ column.displayName }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="let group of groupedCustomData | keyvalue; let g = groupIndex"
        >
          <!-- Group header row -->
          <tr
            *ngIf="selectedGroupOption?.value !== null"
            class="table-row-higlight"
          >
            <td>
              <input
                [id]="'chkGroup-' + g"
                [checked]="group.value.selected"
                (change)="selectGroup(group)"
                type="checkbox"
              />
            </td>
            <ng-container *ngIf="!group.value.selected">
              <td
                *ngFor="let column of customDataColumns; let ci = index"
                (click)="selectGroup(group)"
                [attr.colspan]="ci === 0 ? '2' : '1'"
                [ngClass]="{
                  'text-truncate': column.allowOverflow === true
                }"
                title="{{
                  group.value.model.__AdditionalData &&
                  group.value.model.__AdditionalData[column.fieldName]
                    ? (group.value.model.__AdditionalData[column.fieldName]
                      | customValueDisplay: null:column
                      | async)
                    : (group.value.model[column.fieldName]
                      | customValueDisplay: null:column
                      | async)
                }}"
              >
                {{
                  group.value.model.__AdditionalData &&
                  group.value.model.__AdditionalData[column.fieldName]
                    ? (group.value.model.__AdditionalData[column.fieldName]
                      | customValueDisplay: null:column
                      | async)
                    : (group.value.model[column.fieldName]
                      | customValueDisplay: null:column
                      | async)
                }}
              </td>
            </ng-container>
            <ng-container *ngIf="group.value.selected">
              <td
                *ngFor="let formModel of formModels; let ci = index"
                [attr.colspan]="ci === 0 ? '2' : '1'"
              >
                <temp-form
                  table
                  [model]="formModel"
                  [value]="group.value.model"
                ></temp-form>
              </td>
            </ng-container>
          </tr>
          <!-- Data rows -->
          <tr
            *ngFor="let entry of group.value.data; let i = index"
            [ngClass]="{
              approved: approvedField && entry[approvedField]
            }"
          >
            <td>
              <input
                *ngIf="selectedGroupOption?.value === null"
                [id]="'chkData-' + i"
                type="checkbox"
                [checked]="selectedObjects.indexOf(entry) > -1"
                (click)="selectObject(entry, group)"
              />
            </td>
            <td style="width: 1%" *ngIf="selectedGroupOption?.value !== null">
              <input
                [id]="'chkData-' + i"
                type="checkbox"
                [checked]="selectedObjects.indexOf(entry) > -1"
                (click)="selectObject(entry, group)"
              />
            </td>
            <ng-container *ngIf="selectedObjects.indexOf(entry) === -1">
              <td
                [attr.colspan]="
                  ci === 0 && selectedGroupOption?.value === null ? '2' : '1'
                "
                *ngFor="let column of customDataColumns; let ci = index"
                (click)="
                  selectedGroupOption?.value !== null
                    ? selectGroup(group)
                    : selectObject(entry, group)
                "
                [ngClass]="{
                  'text-truncate': column.allowOverflow === true
                }"
                title="{{
                  entry.__AdditionalData &&
                  entry.__AdditionalData[column.fieldName]
                    ? (entry.__AdditionalData[column.fieldName]
                      | customValueDisplay: null:column
                      | async)
                    : (entry[column.fieldName]
                      | customValueDisplay: null:column
                      | async)
                }}"
              >
                <ng-container
                  *ngIf="ci === 0 && selectedGroupOption?.value !== null"
                ></ng-container>
                {{
                  entry.__AdditionalData &&
                  entry.__AdditionalData[column.fieldName]
                    ? (entry.__AdditionalData[column.fieldName]
                      | customValueDisplay: null:column
                      | async)
                    : (entry[column.fieldName]
                      | customValueDisplay: null:column
                      | async)
                }}
              </td>
            </ng-container>
            <ng-container *ngIf="selectedObjects.indexOf(entry) > -1">
              <td
                *ngFor="let formModel of formModels; let ci = index"
                [attr.colspan]="
                  ci === 0 && selectedGroupOption?.value === null ? '2' : '1'
                "
              >
                <temp-form
                  table
                  [model]="formModel"
                  [value]="entry"
                ></temp-form>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-template>

  <ng-template #showText>
    <div class="border rounded p-3">
      <p class="m-0">
        {{ 'customData.notFound' | translate }}
      </p>
    </div>
  </ng-template>
</div>

<div class="modal-body" [hidden]="step !== 1">
  <ca-invoice-creator-debtor-form
    [invoice]="invoice"
  ></ca-invoice-creator-debtor-form>
</div>

<div class="modal-body" [hidden]="step !== 2">
  <invoice-creator-line-card
    [invoice]="invoice"
    [hideTitle]="true"
    [readOnly]="true"
  ></invoice-creator-line-card>
</div>

<div class="modal-footer d-flex">
  <div class="flex-grow-1">
    <button closeModal type="button" class="btn btn-block btn-link">
      {{ 'shared.terms.cancel' | translate }}
    </button>
  </div>
  <div class="flex-grow-1" *ngIf="step !== 0">
    <button
      type="button"
      class="btn btn-block btn-secondary"
      (click)="step = step - 1"
    >
      {{ 'shared.terms.back' | translate }}
    </button>
  </div>
  <div class="flex-grow-1" *ngIf="step !== 2">
    <button
      type="button"
      class="btn btn-block btn-secondary"
      (click)="nextButton()"
    >
      {{ 'shared.terms.next' | translate }}
    </button>
  </div>
  <div class="flex-grow-1" *ngIf="step === 2">
    <button
      (click)="save()"
      type="button"
      class="btn btn-block btn-success"
      [disabled]="!invoice.invoiceLines || invoice.invoiceLines.length === 0"
    >
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
