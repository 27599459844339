import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import {
  CustomDataContentService,
  ExpenseModel,
  ExpensesDataService,
  FieldType,
  ObjectDefinitionModel,
  ProxyService,
} from '@seahorse/domain';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import * as _ from 'underscore';
import { DataCollectionConfig } from '../data/data-collection.model';
import { DataListComponent } from '../data/data-list/data-list.component';
import { DataField } from '../data/data.model';
import { CustomDataFormService } from '../form/custom-data-form.service';
import { FormField } from '../form/form.model';
import { ModalAction } from '../modal/modal.model';
import { ModalService } from '../modal/modal.service';

export const expensesUpdateModalProcedure = (preload: boolean) => {
  const modalService = inject(ModalService);
  const customDataFormService = inject(CustomDataFormService);
  const objectDefinition = inject(ProxyService).objectDefinitions.find(
    (x) => x.mappingKey === '$customcontent_st-expense'
  );
  const customDataService = inject(CustomDataContentService);
  const expensesDataService = inject(ExpensesDataService);
  const notificationService = inject(NotificationService);
  const translate = inject(TranslateService);

  return (
    expenses?: ExpenseModel[] | undefined,
    checkedIds?: string[],
    singleSelect = false
  ) => {
    const data$ = preload
      ? customDataService
          .getAllCustomerData('expense')
          .pipe(map((response) => response.result))
      : of(expenses);

    const fields = ObjectDefinitionModel.getAllFieldDefinitions(
      objectDefinition,
      true
    )
      .filter((x) =>
        ['description', 'amountActual', 'remarks', 'tariff'].includes(
          x.systemCode
        )
      )
      .map((x) => DataField.fromFieldDefinition(x));

    const poField: DataField = {
      key: 'po',
      name: 'PO',
      type: FieldType.SingleLineText,
    };

    fields.unshift(poField);

    const formModel = customDataFormService.getFormModel(
      objectDefinition,
      fields.map((x) => x.key as string),
      null,
      false
    );

    const poFormField: FormField<FieldType.SingleLineText> = {
      key: 'po' as keyof FieldType.SingleLineText & string,
      name: 'PO',
      groupName: null,
      type: FieldType.SingleLineText,
      isRequired: false,
      isDisabled: true,
      validation: null,
      options: null,
    };

    formModel.fields.unshift(poFormField);

    return data$.pipe(
      mergeMap((data) => {
        const modalRef = modalService
          .buildModal()
          .withTitle('customModules.expenses.updateDialog')
          .withComponentBody<DataListComponent>({
            component: DataListComponent,
            props: {
              selectable: true,
              singleSelect: singleSelect,
              items: data.map((expense) => ({
                ...expense,
                po: expense.__AdditionalData?.orderRegistration?.po || null,
                isChecked: checkedIds?.includes(expense.__Id),
              })),
              config: <DataCollectionConfig>{
                objectDefinition: objectDefinition,
                displayFields: fields,
              },
              formModel: formModel,
            },
            hostAttributes: ['flex'],
          });

        const dataListComponentInstance = modalRef
          .components[0] as DataListComponent;

        dataListComponentInstance.checkChange.subscribe();
        dataListComponentInstance.detectChanges();

        return modalRef.open({ size: 'xl', isTable: true }).pipe(
          mergeMap((res) => {
            if (res.action === ModalAction.Close) {
              return of(undefined);
            }

            const dataListRef = res.componentRef as DataListComponent;
            const updatedExpenses = dataListRef.forms.map((x) => x.value);

            res.modalRef.close();

            return expensesDataService.update(updatedExpenses).pipe(
              tap(
                (rExpenses) => {
                  if (rExpenses.hasResult) {
                    notificationService.showSuccess(
                      'customModules.expenses.expenseUpdated',
                      'shared.terms.success'
                    );
                  } else {
                    notificationService.showError(
                      _.pluck(rExpenses.messages, 'message').join('\n'),
                      translate.instant('shared.terms.failed')
                    );
                  }
                },
                (eExpenses) => {
                  notificationService.showError(
                    _.pluck(eExpenses.error.messages, 'message').join('\n'),
                    translate.instant('shared.terms.failed')
                  );
                }
              ),
              map((response) => response.result)
            );
          })
        ) as Observable<ExpenseModel[] | undefined>;
      })
    );
  };
};
