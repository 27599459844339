import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { IdentityService } from './identity.service';

@Injectable()
export class LoginGuardService implements CanActivate {
  constructor(private identity: IdentityService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.identity.isAuthenticated) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
