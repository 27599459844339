import { BaseFieldRule } from './field-rule.model';

export interface FieldTypeDefinitionModel {
  id: number;
  name: string;
  systemCode: string;
  fieldType: FieldType;
  templateCode: TemplateCode;
  rule: BaseFieldRule;
}

export interface TemplateCode {
  editModes: string[];
  displayModes: string[];
}

export enum FieldType {
  SingleLineText = 0,
  MultiLineText = 1,
  DateTime = 2,
  Integer = 3,
  Boolean = 4,
  List = 5,
  File = 6,
  Decimal = 7,
  LinkedObject = 8,
  LinkedObjects = 9,
  AutoNumbering = 10,
  Date = 11,
  Currency = 12,
  ProgressBar = 13,
  StatusCount = 14,
  Time = 15,
  Status = 16,
  // frontend only types
  Json = 100,
  Object = 101,
}

export interface FieldStatusModel {
  Progress: number;
  Total: number;
}
