import { Guid } from 'guid-typescript';
import { BaseModel, ValidationResult } from '@seahorse/common';

export class FileModel extends BaseModel {
  name: string;
  description: string;
  size: number;
  isPrivate: boolean;
  organisationId: Guid;
  createdById: Guid;
  createdOn: Date;
  fileType: string;
  filePurpose: string;
  mimeType: string;
  content: any;
  icon: string;
  objectFile: ObjectFile[]; // Should be removed later
  objectFiles: ObjectFile[];
  hasLinkedObjects?: boolean;
  file: File;
  inbox: boolean; // ui only

  static validate(model: FileModel): boolean {
    if (!model.filePurpose) {
      return false;
    }
    return true;
  }

  static fromHtmlInput(i: File): FileModel {
    const o: FileModel = new FileModel();
    o.file = i;
    o.name = i.name;
    o.fileType = i.type;
    o.size = i.size;
    o.description = i.name;

    const r = /(?:\.([^.]+))?$/;
    let p: string = null;
    let t: string = null;

    if (i.type && i.type !== '') {
      t = i.type.toLowerCase();
    } else if (r.exec(i.name)) {
      t = r.exec(i.name)[1].toLowerCase();
    }

    if (t) {
      switch (t) {
        case 'application/pdf': {
          p = 'PDF';
          break;
        }

        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
          p = 'Word document';
          break;
        }

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
          p = 'Excel document';
          break;
        }

        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
          p = 'PowerPoint document';
          break;
        }

        case 'text/plain': {
          p = 'Text file';
          break;
        }

        case 'application/x-zip-compressed': {
          p = 'ZIP';
          break;
        }

        case 'msg': {
          p = 'E-mail';
          break;
        }
      }

      o.filePurpose = p ? p : t;
    }

    return o;
  }

  validate(errorPrefix?: string): ValidationResult {
    throw new Error('Method not implemented.');
  }
}

export interface ObjectFile {
  objectId: string;
  objectType: string;
}

export class FileUpdateModel extends BaseModel {
  name: string;
  description: string;
  fileType: string;
  filePurpose: string;
  isPrivate: boolean;

  static fromFileModel(file: FileModel): FileUpdateModel {
    const fileUpdateModel = new FileUpdateModel();
    fileUpdateModel.id = file.id;
    fileUpdateModel.name = file.name;
    fileUpdateModel.description = file.description;
    fileUpdateModel.fileType = file.fileType;
    fileUpdateModel.filePurpose = file.filePurpose;
    fileUpdateModel.isPrivate = file.isPrivate;
    return fileUpdateModel;
  }

  static validate(model: FileUpdateModel): boolean {
    if (!model.filePurpose || !model.name || !model.fileType) {
      return false;
    }
    return true;
  }

  validate(errorPrefix?: string): ValidationResult {
    throw new Error('Method not implemented.');
  }
}
