import { inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';

export type ExportSelectRecipientModalForm = FormModel<
  typeof createExportSelectRecipientModalForm
>;

export function createExportSelectRecipientModalForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    notificationBody: fb.control<string | null>(null),
    selectedRecipients: fb.control<string[] | null>(
      null,
      Validators.compose([Validators.required, Validators.minLength(1)])
    ),
    subject: fb.control<string | null>(null),
  });
}
