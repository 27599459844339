import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccountService } from '../../services/user-account.service';
import { MfaService, MfaSetupComponent } from '@seahorse/temp';
import { MfaSetupCode } from '@seahorse/domain';
import { NotificationService } from '@seahorse/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-activate-account',
  templateUrl: './activate-account.component.html',
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
  @ViewChild('mfaSetup', { static: false })
  mfaSetupComponent?: MfaSetupComponent;

  isLoading = true;
  token: string;
  userId: string;
  activated = false;
  mfaSetupCode?: MfaSetupCode;

  private _subscription = new Subscription();

  private _accountService = inject(UserAccountService);
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);
  private _mfaService = inject(MfaService);
  private _notificationService = inject(NotificationService);

  ngOnInit() {
    this._subscription.add(
      this._route.queryParamMap.subscribe((params) => {
        this.token = params.get('token');
        this.userId = this.token;

        const mfaSetupInProgress = localStorage.getItem('mfaSetupInProgress');
        if (mfaSetupInProgress) {
          this.isLoading = false;
          this.activated = true;
          return;
        }

        if (this.token) {
          this._subscription.add(
            this._accountService
              .activateAccount(this.token)
              .subscribe((response) => {
                if (response) {
                  this.isLoading = false;
                  //TODO: MFA setup is temporarily disabled
                  // this.activated = true;
                  this.backToLogin();
                  setTimeout(() => {
                    this._accountService.activateAccountMessage();
                  }, 1000);
                }
              })
          );
        }
      })
    );
  }

  initMfa() {
    localStorage.setItem('mfaSetupInProgress', this._router.url);

    this._subscription.add(
      this._mfaService.initMfaWithUserId(this.userId).subscribe((res) => {
        this.mfaSetupCode = res.result;
      })
    );
  }

  completeMfa() {
    if (!this.mfaSetupComponent) {
      return;
    }

    const code = this.mfaSetupComponent.codeControl.getRawValue();

    if (!code.length) {
      this._notificationService.showError('user.mfa.verificationCodeRequired');

      return;
    }

    this._subscription.add(
      this._mfaService
        .completeMfaWithUserId(this.userId, code)
        .subscribe((res) => {
          if (res.result) {
            this._notificationService.showSuccess(
              'user.mfa.enabled',
              'shared.terms.success'
            );

            localStorage.removeItem('mfaSetupInProgress');
            this._router.navigate(['/']);
          } else {
            this._notificationService.showError(
              'user.mfa.invalidVerificationCode',
              'shared.terms.error'
            );
          }
        })
    );
  }

  backToLogin() {
    localStorage.removeItem('mfaSetupInProgress');
    this._router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
