import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyModel } from '@seahorse/domain';
import { EventModel } from '../../../../shared/models/event.model';

@Component({
  selector: 'ca-company-info',
  templateUrl: 'company-info.component.html',
})
export class CompanyInfoComponent {
  @Input() company: CompanyModel;
  @Output() onEvent = new EventEmitter<EventModel>();

  get hasParent() {
    return this.company && this.company.parentCompanyId;
  }
}
