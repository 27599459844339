<div class="card" tempFileDragDrop [isFileDroppedFromPage]="true" [filesOnly]="true">
  <div class="card-header">
    {{ title }}
    <div class="btn-list">
      <div class="small input-group">
        <div
          class="small custom-control custom-checkbox d-flex align-self-end mr-2"
        >
          <input
            id="includeChargedBox"
            (change)="includeCharged = !includeCharged"
            type="checkbox"
            class="custom-control-input"
          />
          <label
            for="includeChargedBox"
            class="small custom-control-label w-100"
          >
            {{ 'customModules.expenses.includeCharged' | translate }}
          </label>
        </div>
        <button
          (click)="openInvoiceDialog()"
          type="button"
          class="btn btn-outline-secondary mx-1 text-nowrap btn-sm"
          *ngIf="parent"
        >
          <span><i class="fa-regular fa-euro-sign fa-fw"></i></span>
          {{ 'invoicing.invoiceModel.invoice' | translate }}
        </button>
        <button
          (click)="openExpenseDialog()"
          class="btn btn-outline-secondary ml-1 btn-sm"
          type="button"
        >
          <i class="fa-regular fa-plus fa-fw"></i>
          {{ 'shared.terms.add' | translate }}
        </button>
        <div *ngIf="showCustomDefaultDataPicker" class="input-group-append">
          <button
            class="btn btn-outline-secondary dropdown-toggle btn-sm"
            type="button"
            data-toggle="dropdown"
          ></button>
          <div class="dropdown-menu">
            <button
              (click)="openDefaultExpensesDialog()"
              type="button"
              class="btn dropdown-item"
            >
              <i class="fa-regular fa-plus fa-fw"></i>
              {{ 'customModules.expenses.addFromDefault' | translate }}
            </button>
          </div>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="loading; then showLoading; else showContent"
  ></ng-container>
  <ng-template #showLoading>
    <sh-loading-indicator
      *ngIf="loading"
      class="d-block text-center"
    ></sh-loading-indicator>
  </ng-template>
  <ng-template #showContent>
    <ng-container
      *ngIf="
        expenses && expenses.length > 0;
        then showResult;
        else showNoResult
      "
    ></ng-container>
    <ng-template #showResult>
      <div class="card-body card-body-table">
        <ca-custom-content-list
          [entryId]="null"
          [definition]="expenseDefinition"
          [entries]="filteredExpenses"
          [footers]="showTotals ? expensesTotals : []"
          [columns]="columns"
          [approvedField]="'charged'"
          (onEntrySelected)="openExpenseDialog($event)"
        ></ca-custom-content-list>
      </div>
    </ng-template>
    <ng-template #showNoResult>
      <div class="card-body">
        <div class="text-muted text-center">
          {{ 'shared.terms.noResult' | translate }}
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
