import { Injectable } from '@angular/core';

@Injectable()
export class FormattingService {
  groupByField(arr: any[], groupField: string) {
    return arr.reduce(
      (groups, item) => ({
        ...groups,
        [item[groupField]]: [...(groups[item[groupField]] || []), item],
      }),
      {}
    );
  }

  groupByFunction(arr: any[], callback: any) {
    return arr.reduce(
      (a, c) => (
        a[callback(c)] ? a[callback(c)].push(c) : (a[callback(c)] = [c]), a
      ),
      {}
    );
  }

  padLeft(str, padChar, totalSize) {
    if (!padChar) {
      return str;
    }
    let result = str;
    while (result.length < totalSize) {
      result = padChar + result;
    }
    return result;
  }

  padRight(str, padChar, totalSize) {
    if (!padChar) {
      return str;
    }
    let result = str;
    while (result.length < totalSize) {
      result = result + padChar;
    }
    return result;
  }

  getPropertyByPath(obj: any, path: string) {
    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    path = path.replace(/^\./, ''); // strip a leading dot
    const a = path.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in obj) {
        obj = obj[k];
      } else {
        return;
      }
    }
    return obj;
  }

  sortBy(arr: any[], sortField: string, reverse = false) {
    const result = [].concat(arr).sort((a, b) => {
      if (!a[sortField] && !b[sortField]) {
        return 0;
      } else if (a[sortField] && !b[sortField]) {
        return 1;
      } else if (!a[sortField] && b[sortField]) {
        return -1;
      } else if (a[sortField] < b[sortField]) {
        return -1;
      } else if (a[sortField] > b[sortField]) {
        return 1;
      }
      return 0;
    });
    return reverse ? result.reverse() : result;
  }
}
