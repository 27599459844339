import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CustomDataBaseModel, FieldType, UserModel } from '@seahorse/domain';
import { BadgeDirective } from '@seahorse/ui';
import { FormComponent } from '../../form/form.component';
import { FormModel } from '../../form/form.model';
import { FieldValuePipe } from '../../pipes/field-value.pipe';
import { StatusProgressPipe } from '../../pipes/status-progress.pipe';
import { DataCollectionConfig } from '../data-collection.model';
import { DataField, DataListAction } from '../data.model';
import { EmptyCollectionTemplate } from '../empty-collection.template';
import { CopyContentButtonComponent } from '../../buttons/copy-content-button/copy-content-button.component';

@Component({
  selector: 'temp-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CopyContentButtonComponent,
    FormsModule,
    EmptyCollectionTemplate,
    FieldValuePipe,
    StatusProgressPipe,
    BadgeDirective,
    TranslateModule,
    FormComponent,
  ],
  providers: [FieldValuePipe, StatusProgressPipe],
})
export class DataListComponent<
  TData extends CustomDataBaseModel = CustomDataBaseModel
> {
  @ContentChild('rowAction', { read: TemplateRef })
  rowActionTemplate?: TemplateRef<any>;

  @ViewChildren(FormComponent) forms: QueryList<FormComponent>;

  @Input() actions?: DataListAction[];

  get readonly() {
    return this.formModel == null;
  }

  private _config?: DataCollectionConfig<TData>;
  @Input() set config(val: DataCollectionConfig<TData>) {
    if (!val) {
      return;
    }

    this._config = val;

    this.fields =
      this._config.displayFields?.sort(
        (a, b) =>
          (a.order == null ? Number.MAX_VALUE : a.order) -
          (b.order == null ? Number.MAX_VALUE : b.order)
      ) ?? [];

    if (this._config.objectDefinition?.objectFieldDefinitions) {
      this.fields.map((x) => {
        const fieldDef =
          this._config.objectDefinition.objectFieldDefinitions.find(
            (f) => f.systemCode === x.key
          );
        x.showCopyButton = fieldDef?.additionalDataModel?.showCopyButton;
        return x;
      });
    }
  }

  get config() {
    return this._config;
  }

  get showCheckboxes() {
    return this.checkChange.observers.length > 0;
  }

  @Input() items: TData[];
  @Input() users: UserModel[];
  @Input() selectable = true;
  @Input() singleSelect = false;
  @Input() title: string;
  @Input() emptyPlaceholderConfig: {
    size: string;
    message: string;
    icon: string;
  };
  // remove this property after refactor the selection logic, don't forgot remove the usage of this property at inbox-overview.page.html (2x)
  @Input() setSelectedUI = false;

  @Output() action = new EventEmitter<{ action: string; data: TData }>();
  @Output() checkChange = new EventEmitter<TData[]>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<TData>();

  selectedItem: TData;
  fields: DataField[];
  FieldType = FieldType;

  private cdr = inject(ChangeDetectorRef);
  private _fieldValuePipe = inject(FieldValuePipe);
  @Input() formModel?: FormModel;

  get checkedItems() {
    return this.items?.filter((item) => item['isChecked'] === true);
  }

  itemAction(action: string, item: TData) {
    this.action.emit({ action, data: item });
  }

  selectItem(item: TData) {
    if (!this.selectable) {
      return;
    }

    if (this.setSelectedUI) {
      this.selectedItem = item;
    }

    this.toggleChecked(item);
    this.select.emit(item);
  }

  checkAll(event) {
    if (this.singleSelect) {
      return;
    }

    this.items.forEach((item) => (item['isChecked'] = event.target.checked));
    this.checkChange.emit(this.checkedItems);
  }

  toggleChecked(item: TData) {
    if (
      !item['isChecked'] &&
      this.singleSelect &&
      this.checkedItems.length > 0
    ) {
      this.checkedItems.forEach((i) => (i['isChecked'] = false));
    }

    item['isChecked'] = !item['isChecked'];
    this.checkChange.emit(this.checkedItems);
  }

  prepareItemsForExport() {
    const data = [];

    this.items.forEach((row) => {
      const preparedRow = [];

      this.fields.forEach((item) => {
        preparedRow.push(
          this._fieldValuePipe.transform(
            row[item.key],
            item.type,
            item.pipeModels
          )
        );
      });

      data.push(preparedRow);
    });

    return {
      title: this.title,
      data: data,
      fields: this.fields.map((x) => x.name),
    };
  }

  getTaskProgressFraction(taskStatus): number {
    if (taskStatus && taskStatus.Total > 0) {
      if (taskStatus.Progress === 0) {
        return 1;
      }

      if (
        taskStatus.Progress === taskStatus.Total &&
        taskStatus.Progress / taskStatus.Total === 1
      ) {
        return 0;
      }

      return taskStatus.Progress / taskStatus.Total;
    }
    return 1;
  }

  detectChanges() {
    this.cdr.detectChanges();
  }
}
