import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  EnvService,
  FileSizePipe,
  NotificationService,
  ResultWrapper,
} from '@seahorse/common';
import {
  CustomDataBaseModel,
  FileModel,
  FileService,
  InboxDataService,
} from '@seahorse/domain';
import * as _ from 'underscore';
@Component({
  selector: 'temp-file-import-dialog',
  templateUrl: 'file-import-dialog.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, FileSizePipe],
})
export class FileImportDialogComponent implements OnInit {
  @Input() filesDropped: FileList;

  @Input() title?: string;
  @Input() objectId?: string;
  @Input() object: CustomDataBaseModel;
  @Input() systemCode: string;
  @Input() objectMappingKey?: string; // e.g. $module_objecttype_field if it is a custom data please include Scope!
  @Input() hasParent = false;
  @Input() hasLinkedObjects = false;
  @Input() inbox = false;

  @Input() set purposes(a: string[]) {
    if (a) {
      this._purposes = _.clone(a);
      const acceptList: string[] = [];

      _.forEach(a, (x) => {
        switch (x.toLowerCase()) {
          case 'email': {
            acceptList.push('.msg');
            break;
          }
        }
      });

      this.accept = acceptList.join(', ');
    } else {
      this._purposes = [];
    }
  }
  get purposes() {
    return this._purposes;
  }

  @Output() confirm: EventEmitter<{ files: FileModel[] }>;
  @Output() linkToObjectChange: EventEmitter<boolean>;
  @Input() linkToObject: boolean;

  private _purposes: string[];

  files: FileModel[];
  accept: string;
  loading: boolean;

  isWizardEnabled = this._env.useFileUploadWizard;

  constructor(
    private _env: EnvService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private notification: NotificationService,
    private filesData: FileService,
    private _inboxDataService: InboxDataService
  ) {
    this.title = this.translate.instant('files.fileImportDialog.title');
    this.objectId = null;
    this.objectMappingKey = null;
    this.purposes = [];
    this.confirm = new EventEmitter<{ files: FileModel[] }>();
    this._purposes = [];
    this.files = [];
    this.accept = null;
    this.loading = false;
    this.filesDropped = null;
    this.linkToObject = false;
    this.linkToObjectChange = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    if (this.filesDropped && this.filesDropped.length !== null) {
      this.onSelect(this.filesDropped);
    }
  }

  onSelect(i: FileList) {
    if (i && i.length) {
      _.forEach(i, (x) => {
        const file: FileModel = FileModel.fromHtmlInput(x);
        file.isPrivate = false;

        if (file && !_.any(this.files, (f) => _.isEqual(f, file))) {
          this.files.push(file);
        }
      });
    }
  }

  deselectFile(file: FileModel) {
    if (
      this.files &&
      this.files.length &&
      file &&
      _.contains(this.files, file)
    ) {
      this.files = _.without(this.files, file);
    }
  }

  uploadFiles() {
    if (
      this.objectId &&
      this.objectMappingKey &&
      this.files &&
      this.files.length
    ) {
      if (this.inbox && this.systemCode && this.object) {
        this.objectMappingKey = `$customcontentinbox_${this.systemCode}_id`;

        this._inboxDataService
          .get(this.systemCode, this.objectId)
          .subscribe((res) => {
            if (!res.result) {
              this._inboxDataService
                .addShadowData(this.systemCode, this.object)
                .subscribe();
            }
          });
      }

      this.loading = true;
      const successful: ResultWrapper<FileModel>[] = [];
      let unsuccessful = 0;
      let messages: ResultWrapper<any>['messages'][] = [];

      _.forEach(this.files, (x) => {
        x.objectFile = [
          { objectId: this.objectId, objectType: this.objectMappingKey },
        ];
        this.filesData.addFile(x).subscribe(
          (r: ResultWrapper<FileModel>) => {
            if (r.hasResult) {
              successful.push(r);
            } else {
              messages = messages.concat(r.messages);

              unsuccessful++;
            }
          },
          (e) => {
            messages = messages.concat(e.error.messages);

            unsuccessful++;
          },
          () => {
            if (successful.length + unsuccessful === this.files.length) {
              this.loading = false;

              this.confirm.emit({ files: _.map(successful, 'result') });
              this.activeModal.close();

              if (this.files.length > 1) {
                if (successful.length) {
                  const successfulMessage = `${successful.length}/${
                    this.files.length
                  } ${this.translate.instant('files.filesAdded')}`;

                  if (unsuccessful) {
                    this.notification.showWarning(
                      successfulMessage,
                      this.translate.instant('shared.terms.success')
                    );
                  } else {
                    this.notification.showSuccess(
                      successfulMessage,
                      this.translate.instant('shared.terms.success')
                    );
                  }
                }
              } else {
                this.notification.showSuccess(
                  this.translate.instant('files.fileAdded'),
                  this.translate.instant('shared.terms.success')
                );
              }

              if (unsuccessful) {
                this.notification.showError(
                  _.pluck(messages, 'message').join('\n'),
                  this.translate.instant('shared.terms.failed')
                );
              }
            }
          }
        );
      });
    } else {
      this.confirm.emit({ files: this.files });
      this.activeModal.close();
    }
  }

  close() {
    this.activeModal.close();
  }
}
