<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ title }}</h1>
  <button
    *ngIf="!hasParent"
    closeModal
    type="button"
    class="btn btn-link text-dark"
    (click)="close()"
  >
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div class="btn btn-light btn-lg btn-block p-0">
    <label for="files" class="h-100 w-100 mb-0 py-2" style="cursor: pointer">
      <i class="fa-regular fa-upload fa-fw"></i>
      {{ 'shared.terms.browse' | translate }}...
    </label>
    <input
      [accept]="accept"
      (change)="onSelect($event.target.files)"
      multiple
      hidden
      id="files"
      type="file"
    />
  </div>
  <table *ngIf="files.length" class="table mt-3">
    <thead>
      <tr>
        <th scope="col" class="required">
          {{ 'shared.terms.description' | translate }}
        </th>
        <th scope="col" class="required">
          {{ 'files.model.purpose' | translate }}
        </th>
        <th scope="col">{{ 'files.model.size' | translate }}</th>
        <th *ngIf="!inbox" scope="col">
          {{ 'files.model.private' | translate }}
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let file of files; let i = index">
        <td>
          <input
            [(ngModel)]="file.description"
            [name]="'description-' + i"
            [placeholder]="'files.placeholders.description' | translate"
            required
            class="form-control"
          />
        </td>
        <td>
          <input
            [(ngModel)]="file.filePurpose"
            [name]="'filePurpose-' + i"
            [placeholder]="'files.placeholders.purpose' | translate"
            required
            class="form-control"
          />
        </td>
        <td class="text-center align-middle">
          <ng-container *ngIf="file.size">{{
            file.size | fileSize
          }}</ng-container>
        </td>
        <td *ngIf="!inbox" class="text-center align-middle">
          <input [(ngModel)]="file.isPrivate" type="checkbox" />
        </td>
        <td class="align-middle">
          <button (click)="deselectFile(file)" class="btn btn-link text-danger">
            <i class="fa-solid fa-times fa-fw"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    *ngIf="hasLinkedObjects && isWizardEnabled && files?.length"
    class="mt-4 mb-3 text-center"
  >
    <button
      class="btn btn-primary"
      type="button"
      (click)="linkToObjectChange.emit(!linkToObject)"
    >
      {{ 'customModules.orderRegistrations.details.files.link' | translate }}
    </button>
  </div>
</div>
<div *ngIf="!hasParent || !linkToObject" class="modal-footer">
  <button
    closeModal
    type="button"
    class="btn btn-link flex-fill"
    (click)="close()"
  >
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="loading || (files && !files.length)"
    (click)="uploadFiles()"
    type="button"
    class="btn btn-success flex-fill"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
