import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { BehaviorSubject, Observable } from 'rxjs';

import { ServiceConnectionSubscriptionModel } from '../models/service-connection-subscription.model';
import { ServiceConnectionTokenModel } from '../models/service-connection-token.model';
import { ServiceConnectionModel } from '../models/service-connection.model';
import { DataService } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class ServiceConnectionsDataService
  implements DataService<ServiceConnectionModel>
{
  private _subscriptions: ServiceConnectionSubscriptionModel[] =
    [] as ServiceConnectionSubscriptionModel[];

  get subscriptions(): ServiceConnectionSubscriptionModel[] {
    return this._subscriptions;
  }

  set subscriptions(value: ServiceConnectionSubscriptionModel[]) {
    this._subscriptions = value;
  }

  private serviceConnectionId = new BehaviorSubject<number>(null);
  setServiceConnectionIdCalled$ = this.serviceConnectionId.asObservable();

  constructor(
    private dataContext: DataContextService,
    private http: HttpClient
  ) {}

  getById(
    connectionId: number
  ): Observable<ResultWrapper<ServiceConnectionModel>> {
    return this.dataContext.get<ServiceConnectionModel>(
      `service/connections/${connectionId}`
    );
  }

  getSubscriptions(): Observable<
    ResultWrapper<ServiceConnectionSubscriptionModel[]>
  > {
    return this.dataContext.get<ServiceConnectionSubscriptionModel[]>(
      'service/connections/subscriptions'
    );
  }

  search(
    searchQuery?: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<ServiceConnectionModel[]>> {
    const queryParams = [];

    if (searchQuery !== undefined && searchQuery !== null) {
      queryParams.push(`searchTerm=${encodeURIComponent(searchQuery)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pageIndex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `service/connections/search${
      queryString ? '?' + queryString : ''
    }`;

    return this.dataContext.get<ServiceConnectionModel[]>(url);
  }

  add(
    connection: ServiceConnectionModel
  ): Observable<ResultWrapper<ServiceConnectionModel>> {
    return this.dataContext.post<ServiceConnectionModel>(
      `service/connections`,
      connection
    );
  }

  update(
    connection: ServiceConnectionModel
  ): Observable<ResultWrapper<ServiceConnectionModel>> {
    return this.dataContext.put<ServiceConnectionModel>(
      `service/connections/${connection.id}`,
      connection
    );
  }

  delete(
    connectionId: number
  ): Observable<ResultWrapper<ServiceConnectionModel>> {
    return this.dataContext.delete<ServiceConnectionModel>(
      `service/connections/${connectionId}`
    );
  }

  setServiceConnection(id: number) {
    this.serviceConnectionId.next(id);
  }

  subscribe(
    id: number
  ): Observable<ResultWrapper<ServiceConnectionSubscriptionModel>> {
    return this.dataContext.get<ServiceConnectionSubscriptionModel>(
      `service/connections/${id}/subscribe`
    );
  }

  unsubscribe(
    id: number
  ): Observable<ResultWrapper<ServiceConnectionSubscriptionModel>> {
    return this.dataContext.get<ServiceConnectionSubscriptionModel>(
      `service/connections/${id}/unsubscribe`
    );
  }

  updateSubscription(
    subscription: ServiceConnectionSubscriptionModel
  ): Observable<ResultWrapper<ServiceConnectionSubscriptionModel>> {
    return this.dataContext.put<ServiceConnectionSubscriptionModel>(
      `service/connections/${subscription.serviceConnectionId}/subscription/${subscription.id}`,
      subscription
    );
  }

  getTokenForConnection(
    id: number
  ): Observable<ResultWrapper<ServiceConnectionTokenModel>> {
    return this.dataContext.get<ServiceConnectionTokenModel>(
      `service/connections/${id}/token`
    );
  }

  getTokenForSubscription(
    id: number,
    subscriptionId: number
  ): Observable<ResultWrapper<ServiceConnectionTokenModel>> {
    return this.dataContext.get<ServiceConnectionTokenModel>(
      `service/connections/${id}/subscription/${subscriptionId}/token`
    );
  }

  confirmSubscription(
    serviceName: string,
    queryParams: any
  ): Observable<ResultWrapper<boolean>> {
    let params = '';
    if (queryParams) {
      params = Object.keys(queryParams)
        .map((x) => {
          return `${x}=${queryParams[x]}`;
        })
        .join('&');
    }
    return this.dataContext.get<boolean>(
      `service/connections/confirm/${serviceName}?${params}`
    );
  }
}
