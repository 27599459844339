import { InjectionToken, Type } from '@angular/core';
import { FieldType } from '@seahorse/domain';
import { Observable } from 'rxjs';

export enum FieldFormType {
  Inline,
  Modal,
  None,
}

type ValidationKey =
  | 'maxLength'
  | 'minLength'
  | 'pattern'
  | 'numDecimals'
  | 'format'
  | 'minDate'
  | 'maxDate'
  | 'currencyCodes'
  | 'mustMatch'
  | 'max'
  | 'minTime'
  | 'maxTime';

export type FormFieldValidation = {
  [key in ValidationKey]: any;
};

interface FormFieldOptions {
  [key: string]: any;
}

export interface CustomFieldControl<T> {
  component: Type<T>;
  data?: (value: any) => { [key in keyof T]?: T[key] } & {
    valueChange$: Observable<any>;
  };
}

export type formControlFactory = (field: FormField) => CustomFieldControl<any>;
export const CUSTOM_FORM_CONTROLS = new InjectionToken<formControlFactory>(
  'CUSTOM_FORM_CONTROLS'
);

export interface FormField<TModel = any> {
  key: keyof TModel & string;
  name: string;
  groupName: string;
  type: FieldType;
  isRequired: boolean;
  isDisabled: boolean;
  validation?: Partial<FormFieldValidation>;
  options?: FormFieldOptions;
}

interface FormOptions {
  mode?: 'inline' | 'modal';
  showActions?: boolean;
  noValidate?: boolean;
}

export interface FormModel<TModel = any> {
  fields: FormField<TModel>[];
  value: any;
  options?: FormOptions;
}

export interface IncrementValuesListItem {
  display: string;
  value: number;
}

export interface DateTimePartialModel {
  date?: PartialValueModel;
  time?: PartialValueModel;
}

interface PartialValueModel {
  sfDate?: Date | null;
  value?: string;
  isEmpty?: boolean;
}

export const fieldFormTypeDictionary: Record<FieldType, FieldFormType> = {
  [FieldType.AutoNumbering]: FieldFormType.None,
  [FieldType.Boolean]: FieldFormType.Modal,
  [FieldType.Currency]: FieldFormType.Modal,
  [FieldType.Date]: FieldFormType.Modal,
  [FieldType.DateTime]: FieldFormType.Modal,
  [FieldType.Decimal]: FieldFormType.Inline,
  [FieldType.File]: FieldFormType.None,
  [FieldType.Integer]: FieldFormType.Inline,
  [FieldType.LinkedObject]: FieldFormType.Modal,
  [FieldType.LinkedObjects]: FieldFormType.Modal,
  [FieldType.List]: FieldFormType.Modal,
  [FieldType.MultiLineText]: FieldFormType.Inline,
  [FieldType.SingleLineText]: FieldFormType.Inline,
  [FieldType.ProgressBar]: FieldFormType.None,
  [FieldType.StatusCount]: FieldFormType.None,
  [FieldType.Json]: FieldFormType.Modal,
  [FieldType.Object]: FieldFormType.Modal,
  [FieldType.Time]: FieldFormType.Modal,
  [FieldType.Status]: FieldFormType.None,
};
