import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserDataService } from '../../user/services/user-data.service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { IdentityService } from './identity.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public identity: IdentityService,
    public userService: UserDataService,
    public router: Router
  ) {}

  canActivate(): Observable<boolean> {
    if (!this.identity.isAuthenticated) {
      this.router.navigate(['account/login']);
      return of(false);
    }

    if (this.identity.userPermissions.length > 0) {
      return of(true);
    } else {
      return this.userService.setUserIdentity().pipe(mergeMap((_) => of(true)));
    }
  }
}
