import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'underscore';

import {
  CustomDataBaseModel,
  FieldDefinitionModel,
  FieldType,
  FieldVisibility,
  ObjectDefinitionModel,
} from '@seahorse/domain';
import { RenderOptionModel } from '../../../layout/models/table.model';
import { KeyValuePair } from '@seahorse/common';

@Component({
  selector: 'ca-custom-content-details-card',
  templateUrl: './custom-content-details-card.component.html',
  styleUrls: ['./custom-content-details-card.component.scss'],
})
export class CustomContentDetailsComponent implements OnInit {
  private _definition: ObjectDefinitionModel = null;
  @Input() set definition(definition: ObjectDefinitionModel) {
    if (!definition) {
      this._definition = new ObjectDefinitionModel();
    } else {
      this._definition = definition;
    }
    this.resetFields();
  }
  get definition(): ObjectDefinitionModel {
    return this._definition;
  }

  private _entry: CustomDataBaseModel = null;
  @Input() set entry(entry: CustomDataBaseModel) {
    if (!entry) {
      this._entry = new CustomDataBaseModel();
    } else {
      this._entry = entry;
    }

    if (!this._entry.__AdditionalData) {
      this._entry.__AdditionalData = {};
    }
    this.resetFields();
  }
  get entry(): CustomDataBaseModel {
    return this._entry;
  }

  private _showBaseFields: boolean = null;
  @Input() set showBaseFields(showBaseFields: boolean) {
    this._showBaseFields = showBaseFields;
    this.resetFields();
  }
  get showBaseFields(): boolean {
    return this._showBaseFields;
  }

  @Input() excludeFields: string[];
  @Input() excludeGroups: string[];
  @Input() hideTitle: boolean;
  @Input() inlineEdit: boolean;
  @Input() isEditMode: boolean;
  @Input() fieldPipes: KeyValuePair<string, RenderOptionModel>[];
  @Input() showGroups: string[];
  @Input() showBasicInfo: boolean;
  @Input() title: string;
  @Input() titleDescription: string;
  @Input() buttonFields: string[];
  @Input() submitted = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onButtonClicked: EventEmitter<string>;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFieldChanged: EventEmitter<any>;

  fieldGroups = null;
  mappedKeys = null;
  fieldType = FieldType;
  renderOptions = null;
  buttonFieldList: FieldDefinitionModel[];

  constructor() {
    this.onFieldChanged = new EventEmitter<any>();
    this.mappedKeys = {};
    this.buttonFields = [];
    this.buttonFieldList = [];
    this.onButtonClicked = new EventEmitter<string>();
  }

  ngOnInit() {
    if (this.inlineEdit === undefined || this.inlineEdit === null) {
      this.inlineEdit = true;
    }

    if (this.showBasicInfo === undefined || this.showBasicInfo === null) {
      this.showBasicInfo = true;
    }

    if (this.renderOptions === null) {
      this.renderOptions = {};
      this.resetFields();
    }

    if (this.fieldPipes && this.fieldPipes.length > 0) {
      _.each(this.fieldPipes, (pair) => {
        this.renderOptions[pair.key] = pair.value;
      });
    }
    // console.log(this.renderOptions['status'].pipes[0].name);
  }

  resetFields() {
    if (this.definition && this.entry) {
      let fields;
      if (this.showBaseFields === true) {
        fields = ObjectDefinitionModel.getAllFieldDefinitions(
          this.definition,
          false,
          [FieldVisibility.Public, FieldVisibility.Private]
        );
      } else {
        fields = this.definition.objectFieldDefinitions;
      }

      if (this.excludeFields && this.excludeFields.length > 0) {
        fields = _.filter(fields, (f: FieldDefinitionModel) => {
          return !_.some(this.excludeFields, (ex) => {
            return ex.toLowerCase() === f.systemCode.toLowerCase();
          });
        });
      }

      _.each(fields, (field: FieldDefinitionModel) => {
        if (
          field.systemCode.startsWith('$') &&
          field.fieldTypeDefinitionId !== 13 &&
          field.fieldTypeDefinitionId !== 14
        ) {
          const lastIndex = field.systemCode.lastIndexOf('_');
          const objectKey = field.systemCode.substring(0, lastIndex);
          if (this.entry[objectKey]) {
            this.mappedKeys[field.systemCode] = objectKey;
          }

          // temperory fix, change fieldtype
          if (this.isEditMode === true) {
            field.fieldTypeDefinitionId = 13;
            field.fieldType = 8;
            // field.isReadOnly = true;
          }
        } else if (field.fieldTypeDefinitionId === 12) {
          // temperory fix, disabled to edit user field
          field.isReadOnly = true;
        }

        if (_.contains(this.buttonFields, field.systemCode)) {
          this.buttonFieldList.push(field);
        }
      });

      if (this.buttonFieldList.length) {
        fields = _.filter(fields, (f: FieldDefinitionModel) => {
          return !_.some(this.buttonFields, (ex) => {
            return ex.toLowerCase() === f.systemCode.toLowerCase();
          });
        });
      }

      this.fieldGroups = {};
      const groups = _.groupBy(fields, (field: FieldDefinitionModel) =>
        !field.groupName ? -1 : field.groupName
      );
      _.each(groups, (grp, key) => {
        const sortedFields = _.sortBy(grp, (field: FieldDefinitionModel) => {
          return !field.sortOrder || isNaN(field.sortOrder)
            ? Number.MAX_SAFE_INTEGER
            : parseInt(field.sortOrder);
        });

        if (
          !this.excludeGroups ||
          !_.some(
            this.excludeGroups,
            (exGroup) => exGroup.toLowerCase() === key.toLowerCase()
          )
        ) {
          if (
            !this.showGroups ||
            this.showGroups.length === 0 ||
            this.showGroups.findIndex(
              (x) => x.toLowerCase() === key.toLowerCase()
            ) > -1
          ) {
            this.fieldGroups[key] = sortedFields;
          }
        }
      });
    }
  }
}
