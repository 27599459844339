import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DataContextService, ResultWrapper } from '@seahorse/common';
import { CloneLinkedObjectsRequest } from '../core/models/clone-object-request.model';
import { FileModel, FileUpdateModel } from './file.model';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private objectFilesSubject = new Subject<any>();
  objectFiles$ = this.objectFilesSubject.asObservable();

  private filesSubject = new Subject<FileList>();
  files$ = this.filesSubject.asObservable();

  constructor(private dataContext: DataContextService) {}

  readFilesData(data: any) {
    this.objectFilesSubject.next(data);
  }

  readFiles(files: FileList) {
    this.filesSubject.next(files);
  }

  downloadFile(extension, mimeType, fileOutput, fileName) {
    const file = new Blob(['\ufeff', fileOutput], {
      type: `${mimeType};charset=utf-8`,
    });
    const filename = fileName + '.' + extension;

    const a = document.createElement('a');
    document.body.appendChild(a);

    const fileURL = window.URL.createObjectURL(file);

    a.href = fileURL;
    a.download = filename;
    a.click();
  }

  base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  getFiles(
    objectId: string,
    objectType: string,
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<FileModel[]>> {
    return this.dataContext.get<FileModel[]>(
      `files/${objectType}/${objectId}?pindex=${pageIndex}&psize=${pageSize}`
    );
  }

  getFilesForObjects(
    objectIds: string[],
    objectType: string
  ): Observable<ResultWrapper<FileModel[]>> {
    return this.dataContext.post<FileModel[]>(
      `files/${objectType}/multiple`,
      objectIds
    );
  }

  getFileById(id: number): Observable<ResultWrapper<FileModel>> {
    return this.dataContext.get<FileModel>(`files/${id}`);
  }

  deleteFile(id: number): Observable<ResultWrapper<FileModel>> {
    return this.dataContext.delete<FileModel>(`files/${id}`);
  }

  addFile(file: FileModel): Observable<ResultWrapper<FileModel>> {
    const formData = new FormData();

    formData.append('description', file.description);
    formData.append('filePurpose', file.filePurpose);
    formData.append('file', file.file, file.name);
    formData.append('fileName', file.name);
    formData.append('size', file.file.size.toString());
    formData.append(
      'isPrivate',
      file.isPrivate ? file.isPrivate.toString() : 'false'
    );

    formData.append('objectFile', JSON.stringify(file.objectFile));

    return this.dataContext.post<FileModel>(`files/upload`, formData);
  }

  zipFiles(
    objectType: string,
    objectId: string,
    archive?: string
  ): Observable<any> {
    const target: string = archive ? `?target=${archive}.zip` : '';

    return this.dataContext.download(
      `files/zip/${objectType}/${objectId + target}`
    );
  }

  updateFile(file: FileUpdateModel): Observable<ResultWrapper<FileModel>> {
    return this.dataContext.put<FileModel>(`files`, file);
  }

  updateObjectFile(file: FileModel): Observable<ResultWrapper<FileModel>> {
    return this.dataContext.put<FileModel>(`files/objects`, file);
  }

  clone(clonedObjectParams: CloneLinkedObjectsRequest) {
    return this.dataContext.post<FileModel>('files/clone', clonedObjectParams);
  }

  getFileTypeIcon(contentTypeCode: string) {
    if (!contentTypeCode || contentTypeCode === '') {
      return 'fa-file-export';
    }

    switch (contentTypeCode.toLowerCase()) {
      case 'audio':
      case 'mp3':
      case 'wav':
        return 'fa-file-audio';
      case 'code':
      case 'exact-xml':
      case 'javascript':
      case 'xml':
        return 'fa-file-code';
      case 'csv':
        return 'fa-file-csv';
      case 'excel':
        return 'fa-file-excel';
      case 'gif':
      case 'image':
      case 'jpeg':
      case 'jpg':
      case 'png':
        return 'fa-file-image';
      case 'pdf':
        return 'fa-file-pdf';
      case 'powerpoint':
        return 'fa-file-powerpoint';
      case 'avi':
      case 'mpeg':
      case 'flv':
      case 'wmv':
        return 'fa-file-video';
      case 'word':
        return 'fa-file-word';
      case 'rar':
      case 'zip':
        return 'fa-file-archive';
      // Default file types
      default:
        return 'fa-file-export';
    }
  }
}
