import { LanguageCode } from 'iso-639-1';
import { ContentDistributionRecipientModel } from './content-distribution-recipient.model';
import {
  ExportTemplatePreviewStates,
  VisibilityOption,
} from './export-template.model';

export class ExportOptionModel {
  mappingKey: string;
  options: ExportOptionItemModel[];
}

export class ExportOptionItemModel {
  documentType: {
    distributionService: string;
    id: number;
    name: string;
    systemCode: string;
    requiresRecipients: boolean;
  };
  previewState?: ExportTemplatePreviewStates;
  recipients?: ContentDistributionRecipientModel[];
  templateId: number;
  templateName: string;
  templateSystemCode: string;
  isGeneric?: boolean;
  isPublic: boolean;
  supportsMultiple: boolean;
  language?: LanguageCode;
  optionType?: OptionType;
  enableStore?: boolean;
  subject?: string;
  body?: string;
  combine?: boolean;
  visibilityCondition?: VisibilityOption;
}

export enum OptionType {
  Export,
  Send,
  Store,
}
