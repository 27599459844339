import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IdentityServiceBase } from './identity.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  private _identityService = inject(IdentityServiceBase);
  private _router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const permissions = route.data['allowedPermissions'];
    const url = route.data['redirect'];
    return of(this.hasPermission(permissions, url));
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }

  hasPermission(permissions: any, url?: string): boolean {
    if (permissions && !this._identityService.hasPermission(permissions)) {
      if (!url) {
        this._router.navigate(['not-authorized']);
      } else {
        this._router.navigate([url]);
      }
      return false;
    } else {
      return true;
    }
  }
}
