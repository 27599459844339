/* eslint-disable no-case-declarations */
import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import {
  CompanyDataService,
  CustomDataBaseModel,
  CustomDataContentService,
  CustomDataSearchFieldModel,
  DataObjectKeyModel,
  FinancialDataService,
  HarbourDataService,
  InvoiceConstants,
  InvoiceDataService,
  InvoiceServerSearchModel,
  NauticalCountryDataService,
  NauticalPortDataService,
  NauticalShipDataService,
  NauticalVisitDataService,
  PortWaypointDataService,
  SearchOperators,
  ShipSearchModel,
} from '@seahorse/domain';
import { Observable, EMPTY, zip } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LinkedObjectService {
  constructor(
    private _companyData: CompanyDataService,
    private _customData: CustomDataContentService,
    private _invoiceData: InvoiceDataService,
    private _nauticalCountryDataService: NauticalCountryDataService,
    private _nauticalPortDataService: NauticalPortDataService,
    private _nauticalShipData: NauticalShipDataService,
    private _nauticalVisitData: NauticalVisitDataService,
    private _harbourData: HarbourDataService,
    private _portWaypointDataService: PortWaypointDataService,
    private _financialData: FinancialDataService
  ) {}

  getByKey(
    linkedObjectSystemCode: string,
    mappingKey: string,
    foreignKey: string,
    identityValue: any
  ): Observable<ResultWrapper<CustomDataBaseModel>> {
    return this.getByKeys(linkedObjectSystemCode, mappingKey, foreignKey, [
      identityValue,
    ]).pipe(map(this._responseFromArray));
  }

  getByKeys(
    linkedObjectSystemCode: string,
    mappingKey: string,
    foreignKey: string,
    identityValue: (number | string)[]
  ): Observable<ResultWrapper<CustomDataBaseModel[]>> {
    if (!mappingKey) {
      return EMPTY;
    }

    switch (mappingKey) {
      case '$companies_company':
        const companies$ = identityValue.map((value) =>
          this._companyData.getById(value as number, true)
        );
        return zip(...companies$).pipe(map((res) => this._mergeResponses(res)));
      case '$companies_financial':
        const financials$ = identityValue.map((value) =>
          this._financialData.getById(value as number, true)
        );
        return zip(...financials$).pipe(
          map((res) => this._mergeResponses(res))
        );
      case InvoiceConstants.OBJECTTYPEID:
        const invoices$ = identityValue.map((value) =>
          this._invoiceData.getInvoice(value as number, null, true)
        );
        return zip(...invoices$).pipe(map((res) => this._mergeResponses(res)));
      case '$nautical_ship':
        return this._nauticalShipData.getShipsByIdStrings(
          identityValue as string[],
          true
        ) as unknown as Observable<ResultWrapper<CustomDataBaseModel[]>>;
      case '$nautical_portvisit':
        return this._nauticalVisitData.getByIds(
          identityValue as number[],
          undefined,
          undefined,
          undefined,
          true
        ) as unknown as Observable<ResultWrapper<CustomDataBaseModel[]>>;
      case '$nautical_harbour':
        const harbours$ = identityValue.map((value) =>
          this._harbourData.getById(value as number, true)
        );
        return zip(...harbours$).pipe(map((res) => this._mergeResponses(res)));
      case '$nautical_country':
        const countries$ = identityValue.map((value) =>
          this._nauticalCountryDataService.getById(value as number, true)
        );
        return zip(...countries$).pipe(
          map((res) => this._mergeResponses(res))
        );
      case '$nautical_port':
        const ports$ = identityValue.map((value) =>
          this._nauticalPortDataService.getById(value as number, true)
        );
        return zip(...ports$).pipe(
          map((res) => this._mergeResponses(res))
        );
      case '$nautical_portwaypoint':
        const portWaypoints$ = identityValue.map((value) =>
          this._portWaypointDataService.getById(value as number, true)
        );
        return zip(...portWaypoints$).pipe(
          map((res) => this._mergeResponses(res))
        );
      default:
        // e.g. invoice.number
        if (mappingKey.startsWith('$invoicing_invoice')) {
          // filterByField = true;
          // this._invoiceData.search(identityKey, 0, 50);
        } else if (foreignKey === '__Id' || !foreignKey) {
          return this._customData.getCustomerDataByIds(
            linkedObjectSystemCode,
            identityValue as string[]
          );
        } else {
          const search = {
            fieldName: foreignKey,
            searchOperator: SearchOperators.Equals,
            searchValue: identityValue,
          } as CustomDataSearchFieldModel;
          return this._customData.searchActiveCustomerDataByFields(
            linkedObjectSystemCode,
            [search],
            null,
            null,
            0,
            10
          );
        }
        return EMPTY;
    }
  }

  search(
    linkedObjectSystemCode: string,
    displayFields: string[],
    mappingKey: string,
    query: string,
    pageIndex: number,
    pageSize: number,
    tag: string
  ): Observable<ResultWrapper<any[]>> {
    if (!mappingKey) {
      return EMPTY;
    }

    switch (mappingKey) {
      case '$companies_company':
        return this._companyData.search(query, pageIndex, pageSize, true, tag);
      case '$companies_financial':
        return this._financialData.search(query, pageIndex, pageSize, true);
      case '$invoicing_invoice':
        const invoiceSearch = new InvoiceServerSearchModel(
          query,
          pageIndex,
          pageSize
        );
        invoiceSearch.annotate = true;
        return this._invoiceData.search(invoiceSearch);
      case '$nautical_ship':
        const shipSearch = new ShipSearchModel(query);
        return this._nauticalShipData.find(
          shipSearch,
          pageIndex,
          pageSize,
          null,
          true,
          true
        );
      case '$nautical_portvisit':
        return this._nauticalVisitData.find(
          query,
          null,
          null,
          pageIndex,
          pageSize,
          true
        );
      case '$nautical_harbour':
        return this._harbourData.search(query, pageIndex, pageSize, true);
      case '$nautical_country':
        return this._nauticalCountryDataService.search(query, pageIndex, pageSize, true);
      case '$nautical_portwaypoint':
        return this._portWaypointDataService.search(
          query,
          pageIndex,
          pageSize,
          true
        );
      case '$nautical_port':
        return this._nauticalPortDataService.getByName(query, pageIndex, pageSize, true);
      default:
        // default get custom data
        let searchFields;
        if (displayFields) {
          searchFields = displayFields.map(
            (field) =>
              ({
                fieldName: field,
                searchOperator: SearchOperators.ContainsAny,
                searchValue: query,
              } as CustomDataSearchFieldModel)
          );
        }

        let scope = 'custom';
        try {
          const keyModel = DataObjectKeyModel.fromKey(
            mappingKey.split('_').pop()
          );
          scope = keyModel.Scope;
          // eslint-disable-next-line no-empty
        } catch (error) {}

        return this._customData.searchActiveCustomerDataByFieldsByScope(
          scope,
          linkedObjectSystemCode,
          searchFields ? searchFields : null,
          null,
          null,
          pageIndex,
          pageSize
        );
    }
  }

  private _responseFromArray = (
    response: ResultWrapper<CustomDataBaseModel[]>
  ) => {
    return {
      count: response.count,
      hasResult: response.hasResult,
      messages: response.messages,
      result: response.hasResult ? response.result[0] : [],
      status: response.status,
    } as ResultWrapper<CustomDataBaseModel>;
  };

  private _mergeResponses = (responses: ResultWrapper<any>[]) => {
    return {
      count: responses.length,
      hasResult: responses.some((response) => response.hasResult),
      messages: responses.reduce((acc, cur) => acc.concat(cur.messages), []),
      result: responses.reduce((acc, cur) => acc.concat(cur.result), []),
      status: 1,
    } as ResultWrapper<CustomDataBaseModel[]>;
  };
}
