import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';

import { CompanyModel } from '@seahorse/domain';

@Component({
  selector: 'ca-company-list',
  templateUrl: './company-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyListComponent {
  selectedCompany: CompanyModel = null;
  @Input() companies: CompanyModel[] = [];
  @Output() onCompanySelected = new EventEmitter<CompanyModel>();

  selectCompany(company: CompanyModel) {
    this.selectedCompany = company;
    this.onCompanySelected.emit(company);
  }
}
