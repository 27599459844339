import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ControlTemplate } from './controls/control.template';
import { PasswordControl } from './controls/password/password.control';
import { TextInputControl } from './controls/text-input/text-input.control';
import {
  ComboBoxModule,
  DropDownListModule,
  MultiSelectModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { DropDownListControl } from './controls/drop-down-list/drop-down-list.control';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { CheckboxControl } from './controls/checkbox/checkbox.control';
import { MultiSelectControl } from './controls/multi-select/multi-select.control';
import { TranslateModule } from '@ngx-translate/core';
import { ComboBoxControl } from './controls/combo-box/combo-box.control';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // There still seems to be an issue with the SeahorseTranslateModule breaking the translation.
    // Using TranslateModule for now
    // SeahorseTranslateModule.forChild('assets/forms/i18n'),
    TranslateModule,
    // SyncFusion
    TextBoxModule,
    DropDownListModule,
    CheckBoxModule,
    MultiSelectModule,
    ComboBoxModule,
  ],
  declarations: [
    ControlTemplate,
    TextInputControl,
    PasswordControl,
    DropDownListControl,
    CheckboxControl,
    MultiSelectControl,
    ComboBoxControl,
  ],
  exports: [
    TextInputControl,
    PasswordControl,
    DropDownListControl,
    CheckboxControl,
    MultiSelectControl,
    ComboBoxControl,
  ],
})
export class SeahorseFormsModule {}
