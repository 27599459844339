import { PipeModel } from '@seahorse/common';
import {
  BaseFieldRule,
  FieldDefinitionModel,
  FieldMode,
  FieldType,
} from '@seahorse/domain';
import { BadgeModel } from '@seahorse/ui';

export interface DataField<TData = any> {
  key: ItemKey<TData>;
  name: string;
  type: FieldType;
  order?: number;
  pipeModels?: PipeModel[];
  badge?: Record<string, BadgeModel>;
  appendText?: string;
  displayFields?: string[];
  showCopyButton?: boolean;
  mode?: FieldMode
}

export abstract class DataField {
  static fromFieldDefinition(
    field: FieldDefinitionModel,
    useFieldSort = true
  ): DataField {
    const pipeModels: PipeModel[] = [];
    const badge = {};
    const mode = FieldDefinitionModel.getAdditionalData(field)?.mode;

    if (field.formatting && field.formatting['decimalPipe']) {
      pipeModels.push(
        new PipeModel('decimalPipe', {
          format: field.formatting['decimalPipe'],
        })
      );
    }

    if (
      [FieldType.LinkedObject, FieldType.LinkedObjects].includes(
        field.fieldType
      )
    ) {
      pipeModels.push(
        new PipeModel('tempLinkedObjects', { systemCode: field.systemCode })
      );
    } else if (field.fieldType === FieldType.List) {
      pipeModels.push(
        new PipeModel('tempCustomListDisplay', {
          items: field.fieldRule['items'],
        })
      );

      BaseFieldRule.list(field.fieldRule).items.forEach((item) => {
        if (item.color) {
          badge[item.key] = {
            color: item.color,
            mode: mode?.display,
          };
        }
      });
    }

    if (field.fieldCode === 'user') {
      pipeModels.push(new PipeModel('shUserDisplay'));
    }

    return {
      key: field.systemCode,
      name: field.name,
      type: field.fieldType,
      order: useFieldSort ? field.sortOrder : null,
      pipeModels: pipeModels.length ? pipeModels : undefined,
      badge: Object.keys(badge).length ? badge : undefined,
      appendText: field.fieldRule?.['valueDescriptor'],
      mode: mode
    };
  }
}

export type ItemKey<T> = keyof T;

export class DataListAction {
  key: string;
  name: string;
  icon?: string;
}
