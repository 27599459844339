<ng-container *shTemplate>
  <div *ngIf="title" class="modal-header align-items-center">
    <h1 class="modal-title">{{ title }}</h1>
    <button type="button" class="btn btn-link text-dark" (click)="cancel()">
      <i class="fa-solid fa-times fa-sm fa-fw"></i>
    </button>
  </div>
  <div [class.modal-body]="!isTable">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer" *ngIf="!hideActions">
    <button
      type="button"
      class="btn btn-link btn-block w-50"
      (click)="cancel()"
    >
      {{ 'shared.terms.cancel' | translate }}
    </button>
    <button
      (click)="confirm()"
      [disabled]="disabled"
      type="button"
      class="btn btn-success btn-block w-50"
    >
      {{ 'shared.terms.confirm' | translate }}
    </button>
  </div>
</ng-container>
