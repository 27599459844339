<table class="table table-clickable table-fixed-header">
  <thead>
    <tr>
      <th>{{ 'companies.company.model.name' | translate }}</th>
      <th>{{ 'companies.company.model.number' | translate }}</th>
      <th>{{ 'companies.company.model.types' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let company of companies"
      (click)="selectCompany(company)"
      [ngClass]="{
        selected: selectedCompany && selectedCompany.id === company.id
      }"
    >
      <td>{{ company.name }}</td>
      <td>{{ company.number }}</td>
      <td>
        <ul class="list-inline list-inline-seperated">
          <li
            class="list-inline-item"
            *ngFor="let companyType of company.companyTypes"
          >
            {{ companyType.name }}
          </li>
        </ul>
      </td>
    </tr>
  </tbody>
</table>
