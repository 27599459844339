import { CurrencyPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  ErrorMessage,
  GroupOption,
  KeyValuePair,
  NotificationService,
  PipeModel,
  ResultWrapper
} from '@seahorse/common';
import {
  CustomDataBaseModel,
  CustomDataObjectModel,
  CustomDataSearchFieldModel,
  CustomDefaultDataModel,
  ExpenseModel,
  FieldType,
  FileModel,
  FileService,
  InvoiceDataService,
  InvoiceMetaDataModel,
  InvoiceModel,
  ObjectDefinitionModel,
  OrderRegistrationModel,
  SearchOperators,
  TariffAmountTypes
} from '@seahorse/domain';
import {
  BaseFileListCardService, expensesUpdateModalProcedure
} from '@seahorse/temp';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import {
  ContentMapObjectModel,
  ContentMappingModel
} from '../../../content-mapping/models/content-mapping.model';
import { ContentMappingDataService } from '../../../content-mapping/services/content-mapping-data.service';
import { IdentityService } from '../../../core/services/identity.service';
import { ProxyServices } from '../../../core/services/proxy.service';
import { CustomDefaultDataModalComponent } from '../../../custom-data/components/custom-default-data-modal/custom-default-data-modal.component';
import { CustomDefaultDataService } from '../../../custom-data/services/custom-default-data.service';
import { ExpensesDataService } from '@seahorse/domain';
import { InvoiceCreatorByCustomDataModalComponent } from '../../../invoicing-and-tariffs/modules/invoicing/components/invoice-creator-by-custom-data-modal/invoice-creator-by-custom-data-modal.component';
import { TableColumnModel } from '../../../layout/models/table.model';
import { OrderRegistrationsDataService } from '../../../order-registrations/services/order-registrations-data.service';
import { EventModel } from '../../../shared/models/event.model';
import {
  ExpenseConstant,
  ExpenseGroupOptions
} from '@seahorse/domain';
import { ExpenseType } from '../../models/expense-types.enum';
import { ExpenseHelpers } from '../../models/expense.helpers';
import { ExpenseDialogComponent } from '../expense-dialog/expense-dialog.component';

@Component({
  selector: 'ca-expenses-list',
  templateUrl: './expenses-list.component.html',
})
export class ExpensesListComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() columns: TableColumnModel[];
  @Input() expenseModel: ExpenseModel;
  @Input() expenseType: ExpenseType;
  @Input() orderRegistrations: OrderRegistrationModel[];
  @Input() sortBy: keyof ExpenseModel;
  @Input() skipService: boolean;
  @Input() hideTariffPicker: boolean;
  @Input() hideCommissionFee: boolean;
  @Input() hideOrderRegistrationPicker: boolean;
  @Input() loading: boolean;
  @Input() defaultExpenseData: CustomDefaultDataModel;
  @Input() showCustomDefaultDataPicker: boolean;

  // when this property is set, the expenses will be loaded by this parent
  @Input() set parent(parent: CustomDataBaseModel) {
    this._parent = parent;
    this.loadData();
  }

  get parent(): CustomDataBaseModel {
    return this._parent;
  }

  @Input() parentMappingKey?: string;

  @Input() set expenses(data: ExpenseModel[]) {
    this._expenses = this.sortExpenses(
      _.map(data, (item) => {
        if (item.commissionFee !== null && item.commissionFee !== undefined) {
          if (item.commissionFeeType === TariffAmountTypes.Number) {
            item['commissionFeeDisplay'] = new CurrencyPipe('nl-NL').transform(
              item.commissionFee,
              'EUR',
              'symbol-narrow',
              '1.2-2'
            );
          } else if (item.commissionFeeType === TariffAmountTypes.Percentage) {
            if (item.amountActual >= 0) {
              item['commissionFeeDisplay'] =
                new CurrencyPipe('nl-NL').transform(
                  (item.amountActual * item.commissionFee) / 100,
                  'EUR',
                  'symbol-narrow',
                  '1.2-2'
                ) +
                ' (' +
                item.commissionFee +
                '%)';
            } else {
              item['commissionFeeDisplay'] = item.commissionFee + '%';
            }
          }
        }

        return item;
      })
    );

    this.filterExpenses();
  }

  get expenses(): ExpenseModel[] {
    return this._expenses;
  }

  @Input() set showTotals(showTotals: boolean) {
    this._showTotals = showTotals;
  }
  get showTotals(): boolean {
    return this._showTotals;
  }

  @Output() expensesChange!: EventEmitter<ExpenseModel[]>;

  private _expenses: ExpenseModel[];
  private _parent: CustomDataBaseModel = null;
  private _showTotals: boolean = null;
  private _subscriptions = new Subscription();

  expenseDefinition: ObjectDefinitionModel;
  expensesTotals: KeyValuePair<keyof ExpenseModel, string>[];
  filteredExpenses: ExpenseModel[];
  selectedCustomer: ExpenseModel['customer'];
  iModelId: number;
  defaultDataGroup: CustomDefaultDataModel;
  loadingCustomDefaultDataSet: boolean;

  set includeCharged(value: boolean) {
    this._includeCharged = value;
    this.filterExpenses();
  }

  get includeCharged(): boolean {
    return this._includeCharged;
  }

  private _includeCharged = false;
  private _openExpensesUpdateModal = expensesUpdateModalProcedure(false);

  constructor(
    private modal: NgbModal,
    private translate: TranslateService,
    private proxy: ProxyServices,
    private _customDefaultDataService: CustomDefaultDataService,
    private _expensesDataService: ExpensesDataService,
    private _invoiceDataService: InvoiceDataService,
    private _notificationService: NotificationService,
    private _filesService: FileService,
    private _orderRegistrationsData: OrderRegistrationsDataService,
    private _fileCardService: BaseFileListCardService,
    private _identityService: IdentityService,
    private _contentMappingDataService: ContentMappingDataService
  ) {
    this.title = this.translate.instant(
      'customModules.expenses.expensesListTitle'
    );
    this.columns = [];
    this.expenseDefinition = null;
    this.expenseModel = null;
    this.expenseType = ExpenseType.Default;
    this.orderRegistrations = [];
    this.sortBy = '__CreatedOn';
    this.skipService = false;
    this.hideTariffPicker = false;
    this.hideCommissionFee = false;
    this.hideOrderRegistrationPicker = false;
    this.loading = false;
    this.expensesChange = new EventEmitter<ExpenseModel[]>();
    this.expenses = [];
    this._expenses = [];
    this.expensesTotals = [];
    this.filteredExpenses = [];
    this.selectedCustomer = null;
    this.iModelId = 0;
    this.loadingCustomDefaultDataSet = false;

    if (this._showTotals === undefined || this._showTotals === null) {
      this._showTotals = true;
    }

    this._subscriptions.add(
      this._filesService.files$.subscribe((files) => this.onFileUploaded(files))
    );
  }

  private onFileUploaded(files: FileList) {
    this._openExpensesUpdateModal(this.expenses).subscribe(
      (updatedExpenses) => {
        if (!updatedExpenses) {
          return;
        }

        this.expenses = this.expenses.map((expense) => {
          const updatedExpense = updatedExpenses.find(
            (x) => x.__Id === expense.__Id
          );

          if (updatedExpense) {
            return updatedExpense;
          }

          return expense;
        });

        this.addFilesToObjects(files, updatedExpenses);
      }
    );
  }

  private addFilesToObjects(files: FileList, objects: ExpenseModel[]) {
    for (let i = 0; i < files.length; i++) {
      const file = FileModel.fromHtmlInput(files[i]);
      const parentId = this.parent?.__Id;

      file.objectFile = [
        {
          objectId: parentId,
          objectType: this.parentMappingKey,
        },
        ...objects.map((x) => ({
          objectId: x.__Id,
          objectType: ExpenseConstant.OBJECTTYPEID,
        })),
      ];

      this._filesService.addFile(file).subscribe((res) => {
        if (!res.hasResult) {
          this._notificationService.showError(
            res.messages.map((x) => x.message).join('\n')
          );
        }

        this._notificationService.showSuccess('files.fileAdded');
        this._fileCardService.reload();
      });
    }
  }

  ngOnInit() {
    if (!this.columns || !this.columns.length) {
      const amountColumn = new TableColumnModel(
        'amount',
        FieldType.Decimal,
        this.translate.instant('customModules.expenses.model.amount'),
        [new PipeModel('currency')]
      );
      amountColumn.width = '125px';

      const actualColumn = new TableColumnModel(
        'amountActual',
        FieldType.Decimal,
        this.translate.instant('customModules.expenses.model.amountActual'),
        [new PipeModel('currency')]
      );
      actualColumn.width = '125px';

      const commissionFeeColumn = new TableColumnModel(
        'commissionFeeDisplay',
        FieldType.SingleLineText,
        this.translate.instant('customModules.expenses.model.commissionFee')
      );
      commissionFeeColumn.width = '125px';

      const orderColumn = new TableColumnModel(
        'orderRegistration',
        FieldType.LinkedObject,
        this.translate.instant('customModules.expenses.model.orderRegistration')
      );
      orderColumn.width = '125px';

      const quantityColumn = new TableColumnModel(
        'quantity',
        FieldType.Integer,
        this.translate.instant('customModules.expenses.model.quantity')
      );
      quantityColumn.width = '85px';

      const supplierColumn = new TableColumnModel(
        'supplier',
        FieldType.LinkedObject,
        this.translate.instant('customModules.expenses.model.supplier'),
        null,
        null,
        null,
        null,
        true
      );
      supplierColumn.width = '150px';

      const descriptionColumn = new TableColumnModel(
        'customer',
        FieldType.LinkedObject,
        this.translate.instant('customModules.expenses.model.customer'),
        null,
        null,
        null,
        null,
        true
      );
      descriptionColumn.width = '150px';

      const remarksColumn = new TableColumnModel(
        'remarks',
        FieldType.SingleLineText,
        this.translate.instant('customModules.expenses.model.remarks')
      );
      remarksColumn.width = '150px';

      switch (this.expenseType) {
        case ExpenseType.OrderRegistration:
          {
            this.columns.push(
              // new TableColumnModel('quantity', FieldType.Integer, this.translate.instant('customModules.expenses.model.quantity')),
              new TableColumnModel(
                'description',
                FieldType.MultiLineText,
                this.translate.instant('shared.terms.description'),
                null,
                null,
                null,
                null,
                true
              ),
              remarksColumn,
              amountColumn
            );

            if (!this.hideCommissionFee) {
              this.columns.push(commissionFeeColumn);
            }
          }
          break;

        case ExpenseType.PortCallFile:
          {
            this.columns.push(
              orderColumn,
              new TableColumnModel(
                'description',
                FieldType.MultiLineText,
                this.translate.instant('shared.terms.description'),
                null,
                null,
                null,
                null,
                true
              ),
              supplierColumn,
              descriptionColumn,
              remarksColumn,
              quantityColumn,
              amountColumn,
              actualColumn
            );

            if (!this.hideCommissionFee) {
              this.columns.push(commissionFeeColumn);
            }
          }
          break;

        default:
          {
            this.columns.push(
              // new TableColumnModel('quantity', FieldType.Integer, this.translate.instant('customModules.expenses.model.quantity')),
              new TableColumnModel(
                'description',
                FieldType.MultiLineText,
                this.translate.instant('shared.terms.description'),
                null,
                null,
                null,
                null,
                true
              ),
              remarksColumn,
              amountColumn,
              actualColumn
            );

            if (!this.hideCommissionFee) {
              this.columns.push(commissionFeeColumn);
            }
          }
          break;
      }
      if (
        this.showCustomDefaultDataPicker &&
        this.expenseType === ExpenseType.PortCallFile
      ) {
        this.loadingCustomDefaultDataSet = true;
        this._customDefaultDataService
          .getDefaultDataGroups('st-portcallfile')
          .subscribe(
            (response) => {
              const errorMessages: (string | ErrorMessage)[] = response.messages
                ? response.messages
                : [];
              if (response.hasResult) {
                this.defaultDataGroup = response.result.find(
                  (x) => x.systemCode === 'default_expenses'
                );
              }

              if (errorMessages && errorMessages.length) {
                this._notificationService.displayErrorNotification(
                  errorMessages
                );
              }
            },

            () => this._notificationService.displayErrorNotification(),
            () => (this.loadingCustomDefaultDataSet = false)
          );
      }
    }

    this.expenseDefinition = _.find(
      this.proxy.objectDefinitions,
      (x) => x.systemCode === 'expense'
    );
    const defaults = _.filter(
      ObjectDefinitionModel.getAllFieldDefinitions(this.expenseDefinition),
      (x) => !_.isNull(x.defaultValue)
    );

    if (!this.expenseModel) {
      this.expenseModel = new ExpenseModel(
        _.extend(
          _.object(
            _.pluck(defaults, 'systemCode'),
            _.pluck(defaults, 'defaultValue')
          )
        )
      );
    }
  }

  filterExpenses() {
    this.filteredExpenses = [...this.expenses].filter((x) => {
      let returnedExpense = true;
      if (this.selectedCustomer)
        returnedExpense = x.customer === this.selectedCustomer;
      if (returnedExpense && !this.includeCharged)
        returnedExpense = x.charged !== true;
      return returnedExpense;
    });

    this.calculateTotals();
  }

  loadData() {
    if (this.parent && this.parent.__DataObjectKey) {
      if (
        this.parent.__DataObjectKey
          .toLocaleLowerCase()
          .indexOf('ct-portcallfile') === 0
      ) {
        this.loadOrders(this.parent.__Id, 'portCallFile');

        this.loadExpenses(ExpenseType.PortCallFile);
      } else if (
        this.parent.__DataObjectKey
          .toLocaleLowerCase()
          .indexOf('ct-orderregistration') === 0
      ) {
        this.loadExpenses(ExpenseType.OrderRegistration);
      }
    }
  }

  loadExpenses(expenseType: ExpenseType) {
    const defaults = _.filter(
      ObjectDefinitionModel.getAllFieldDefinitions(
        _.find(this.proxy.objectDefinitions, (x) => x.systemCode === 'expense')
      ),
      (x) => !_.isNull(x.defaultValue)
    );

    this.loading = true;
    this.expenseType = expenseType;

    (this.expenseType === ExpenseType.PortCallFile
      ? this._expensesDataService.getByPortCallFile(this.parent.__Id, [])
      : this._expensesDataService.getByOrderRegistration(this.parent.__Id, [])
    ).subscribe(
      (r) => {
        if (r.hasResult) {
          this.expenses = r.result;
        } else {
          this._notificationService.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },

      (e) => {
        this._notificationService.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },

      () => {
        this.loading = false;
      }
    );

    if (this.expenseType === ExpenseType.PortCallFile) {
      this.expenseModel = new ExpenseModel(
        _.extend(
          _.object(
            _.pluck(defaults, 'systemCode'),
            _.pluck(defaults, 'defaultValue')
          ),
          { portCallFile: this.parent.__Id }
        )
      );
    }
  }

  loadOrders(id: string, fieldName: string) {
    const search = new CustomDataSearchFieldModel();
    search.fieldName = fieldName;
    search.searchOperator = SearchOperators.Equals;
    search.searchValue = id;

    this.orderRegistrations = [];

    this._orderRegistrationsData
      .search(search, null, null, 0, 500, 'po', 'asc', [])
      .subscribe(
        (r: ResultWrapper<OrderRegistrationModel[]>) => {
          if (r.hasResult) {
            this.orderRegistrations = r.result;
          } else {
            this._notificationService.showError(
              _.pluck(r.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          }
        },
        (e) => {
          this._notificationService.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      );
  }

  openExpenseDialog(expense?: ExpenseModel) {
    const expenseModel = expense
      ? _.clone(expense)
      : _.clone(this.expenseModel);

    const isNewExpense = !expense;
    const organization = this._identityService.identity.organisationName;

    if (isNewExpense) {
      if (organization?.toLowerCase() !== 'seamar') {
        expenseModel.customer =
          this.expenseModel.customer ?? this.parent?.['customer'] ?? null;
        expenseModel.supplier =
          this.expenseModel.supplier ?? this.parent?.['supplier'] ?? null;
      } else {
        expenseModel.customer = null;
        expenseModel.supplier = null;
      }
    }

    if (this.skipService && !expenseModel.__Id) {
      expenseModel.__Id = this.iModelId.toString();
      this.iModelId++;
    }

    const modal = this.modal.open(ExpenseDialogComponent, {
      backdrop: 'static',
    });
    {
      modal.componentInstance.expense = expenseModel;
      modal.componentInstance.newExpense = isNewExpense;
      modal.componentInstance.orderRegistrations = this.orderRegistrations;
      modal.componentInstance.skipService = this.skipService;
      modal.componentInstance.hideTariffPicker = this.hideTariffPicker;
      modal.componentInstance.hideCommissionFee = this.hideCommissionFee;
      modal.componentInstance.hideOrderRegistrationPicker =
        this.hideOrderRegistrationPicker;
    }

    modal.result.then((event: EventModel) => {
      if (event.data) {
        let newExpenses: ExpenseModel[] = [];

        switch (event.action) {
          case 'added':
          case 'addPromise':
            newExpenses = _.clone(this.expenses).concat(event.data);
            break;

          case 'updated':
          case 'updatePromise':
            newExpenses = _.reject(
              _.clone(this.expenses),
              (x) => x.__Id === event.data.__Id
            ).concat(event.data);
            break;

          case 'deleted':
          case 'deletePromise':
            newExpenses = _.reject(
              _.clone(this.expenses),
              (x) => x.__Id === event.data.__Id
            );
            break;
        }

        this.expenses = newExpenses;
        this.expensesChange.emit(this.expenses);
      }
    });
  }

  openDefaultExpensesDialog() {
    const modal = this.modal.open(CustomDefaultDataModalComponent, {
      backdrop: 'static',
    });

    modal.componentInstance.defaultDataGroup = this.defaultDataGroup;
    modal.componentInstance.hideDefaultDataGroupPicker = true;

    modal.result.then((defaultDataObjects: CustomDataObjectModel[]) => {
      if (defaultDataObjects) {
        const defaultExpenses: ExpenseModel[] = [];
        defaultDataObjects.forEach((x) => {
          const defaultExpense = Object.assign(
            _.clone(this.expenseModel),
            x.fields
          );
          if (this.expenseModel.portCallFile) {
            defaultExpense.portCallFile = this.expenseModel.portCallFile;
          }

          if (this.expenseModel.orderRegistration) {
            defaultExpense.orderRegistration =
              this.expenseModel.orderRegistration;
          }

          if (this.expenseModel.supplier) {
            defaultExpense.supplier = this.expenseModel.supplier;
          }

          if (this.expenseModel.customer) {
            defaultExpense.supplier = this.expenseModel.customer;
          }

          if (this.expenseModel.tariff) {
            defaultExpense.tariff = this.expenseModel.tariff;
          }

          if (this.skipService && !defaultExpense.__Id) {
            defaultExpense.__Id = this.iModelId.toString();
            this.iModelId++;
          }

          defaultExpenses.push(defaultExpense);
        });

        let newExpenses = _.clone(this.expenses);

        if (this.skipService) {
          newExpenses = _.clone(this.expenses).concat(defaultExpenses);
          this.expensesChange.emit(this.sortExpenses(newExpenses));
        } else {
          this.loading = true;
          this._expensesDataService.add(defaultExpenses).subscribe(
            (response) => {
              const errorMessages: (string | ErrorMessage)[] = response.messages
                ? response.messages
                : [];
              if (response.hasResult && response.result.length > 0) {
                this._notificationService.showSuccess(
                  this.translate.instant('customModules.expenses.expenseAdded'),
                  this.translate.instant('shared.terms.success')
                );
                newExpenses = _.clone(this.expenses).concat(defaultExpenses);
              }

              if (errorMessages && errorMessages.length) {
                this._notificationService.displayErrorNotification(
                  errorMessages
                );
              }
            },

            () => this._notificationService.displayErrorNotification(),
            () => {
              this.loading = false;
              this.expensesChange.emit(this.sortExpenses(newExpenses));
            }
          );
        }
      }
    });
  }

  openInvoiceDialog() {
    const modal = this.modal.open(InvoiceCreatorByCustomDataModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });
    const newInvoice = new InvoiceModel();
    _.each(ExpenseHelpers.getInvoiceMapping(this.expenseType), (pair) => {
      if (pair.sourceFields && pair.sourceFields.length > 0)
        newInvoice[pair.targetField] = this.parent[pair.sourceFields[0]];
    });
    modal.componentInstance.invoice = newInvoice;
    modal.componentInstance.invoiceLineMappings =
      ExpenseHelpers.getInvoiceLineMapping();
    modal.componentInstance.amountField = 'amountActual';
    modal.componentInstance.approvedField = 'charged';
    modal.componentInstance.customData = this.expenses.map((exp) =>
      Object.assign({}, exp)
    );
    modal.componentInstance.metaDataMappingkey = ExpenseConstant.OBJECTTYPEID;
    modal.componentInstance.companyIdField = 'customer';
    modal.componentInstance.filterText = this.translate.instant(
      'customModules.expenses.showOnlyOpenExpenses'
    );
    modal.componentInstance.filterField = 'charged';
    modal.componentInstance.objectDefinition = this.expenseDefinition;
    modal.componentInstance.selectGroupOptions = [
      new GroupOption(
        this.translate.instant('customModules.expenses.groupOptions.nothing'),
        null,
        null
      ),
      new GroupOption(
        this.translate.instant('customModules.expenses.groupOptions.reference'),
        ExpenseGroupOptions.Reference,
        ['remarks']
      ),
      new GroupOption(
        this.translate.instant('customModules.expenses.groupOptions.supplier'),
        ExpenseGroupOptions.Supplier,
        ['__AdditionalData.supplier.name']
      ),
      new GroupOption(
        this.translate.instant(
          'customModules.expenses.groupOptions.supplierAndReference'
        ),
        ExpenseGroupOptions.SupplierAndReference,
        ['__AdditionalData.supplier.name', 'remarks']
      ),
    ];

    const actualColumn = new TableColumnModel(
      'amountActual',
      FieldType.Decimal,
      this.translate.instant('customModules.expenses.model.amountActual'),
      [new PipeModel('currency')]
    );
    actualColumn.width = '125px';
    modal.componentInstance.customDataColumns = [
      new TableColumnModel(
        'description',
        FieldType.MultiLineText,
        this.translate.instant('shared.terms.description'),
        null,
        null,
        null,
        null,
        true
      ),
      actualColumn,
      new TableColumnModel(
        'tariff',
        FieldType.SingleLineText,
        this.translate.instant('invoicing.tariffGroups.model.tariff'),
        null,
        null,
        null,
        null,
        true
      ),
    ];

    modal.result.then(
      (invoiceResponse: { invoice: InvoiceModel; selectedIds: string[] }) => {
        if (invoiceResponse && invoiceResponse.invoice) {
          const invoiceModel = invoiceResponse.invoice;
          let request;
          if (this.parent !== undefined && this.parent !== null) {
            // HACK until we find a better solution: add the selected expenses to the parent data
            if (
              invoiceResponse.selectedIds &&
              invoiceResponse.selectedIds.length > 0
            ) {
              if (!this.parent['__InvoiceLineSource']) {
                this.parent['__InvoiceLineSource'] = this.expenses.filter(
                  (exp) =>
                    invoiceResponse.selectedIds.find(
                      (id) => id.toLowerCase() === exp.__Id.toLowerCase()
                    )
                );
              }
            }

            request = this._invoiceDataService.addWithSourceObject(
              invoiceModel,
              this.parent
            );
          } else {
            request = this._invoiceDataService.add(invoiceModel);
          }

          request.subscribe((invoiceAddResponse) => {
            if (invoiceAddResponse && invoiceAddResponse.hasResult) {
              this._notificationService.showSuccess(
                this.translate.instant('invoicing.invoiceCreator.added'),
                this.translate.instant('shared.terms.success')
              );

              const updateExpenses: ExpenseModel[] = [];
              _.each(invoiceResponse.selectedIds, (id) => {
                const foundExp = _.find(
                  this.expenses,
                  (exp) => exp.__Id === id
                );
                if (foundExp) {
                  foundExp.charged = true;
                  updateExpenses.push(foundExp);
                }
              });

              this.loading = true;
              this.updateExpenses(updateExpenses);

              const expenseMetaData = invoiceAddResponse.result.metaData.filter(
                (x) =>
                  x.metaKey === '$customcontent_st-expense_id' &&
                  x.invoiceLineId !== 0
              );

              this.addFilesContentMap(expenseMetaData);
            } else {
              this._notificationService.showError(
                this.translate.instant('shared.terms.failed')
              );
            }
          });
        }
      }
    );
  }

  private updateExpenses(updateExpenses: ExpenseModel[]) {
    this._expensesDataService.update(updateExpenses).subscribe(
      (rExpenses) => {
        if (rExpenses.hasResult) {
          this._notificationService.showSuccess(
            this.translate.instant('customModules.expenses.expenseUpdated'),
            this.translate.instant('shared.terms.success')
          );

          this.expenses = this.expenses.map((expense) => {
            const updatedExpense = rExpenses.result.find(
              (x) => x.__Id === expense.__Id
            );

            if (updatedExpense) {
              return updatedExpense;
            }

            return expense;
          });
        } else {
          this._notificationService.showError(
            _.pluck(rExpenses.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
        this.loading = false;
      },
      (eExpenses) => {
        this._notificationService.showError(
          _.pluck(eExpenses.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
        this.loading = false;
      }
    );
  }

  calculateTotals() {
    if (this.showTotals) {
      let amountTotal = 0;
      let amountActualTotal = 0;
      // let commissionFeeTotal = 0;
      let commissionFeeActualTotal = 0;

      _.forEach(this.filteredExpenses, (x) => {
        amountTotal += x.amount;
        amountActualTotal += x.amountActual;
        // commissionFeeTotal += x.commissionFeeType == TariffAmountTypes.Percentage ? (x.amount * x.commissionFee) / 100 : x.commissionFee;
        commissionFeeActualTotal +=
          x.commissionFeeType === TariffAmountTypes.Percentage
            ? (x.amountActual * x.commissionFee) / 100
            : x.commissionFee;
      });

      this.expensesTotals = [
        new KeyValuePair(
          'amount',
          new CurrencyPipe('nl-NL').transform(
            amountTotal,
            'EUR',
            'symbol-narrow',
            '1.2-2'
          )
        ),
        new KeyValuePair(
          'amountActual',
          new CurrencyPipe('nl-NL').transform(
            amountActualTotal,
            'EUR',
            'symbol-narrow',
            '1.2-2'
          )
        ),
        new KeyValuePair(
          'commissionFeeDisplay',
          new CurrencyPipe('nl-NL').transform(
            commissionFeeActualTotal,
            'EUR',
            'symbol-narrow',
            '1.2-2'
          )
        ),
      ];
    }
  }

  private addFilesContentMap(expenseMetaData: InvoiceMetaDataModel[]) {
    if (expenseMetaData?.length === 0) {
      return;
    }

    expenseMetaData.forEach((metaData) => {
      this._subscriptions.add(
        this._filesService
          .getFiles(metaData.metaValue, ExpenseConstant.OBJECTTYPEID, 0, 100)
          .subscribe((res) => {
            if (res.hasResult) {
              res.result.forEach((file) => {
                const contentMappingModel = new ContentMappingModel();

                contentMappingModel.item1 = new ContentMapObjectModel();
                contentMappingModel.item1.key = '$invoicing_invoiceline_id';
                contentMappingModel.item1.value =
                  metaData.invoiceLineId.toString();

                contentMappingModel.item2 = new ContentMapObjectModel();
                contentMappingModel.item2.key = '$files_file_id';
                contentMappingModel.item2.value = file.id.toString();

                contentMappingModel.objectRelationship = 'invoiced';

                this._subscriptions.add(
                  this._contentMappingDataService
                    .addOrUpdate(contentMappingModel)
                    .subscribe()
                );
              });
            }
          })
      );
    });
  }

  sortExpenses(expenses: ExpenseModel[]): ExpenseModel[] {
    const sort = this.skipService ? '__Id' : this.sortBy;

    return _.sortBy(expenses, sort);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
