import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PERMISSIONGROUPS } from '../../../../core/models/permission.model';
import { ResultStatus } from '@seahorse/common';
import { IdentityService } from '../../../../core/services/identity.service';
import { NotificationService } from '@seahorse/common';
import { LayoutDisplayService } from '../../../../layout/services/layout-display.service';
import { PreferenceDataService } from '../../../../preference/services/preference-data.service';
import { UserDataService } from '../../../../user/services/user-data.service';
import { forkJoin } from 'rxjs';
import { OrganisationModel } from 'apps/customer-application/src/app/content-distribution/models/organisation.model';
import { UserAccountService } from '../../services/user-account.service';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'ca-set-permissions',
  templateUrl: './set-permissions.component.html',
})
export class SetPermissionsComponent implements OnInit {
  permissions = PERMISSIONGROUPS;
  isLoading = false;
  seedOrganizations: OrganisationModel[] = [];
  selectedOrganisationId: Guid;

  constructor(
    private layoutDisplay: LayoutDisplayService,
    private userDataService: UserDataService,
    private notificationService: NotificationService,
    private preferenceService: PreferenceDataService,
    private router: Router,
    private translateService: TranslateService,
    private identityService: IdentityService,
    private userAccountService: UserAccountService
  ) {}

  ngOnInit() {
    this.layoutDisplay.currentModuleName = this.translateService.instant(
      'account.register.permissions.title'
    );

    this.userAccountService
      .getDemoOrganisations()
      .subscribe((organisations) => {
        this.seedOrganizations = organisations.result;
      });
  }

  save() {
    this.isLoading = true;

    const permissionNames = this.permissions
      .filter((x) => x.checked)
      .map((x) => x.name);

    const customData = permissionNames.includes('custom_data');
    const invoices = permissionNames.includes('invoices');

    this.userDataService.setPermissions(permissionNames).subscribe((result) => {
      if (result.status === ResultStatus.OK) {
        this.notificationService.showSuccess(
          'Permissions added!',
          'Successfully'
        );

        this.identityService.identity = result.result.user;
        this.identityService.setPermissions(result.result.permissions);

        this.layoutDisplay.reloadNavigation();

        if (customData) {
          if (invoices) {
            const customDataSaveResult = this.savePreference('custom_data');
            const invoicesSaveResult = this.savePreference('invoices');

            forkJoin([customDataSaveResult, invoicesSaveResult]).subscribe(
              (results) => {
                if (
                  results[0].hasResult &&
                  results[0].status === ResultStatus.OK &&
                  results[0].hasResult &&
                  results[0].status === ResultStatus.OK
                ) {
                  this.router.navigate(['onboarding/customdata']);
                  this.isLoading = false;
                } else {
                  this.notificationService.showError(
                    'Something went wrong!',
                    'Failed'
                  );
                  this.isLoading = false;
                }
              }
            );
          } else {
            this.savePreference('custom_data').subscribe((result) => {
              if (result.hasResult && result.status === ResultStatus.OK) {
                this.router.navigate(['onboarding/customdata']);
                this.isLoading = false;
              } else {
                this.notificationService.showError(
                  'Something went wrong!',
                  'Failed'
                );
                this.isLoading = false;
              }
            });
          }
        } else {
          this.notificationService.showSuccess(
            'Onboarding process completed!',
            'Successfully'
          );
          this.router.navigate([
            `../onboarding/create-environment/${this.selectedOrganisationId}`,
          ]);

          this.isLoading = false;
        }
      } else {
        this.notificationService.showError('Something went wrong!', 'Failed');
        this.isLoading = false;
      }
    });
  }

  savePreference(name: string) {
    const fieldValue = {
      hasStep: true,
      finished: false,
    };

    return this.preferenceService.save({
      category: 'permissions',
      fieldType: 1,
      fieldValue: JSON.stringify(fieldValue),
      id: -1,
      name: name,
    });
  }
}
