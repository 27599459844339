import { Injector } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ComponentDescriptor, DynamicsService } from '@seahorse/common';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { ModalAction, ModalOptions, ModalResponse } from './modal.model';
import { ModalTemplate } from './modal.template';

export class ModalBuilder {
  private _title: string;
  content: HTMLElement[] = [];
  components: any[] = [];

  private _ngbModal: NgbModal;
  private _modalRef: NgbModalRef;
  private _translateService: TranslateService;
  private _dynamicsService: DynamicsService;

  constructor(private _injector: Injector) {
    this._ngbModal = _injector.get(NgbModal);
    this._translateService = _injector.get(TranslateService);
    this._dynamicsService = _injector.get(DynamicsService);
  }

  withTitle(title: string) {
    if (title) {
      this._title = this._translateService.instant(title);
    }

    return this;
  }

  withTextBody(text: string, styles?: Partial<CSSStyleDeclaration>) {
    const contentEl = document.createElement('div');
    contentEl.innerText = this._translateService.instant(text);

    if (styles) {
      Object.keys(styles).forEach(
        (key) => (contentEl.style[key] = styles[key])
      );
    }

    this.content.push(contentEl);
    return this;
  }

  withHtmlBody(element: HTMLElement) {
    this.content.push(element);
    return this;
  }

  withComponentBody<TComponent>(descriptor: ComponentDescriptor<TComponent>) {
    const componentRef = this._dynamicsService.createComponent(descriptor);
    const content = componentRef.location.nativeElement as HTMLElement;

    this.content.push(content);
    this.components.push(componentRef.instance);
    return this;
  }

  open(options?: ModalOptions) {
    const modalTemplateRef = this._dynamicsService.createComponent({
      component: ModalTemplate,
      props: {
        title: this._title,
        isTable: options?.isTable,
        hideActions: options?.hideActions,
      },
      projectableNodes: [this.content],
      injector: this._injector,
    });

    this._modalRef = this._ngbModal.open(
      modalTemplateRef.instance.templateRef,
      {
        backdrop: 'static',
        keyboard: options ? options.closeWithEscape : true,
        size: options?.size,
      }
    );

    return from(modalTemplateRef.instance.action).pipe(map(this._onAction));
  }

  private _onAction = <TComponent>(response: ModalResponse<TComponent>) => {
    response.componentRef = this.components[0];
    response.modalRef = this._modalRef;

    if (response.action === ModalAction.Close) {
      this._modalRef.close(response);
      this.cleanUp();
    }

    return response;
  };

  private cleanUp() {
    this._modalRef = null;
    this._title = null;
  }
}
