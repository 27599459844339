export class ExpenseConstant {
  static OBJECTTYPEKEY = 'st-expense';
  static OBJECTTYPEID = '$customcontent_st-expense_id';
}

export enum ExpenseGroupOptions {
  Reference = 0,
  Supplier,
  SupplierAndReference,
}
