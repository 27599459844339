<div class="container-fluid py-3">
  <ul class="summary-buttons">
    <li *ngFor="let demoOrganisation of demoOrganisations">
      <button
        (click)="createEnvironment(demoOrganisation)"
        class="summary-button w-100 py-5"
        [disabled]="isLoading"
      >
        <span class="summary-button-text">
          {{ demoOrganisation.name }}
        </span>
      </button>
    </li>
  </ul>
  <code *ngIf="isLoading" class="alert alert-dark terminal-emulator">
    <sh-loading-indicator class="text-center"></sh-loading-indicator>
    <span *ngFor="let message of messages">{{ message.message }}</span>
  </code>
</div>
